import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Menu } from 'antd';
import { usePermissions } from '../../hooks/usePermissions';
import type { MenuProps } from 'antd';
import { getUsersListReq } from '../../actions/user';
import allLogsIcon from '../../img/allLogs.svg';
import { MailOutlined } from '@ant-design/icons';
import Spiner from '../spiner/Spiner';
import UnitIcon from '../../img/tsx/UnitIcon';
import IftaIcon from '../../img/tsx/IftaIcon';
import TransactionIcon from '../../img/tsx/TransactionIcon';
import UndifinedIcon from '../../img/tsx/UndifinedIcon';
import UnitHistoryIcon from '../../img/tsx/UnitHistoryIcon';

type MenuItem = Required<MenuProps>['items'][number];

export const SideBar = (props: any) => {
  const { isOpen } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { checkPermission, PermissionType } = usePermissions();
  const usersList = useSelector((state: any) => state.user.usersList);
  const fullScreenRoutes = [
    'carriers',
    'drivers',
    'driver_group',
    'mechanic',
    'vehicle',
    'trailer',
    'device',
    'role',
    'company',
    'role',
    'user',
    'logs',
    'log',
    'logs_unidentified',
  ];

  const getDefaultKey = (pathname: string): any => {
    const keys: any = [];
    fullScreenRoutes.forEach((route: any) => {
      if (pathname.indexOf(route) > -1) {
        keys.push(route);
      }
    });
    return keys;
  };

  const getPath = (path: string) => {
    return `${pathname}/${path}`;
  };

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    link?: any,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      link,
    } as MenuItem;
  }

  function getItemLink(
    label: React.ReactNode,
    key: React.Key,
    link?: any,
    icon?: React.ReactNode,
  ): MenuItem {
    return {
      key,
      icon,
      label,
      link,
    } as MenuItem;
  }

  const getUsers = () => {
    if (!usersList.length) {
      dispatch(getUsersListReq({}));
    }
  };

  const users: MenuItem[] = [
    getItem('Navigation One', 'sub1', '/', <MailOutlined />, [
      getItem('Option 5', '5', '/', <Spiner />),
    ]),
  ];

  const items = React.useMemo(() => {
    return [
      getItem(
        'Dashboards',
        '/client',
        '/client',
        <span className="icon-fi-rr-home"></span>,
        [
          getItem(
            'Dashboards',
            '/client',
            '/client',
            <span className="icon-fi-rr-folder"></span>,
          ),
        ],
      ),
      getItem(
        'Units',
        'units_',
        getPath('units_'),

        <span className="icon-fi-rr-layers">
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
        </span>,
        [
          getItem('All Units', 'units', '/units', <UnitIcon />),
          getItem(
            'Unit History',
            'units/history',
            '/units/history',
            <UnitHistoryIcon />,
          ),
        ],
      ),
      getItem(
        'All Logs',
        'logs_',
        getPath('logs_'),

        <img src={allLogsIcon} />,
        [
          getItem(
            'Logs',
            'logs',
            '/logs',
            <span className="icon-fi-rr-folder"></span>,
          ),
          getItem(
            'Unidentified',
            'logs/unidentified',
            '/logs/unidentified',
            <UndifinedIcon />,
          ),
          getItem(
            'Transactions',
            'logs/transactions',
            '/logs/transactions',
            <TransactionIcon />,
          ),
        ],
      ),
      getItem(
        'Reports',
        'reports',
        getPath('reports'),
        <span className="icon-fi-rr-document-signed"></span>,
        [
          getItem(
            'DOT Report',
            'dot',
            '/dot',
            <span className="icon-fi-rr-document-signed" />,
          ),
          getItem('IFTA Reports', 'ifta', '/ifta', <IftaIcon />),
        ],
      ),
      getItem(
        'Manage',
        'manage',
        getPath('manage'),
        <span className="icon-icon-atention"></span>,
        [
          getItem(
            'Carriers',
            'carriers',
            '/carriers',
            <span className="icon-fi-rr-inbox"></span>,
          ),
          getItem(
            'Drivers',
            'drivers',
            '/drivers',
            <span className="icon-fi-rr-user"></span>,
          ),
          getItem(
            'Driver Group',
            'driver_group',
            '/driver_group',
            <span className="icon-fi-rr-folder"></span>,
          ),
          getItem(
            'Vehicles',
            'vehicle',
            '/vehicle',
            <span className="icon-fi-rr-truck-side"></span>,
          ),
          getItem(
            'Devices',
            'device',
            '/device',
            <span className="icon-fi-rr-data-transfer"></span>,
          ),
          getItem(
            'Alerts',
            'alerts',
            '/alerts',
            <span className="icon-fi-rr-folder"></span>,
          ),
        ],
      ),
      getItem(
        'Company Account',
        'company_account',
        getPath('company_account'),
        <span className="icon-fi-rr-building"></span>,
        [
          getItem(
            'Company',
            'company',
            '/company',
            <span className="icon-fi-rr-folder"></span>,
          ),
          getItem(
            'Offices',
            'office',
            '/office',
            <span className="icon-fi-rr-folder"></span>,
          ),
          getItem(
            'Roles & Permissions',
            'role',
            '/role',
            <span className="icon-fi-rr-key"></span>,
          ),

          getItem(
            'Users',
            'user',
            '/user',
            <span className="icon-fi-rr-user"></span>,
          ),
          getItem(
            'User Activity',
            'activity',
            '/activity',
            <span className="icon-fi-rr-user"></span>,
          ),
        ],
      ),
    ];
  }, []);

  const FirstMenu = React.useMemo(() => {
    return (
      <Menu
        className="ubuntu"
        defaultSelectedKeys={getDefaultKey(pathname)}
        defaultOpenKeys={['company_account', 'manage']}
        mode="inline"
        onClick={(item) => {
          navigate(item.key);
        }}
        items={items}
        inlineCollapsed={!isOpen}
        // onOpenChange={(item) => {
        //   getUsers();
        // }}
      />
    );
  }, [isOpen, pathname]);

  return (
    <Row className="side-bar ubuntu">
      <Col span={24}>{FirstMenu}</Col>
    </Row>
  );
};
