import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Select, Button, DatePicker, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';
import { carrierData } from './constant';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import {
  getEventLabel,
  getOriginLabel,
  parseDateGeneralStringFormat,
} from './log-utils';
import {
  assignUnLogReq,
  getLogUnideentifiedTransactions,
} from '../../../actions';
import TableSpinner from '../../spiner/TableSpinner';

const { RangePicker } = DatePicker;

const App: React.FC = () => (
  <Space direction="vertical" size={12}>
    <RangePicker />
    <RangePicker showTime />
    <RangePicker picker="week" />
    <RangePicker picker="month" />
    <RangePicker picker="quarter" />
    <RangePicker picker="year" />
  </Space>
);

dayjs.extend(customParseFormat);

const timeFormat = (second: number) => {
  const hours = Math.floor(second / 3600);
  const minutes = Math.floor((second % 3600) / 60);
  const seconds = second % 60;
  const addLeadingZero = (value: number): string => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const formattedTime = {
    hours: addLeadingZero(hours),
    minutes: addLeadingZero(minutes),
    seconds: addLeadingZero(seconds),
  };
  return formattedTime;
};

export const LogTabelUnidentified: React.FC = () => {
  const dispatch = useDispatch();
  const { handleTableChange, tableParams, rowSelection } = useTableParams({});
  const logs = useSelector((state: any) => state.driverLog.logList);
  const driverLogData = useSelector(
    (state: any) => state?.driverLog?.driverData,
  );
  const driverConfigLog = useSelector(
    (state: any) => state?.log?.driverConfigLog?.data,
  );
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const unLogs = useSelector((state: any) => state?.log?.undefined);
  const logUnitId = useSelector(
    (state: any) => state?.driverLog?.logForms?.[0]?.unit?.id,
  );
  const count = useSelector((state: any) => state.log.count);
  const loading = useSelector((state: any) => state.log.loading);
  const [selectValues, setSelectValues] = useState<{ value: string }[]>([]);
  const handleChange = (value: string, index: number) => {
    const updatedSelectValues = [...selectValues];
    updatedSelectValues[index] = { value };
    setSelectValues(updatedSelectValues);
  };
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Date & Time',
      key: 'identificator_log',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>{`${parseDateGeneralStringFormat(
            record?.timestamp,
          )}`}</div>
        );
      },
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu">
            {getEventLabel(record?.event_type, record?.event_code)}
          </div>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.location ? `${record?.location}` : ''}
          </div>
        );
      },
    },

    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      width: '11%',
      ellipsis: true,
      render: (value, record, index) => {
        console.log(record);

        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.unit?.vehicle?.identificator}
          </div>
        );
      },
    },

    {
      title: 'Odometer',
      dataIndex: 'total_miles',
      key: 'total_miles',
      width: '10%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {`${defaultTo(record?.total_miles, '')}`}
          </div>
        );
      },
    },
    {
      title: 'EH',
      dataIndex: 'total_hours',
      key: 'total_hours',
      width: '8%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.total_hours?.toFixed(1)}
          </div>
        );
      },
    },
    {
      title: 'Select Driver',
      dataIndex: 'progress',
      key: 'progress',
      width: '20%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div style={{ width: 200 }}>
            <Select
              showSearch
              optionFilterProp="children"
              defaultValue={driverConfigLog.driver.id}
              style={{ width: 200 }}
              onChange={(e) => handleChange(e, index)}
            >
              {driverLogData?.carrier?.drivers
                ?.filter((driver: any) => driver.status === 1)

                .map((item: any, i: number) => {
                  return (
                    <Select.Option
                      key={i}
                      value={item.id}
                      style={{ backgroundColor: item.color }}
                    >
                      {item?.first_name} {item?.last_name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        );
      },
    },
    {
      title: 'Assign',
      dataIndex: 'edit',
      key: 'edit',
      width: '8%',
      ellipsis: true,
      render: (value, record, index) => {
        const asignClick = () => {
          // alert(selectedDriverId);
          dispatch(
            assignUnLogReq({
              body: {
                driver: selectValues[index].value,
                events: [record.id],
              },
            }),
          );
        };
        return (
          <div className="ubuntu" style={{ display: 'flex' }}>
            <button onClick={asignClick} className="roll-back-button">
              Assign
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getLogUnideentifiedTransactions({
        unitId: logUnitId,
        date: driverLogDate,
        queryParams: {
          bro_with: ['unit'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    const values = unLogs.map((logs: any) => {
      return {
        value: '',
      };
    });
    setSelectValues(values);
  }, [unLogs]);
  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <div style={{ width: '100%' }} className="logs-table">
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={unLogs}
              pagination={{
                ...tableParams.pagination,
                position: ['bottomCenter'],
                total: count,
              }}
              loading={{
                spinning: loading,
                indicator: <TableSpinner />,
              }}
              onChange={handleTableChange}
              rowSelection={{ ...rowSelection, columnWidth: 10 }}
              className="table-custom"
            />
          </div>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
