import styles from './Unit.module.css';
import UnitCard, { IUnitCardProps } from './UnitCard';
const UnitInfo = () => {
  const filterItems = [
    {
      title: '5',
      icon: '',
    },
    {
      title: '10',
      icon: '',
    },
    {
      title: 'Truck Stop',
      icon: 'icon-fi-rr-home',
    },
  ];

  const unitArray: IUnitCardProps[] = [
    {
      title: 'Lincoln Oasis Mobil',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      icon: 'icon-fi-rr-home',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
    {
      title: 'Lincoln Oasis Mobil',
      icon: 'icon-fi-rr-document-signed',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-document-signed',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
    {
      title: 'Lincoln Oasis Mobil',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      icon: 'icon-fi-rr-home',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
    {
      title: 'Lincoln Oasis Mobil',
      icon: 'icon-fi-rr-document-signed',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-document-signed',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
    {
      title: 'Lincoln Oasis Mobil',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      icon: 'icon-fi-rr-home',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
    {
      title: 'Lincoln Oasis Mobil',
      icon: 'icon-fi-rr-document-signed',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-document-signed',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
    {
      title: 'Lincoln Oasis Mobil',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      icon: 'icon-fi-rr-home',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
    {
      title: 'Lincoln Oasis Mobil',
      icon: 'icon-fi-rr-document-signed',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      body: [
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-document-signed',
          changed: false,
        },
        {
          title: 'Weight Station',
          icon: 'icon-fi-rr-home',
          changed: true,
        },
        {
          title: 'Weight Station',
          changed: false,
        },
      ],
    },
  ];

  return (
    <div className={styles.info}>
      <div className={styles.filters}>
        {filterItems.map((item) => (
          <div className={styles.filterItem}>
            {item.icon.length != 0 && (
              <span className={`${item.icon} ${styles.span}`} />
            )}
            {item.title}
          </div>
        ))}
      </div>
      <div className={styles.infoBody}>
        {unitArray.map((unit) => (
          <UnitCard
            icon={unit.icon}
            title={unit.title}
            body={unit.body}
            location={unit.location}
          />
        ))}
      </div>
    </div>
  );
};

export default UnitInfo;
