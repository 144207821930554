function UnitIcon() {
  return (
    <svg
      style={{
        marginRight: 10,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      fill="none"
      viewBox="0 0 14 15"
    >
      <g clipPath="url(#clip0_6506_25320)">
        <path
          className="ant-menu-item-icon"
          d="M11.974 2.96L8.458.93a2.925 2.925 0 00-2.916 0L2.026 2.96A2.925 2.925 0 00.568 5.484v4.06a2.926 2.926 0 001.458 2.527l3.516 2.03a2.924 2.924 0 002.916 0l3.516-2.03a2.926 2.926 0 001.458-2.527v-4.06a2.926 2.926 0 00-1.458-2.526zM6.125 1.94a1.75 1.75 0 011.75 0l3.516 2.03c.089.056.173.12.25.192L8.028 6.248a2.06 2.06 0 01-2.056 0L2.359 4.162c.077-.071.161-.136.25-.192l3.516-2.03zm-3.516 9.12a1.755 1.755 0 01-.875-1.516V5.485c.004-.105.018-.21.042-.312L5.388 7.26c.32.181.667.306 1.029.37v5.582c-.101-.03-.199-.071-.292-.12l-3.516-2.03zm9.657-1.516a1.755 1.755 0 01-.875 1.517l-3.516 2.03c-.093.049-.19.09-.292.12V7.629c.362-.064.71-.189 1.029-.37l3.612-2.086c.024.103.038.207.042.312v4.06z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_6506_25320">
          <path
            className="ant-menu-item-icon"
            d="M0 0H14V14H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnitIcon;
