import { Input } from "antd";
import React from "react";
import { InputType } from "../../../../constants/inputs";
import { DriverDynamicField } from "../../units/fields/DriverDynamicField";
import { VehicleDynamicField } from "../../units/fields/VehicleDynamicField";
import { DeviceDynamicField } from "../../units/fields/DeviceDynamicField";
import { TextInputV2 } from "../../../common/doubleinput";

interface ILogBulkInput {
  type:
    | "HH.MM"
    | "Odometer"
    | "Engine Hours"
    | "Select Driver"
    | "Select Vehicle"
    | "Select ELD";
  title: string;
}

const driverTypes = {
  type: InputType.DRIVER_DYNAMIC,
  name: "codriver",
  placeholder: "Select CO Driver",
  hasFeedback: true,
  width: "100%",
  title: "",
  isRequired: false,
  isLogDriver: true,
  isCoDriver: true,
};
const vehicleTypes = {
  type: InputType.VEHICLE_DYNAMIC,
  name: "vehicle",
  title: "",
  placeholder: "Select Vehicle",
  hasFeedback: true,
  width: "100%",
  isLogDriver: true,
  isRequired: false,
  options: [
    {
      key: 0,
      value: "Vehicle 01",
    },
    {
      key: 1,
      value: "Vehicle 02",
    },
  ],
};
const eldTypes = {
  type: InputType.DEVICE_DYNAMIC,
  name: "eld",
  placeholder: "Select Eld",
  hasFeedback: true,
  width: "100%",
  isLogDriver: true,
  options: [
    {
      key: 0,
      value: "Eld 01",
    },
    {
      key: 1,
      value: "Eld 02",
    },
  ],
};

const time = {
  type: InputType.TEXT_V2,
  name: "time",
  placeholder: "HH.MM",
  hasFeedback: true,
  width: "100%",
  title: "",
};
const engine = {
  type: InputType.TEXT_V2,
  name: "hours",
  placeholder: "Engine Hours",
  hasFeedback: true,
  width: "100%",
  title: "",
};
const odomentr = {
  type: InputType.TEXT_V2,
  name: "odomentr",
  placeholder: "Odometer",
  hasFeedback: true,
  width: "100%",
  title: "",
};
const defalut = {
  type: InputType.TEXT_V2,
  name: "empty",
  placeholder: "choose",
  hasFeedback: true,
  width: "100%",
  title: "",
};

const LogBulkInput = ({ type, title }: ILogBulkInput) => {
  switch (type) {
    case "HH.MM":
      return <TextInputV2 {...time} />;
    case "Select Driver":
      return <DriverDynamicField {...driverTypes} />;
    case "Engine Hours":
      return <TextInputV2 {...engine} />;
    case "Odometer":
      return <TextInputV2 {...odomentr} />;
    case "Select Vehicle":
      return <VehicleDynamicField {...vehicleTypes} />;
    case "Select ELD":
      return <DeviceDynamicField {...eldTypes} />;
    default:
      return <TextInputV2 {...defalut} />;
  }
};

export default LogBulkInput;
