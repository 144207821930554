import styles from './Chat.module.css';

interface IChatInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

const ChatInput = ({ value, onChange, placeholder }: IChatInputProps) => {
  return (
    <>
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={styles.input}
        type="text"
        name="message"
      />
    </>
  );
};

export default ChatInput;
