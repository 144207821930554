import { CHARTCOLORS } from '../../../constant';
import {
  ChartForrmaterData,
  ChartPointsData,
} from '../interface/ChartInterface';

export const generateChartType = (
  type: string | undefined,
  timestamp: number,
  id: string,
): ChartForrmaterData | null => {
  switch (type) {
    case 'ON':
      return {
        borderColor: CHARTCOLORS.ON,
        y: 1,
        label: type,
        pointRadius: 0,
        borderWidth: 2,
        stepped: true,
        x: timestamp * 1000,
        borderDash: [],
        id,
      };
    case 'OFF':
      return {
        borderColor: CHARTCOLORS.OFF,
        pointRadius: 0,
        borderWidth: 2,
        label: type,
        stepped: true,
        x: timestamp * 1000,
        y: 4,
        id,
        borderDash: [],
      };
    case 'SB':
      return {
        borderColor: CHARTCOLORS.SB,
        pointRadius: 0,
        borderWidth: 2,
        stepped: true,
        label: type,
        x: timestamp * 1000,
        y: 3,
        id,
        borderDash: [],
      };
    case 'D':
      return {
        borderColor: CHARTCOLORS.D,
        pointRadius: 0,
        borderWidth: 2,
        stepped: true,
        y: 2,
        id,
        x: timestamp * 1000,
        label: type,
        borderDash: [],
      };
    case 'PC':
      return {
        borderColor: CHARTCOLORS.OFF,
        pointRadius: 0,
        borderWidth: 2,
        stepped: true,
        y: 4,
        id,
        x: timestamp * 1000,
        label: type,
        borderDash: [5, 5],
      };
    case 'YM':
      return {
        borderColor: CHARTCOLORS.ON,
        pointRadius: 0,
        borderWidth: 2,
        stepped: true,
        y: 1,
        id,
        x: timestamp * 1000,
        label: type,
        borderDash: [5, 5],
      };
    default:
      return null;
  }
};
