import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const chartFromDateToTimeStamp = (day: string, timeZone: string) => {
  const startOfDay = dayjs(day).tz(timeZone).endOf('day').valueOf() + 1;
  const endOfDay =
    dayjs(day).add(1, 'day').tz(timeZone).endOf('day').valueOf() + 1;

  return {
    startOfDay,
    endOfDay,
  };
};
export const getCurrentDate = (tz: string) => {
  const date = dayjs().tz(tz);
  const timestamp = date.valueOf();
  const formattedDate = date.format('YYYY-MM-DD');

  return {
    timestamp,
    formattedDate,
  };
};

export const convertTimestampToTimezone = (
  timestamp: number,
  timeZone: string,
) => {
  const date = dayjs(timestamp).tz(timeZone).valueOf();
  return date;
};
