import React, { useState, useMemo, useEffect } from 'react';
import { Form, Input, Col } from 'antd';

export const InputTextV2Authority = (props: any) => {
  const {
    rules = [],
    name = '',
    icon,
    placeholder = '',
    label = '',
    disabled,
    width = '100%',
    title = '',
    isSecondField = false,
    span = 24,
    styles = {},
    pathName = '',
    error,
    errorVisible,
    secondError,
    form,
    currentIndex,
  } = props;
  const getName = useMemo(() => {
    return pathName ? [...pathName, name] : name;
  }, [pathName, name]);
  const authority = form.getFieldValue('terminals');
  const [authorityState, setAuthorityState] = useState(
    authority[currentIndex].authority,
  );
  const setAuthorityTerminal = () => {
    form.setFieldValue(['terminals', currentIndex, 'authority'], true),
      setAuthorityState(true);
  };
  useEffect(() => {
    setAuthorityState(authority[currentIndex].authority);
  }, [form.getFieldValue('terminals')]);
  return (
    <Col
      span={span}
      className="input-container-v2"
      style={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {title ? (
        title === 'invisible' ? (
          <div
            className="input-item-title input-title ubuntu"
            style={{ opacity: 0 }}
          >
            {title} {authorityState && '(Authority terminal)'}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            className="input-item-title input-title ubuntu"
          >
            <p>
              {title} {authorityState && '(Authority terminal)'}
            </p>
            {!authorityState && (
              <div
                onClick={setAuthorityTerminal}
                style={{ cursor: 'pointer' }}
                className="orange"
              >
                set Authority terminal
              </div>
            )}
          </div>
        )
      ) : null}
      <Form.Item rules={rules} name={getName} style={{ width: '100%' }}>
        <Input
          prefix={
            !!icon ? (
              <span className={`icon-icon-${icon} orange`}></span>
            ) : (
              <span></span>
            )
          }
          placeholder={placeholder}
          style={{ width, ...styles }}
          disabled={disabled}
        />
      </Form.Item>
      <div className="input-item-error">
        {errorVisible === 1 && error}
        {errorVisible === 2 && secondError}
      </div>
    </Col>
  );
};
