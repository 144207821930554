export function cleanObject(obj: Record<string, any>) {
  const data: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '' && obj[key] !== null) {
      data[key] = obj[key];
    }
  }

  return JSON.stringify(data);
}
