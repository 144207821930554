import React from "react";
import logoPc from "../../../img/header/logo.svg";

export const HeaderLogo = () => {
  return (
    <div className="header-logo-container">
      <img src={logoPc} />
    </div>
  );
};
