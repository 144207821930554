import { Button, DatePicker, Form, Modal, TimeRangePickerProps } from 'antd';
import { useEffect, useState } from 'react';
import styles from './RecordPage.module.css';
import { InputSelectV2, TextInputV2 } from '../../common/doubleinput';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getCarriersListReq, getDriverListReq } from '../../../actions';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);
const CreateIftaModal = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const carriers = useSelector((state: any) => state.carrier.carrierList);
  const drivers = useSelector((state: any) => state.driver.driverList);
  const vehicle = useSelector((state: any) => state.vehicle.vehicleList);
  const [carrierOptions, setCarrierOptions] = useState<any[]>();
  const [criteriaOptions, setCriteriaOptions] = useState<any[]>();
  const dispatch = useDispatch();
  const openModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };
  const createRecordInputs = [
    {
      type: 'select',
      title: 'Carrier',
      placeholder: 'Select Carrier',
      name: 'carrier',
      options: carrierOptions,
    },
    {
      type: 'range',
      title: 'Period',
      placeholder: 'Select Period',
      name: 'period',
    },
    {
      type: 'select',
      title: 'Type',
      name: 'type',
      options: [
        {
          key: 'IFTA by Vehicle',
          value: 'IFTA by Vehicle',
        },
        {
          key: 'IFTA by Driver',
          value: 'IFTA by Driver',
        },
        {
          key: 'IFTA by State',
          value: 'IFTA by State',
        },
      ],
    },
    {
      type: 'select',
      title: 'Selection Criteria',
      name: 'criteria',
      options: criteriaOptions,
    },
  ];
  const onFinish = () => {
    // const { carrier, note, code, range, transferMethod, driver } =
    //   form.getFieldsValue();
    setIsModalOpen(false);
  };
  function disabledDate(current: any) {
    return current && current > dayjs().endOf('day');
  }
  const { RangePicker } = DatePicker;
  const today = dayjs();
  const rangePickerChange = (value: any) => {
    if (value) {
      const [startDate, endDate] = value;
      form.setFieldValue(
        'range',
        `${startDate.valueOf()}-${endDate.valueOf()}`,
      );
    } else {
      form.setFieldValue('range', '');
    }
  };
  const handleSelectChange = (inputName: string) => {
    const value = form.getFieldValue('type');
    const carriaerValue = form.getFieldValue('carrier');
    let options: any[] = [];
    if (inputName === 'type' || inputName === 'carrier') {
      form.setFieldValue('criteria', '');
      if (value === 'IFTA by Vehicle') {
        if (carriaerValue) {
          const vehicleFilter = vehicle.filter(
            (item: any) => item.carrier.name === carriaerValue,
          );
          vehicleFilter.map((vehicle: any) => {
            options.push({
              key: vehicle.id,
              value: vehicle.identificator,
            });
          });
          options.push({
            key: 'All Vehicles',
            value: 'All Vehicles',
          });
        } else {
          options.push({
            key: 'Please Select Carrier',
            value: 'Please Select Carrier',
            disabled: true,
          });
        }
      } else if (value === 'IFTA by Driver') {
        if (carriaerValue) {
          const driversFilter = drivers.filter(
            (item: any) => item.carrier.name === carriaerValue,
          );
          driversFilter.map((driver: any) => {
            options.push({
              key: driver.id,
              value: driver.first_name + ' ' + driver.last_name,
            });
          });
          options.push({
            key: 'All Driver',
            value: 'All Driver',
          });
        } else {
          options.push({
            key: 'Please Select Carrier',
            value: 'Please Select Carrier',
            disabled: true,
          });
        }
      } else {
        options = [
          {
            key: 'All States',
            value: 'All States',
          },
          {
            key: 'AK',
            value: 'AK',
          },
          {
            key: 'AL',
            value: 'AL',
          },
        ];
      }
      setCriteriaOptions(options);
    }
  };
  useEffect(() => {
    if (carriers) {
      const options: any[] = [];
      carriers.map((carrier: any) => {
        options.push({
          key: carrier.name,
          value: carrier.name,
        });
      });
      setCarrierOptions(options);
    }
  }, [carriers]);

  useEffect(() => {
    dispatch(getCarriersListReq({}));
    dispatch(
      getDriverListReq({
        queryParams: {
          with: ['carrier'],
        },
      }),
    );
  }, []);
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: '2023 - Q3',
      value: [
        dayjs('2023-07-01').startOf('quarter'),
        dayjs('2023-09-30').endOf('quarter'),
      ],
    },
    {
      label: '2023 - Q4',
      value: [
        dayjs('2023-10-01').startOf('quarter'),
        dayjs('2023-12-31').endOf('quarter'),
      ],
    },
    {
      label: '2024 - Q1',
      value: [
        dayjs('2024-01-01').startOf('quarter'),
        dayjs('2024-03-31').endOf('quarter'),
      ],
    },
    {
      label: '2024 - Q2',
      value: [dayjs('2024-04-01').startOf('quarter'), dayjs()],
    },
  ];

  return (
    <>
      <div
        onClick={openModal}
        className="orange ubuntu"
        style={{
          fontWeight: 500,
          fontSize: 12,
          marginLeft: 16,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          borderRadius: 10,
          padding: '10px',
          width: 'fit-content',
          gap: 5,
        }}
      >
        <span
          className="icon-fi-rr-plus ubuntu orange"
          style={{ width: 'fit-content' }}
        />
        Crete IFTA Report
      </div>
      <Modal
        title="Crete IFTA Report"
        open={isModalOpen}
        onOk={openModal}
        onCancel={openModal}
        footer={null}
        destroyOnClose
        className={styles.modal}
      >
        <Form form={form} name="IFTA" onFinish={onFinish}>
          <div className={styles.form}>
            {createRecordInputs.map((input) => (
              <>
                {input.type === 'select' && (
                  <InputSelectV2
                    showSearch
                    title={input.title}
                    name={input.name}
                    options={input.options}
                    placeholder={input.placeholder}
                    onChange={() => handleSelectChange(input.name)}
                  />
                )}
                {input.type === 'range' && (
                  <Form.Item name={input.name}>
                    <div className="input-item-title">{input.title}</div>
                    <RangePicker
                      name={input.name}
                      presets={rangePresets}
                      onChange={(e) => rangePickerChange(e)}
                      className={styles.rangePicker}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                )}
                {input.type === 'text' && (
                  <TextInputV2 title={input.title} name={input.name} />
                )}
              </>
            ))}
            <div>
              <Form.Item style={{ width: '100%', display: 'flex' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="orange"
                  style={{ width: '65px', marginRight: 12 }}
                >
                  Save
                </Button>
                <Button
                  className="grey"
                  style={{ width: '85px', marginRight: 12 }}
                  onClick={() => {
                    openModal();
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateIftaModal;
