import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import styles from './ClientStyles.module.css';
const MainContainer = ({
  children,
  half,
  back,
}: {
  children: ReactNode;
  half?: boolean;
  back?: boolean;
}) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      className={`${styles.card} ${half && `${styles.half}`}`}
    >
      {children}
    </motion.div>
  );
};

export default MainContainer;
