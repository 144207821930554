import React, { createContext, ReactNode, useContext, useState } from 'react';
import { IDriver } from './Chat';

interface ChatContextType {
  open: boolean;
  openChat: () => void;
  closeChat: () => void;
  chooseDriver: (driver: IDriver) => void;
  driver: IDriver | null;
}

const defaultChatContext: ChatContextType = {
  open: false,
  openChat: () => {
    console.warn('openChat function should be overridden by ChatProvider');
  },
  closeChat: () => {
    console.warn('closeChat function should be overridden by ChatProvider');
  },
  chooseDriver: (driver: IDriver) => {
    console.warn('closeChat function should be overridden by ChatProvider');
  },
  driver: null,
};

const ChatContext = createContext<ChatContextType>(defaultChatContext);
export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [driver, setDriver] = useState<IDriver | null>(null);
  const openChat = () => setOpen(true);
  const closeChat = () => setOpen(false);
  const chooseDriver = (driver: IDriver) => setDriver(driver);
  return (
    <ChatContext.Provider
      value={{ open, openChat, closeChat, chooseDriver, driver }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
