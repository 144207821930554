import { createReducer } from '@reduxjs/toolkit';
import { getTransactionSucces } from '../actions/transaction';

export const transactionState = {
  allTransactions: [],
};

export default {
  transaction: createReducer<any>(transactionState, (builder) => {
    builder.addCase(getTransactionSucces, (state, { payload }) => {
      return {
        ...state,
        allTransactions: payload,
      };
    });
  }),
};
