import { IInput, IInputsBlockProps } from './InputsBlock';

export const DOT = {
  driver: 'driver',
  period: 'period',
  carrier: 'carrier',
  origin: 'origin',
  code: 'code',
  createTime: 'createTime',
  processedTime: 'processedTime',
  location: 'location',
  method: 'method',
  status: 'status',
  submissionId: 'submissionId',
  user: 'user',
  reports: 'reports',
  summary: 'summary',
  notice: 'notice',
  inspectionCode: 'inspectionCode',
  inspectionCode1: 'inspectionCode1',
  investigationCode: 'investigationCode',
  violations: 'violations',
};

const DOTGenaralFileds = (): IInputsBlockProps => {
  return {
    title: 'General',
    blocks: [
      {
        title: 'General Information',
        input: [
          {
            inputType: 'text',
            disabled: true,
            name: DOT.carrier,
            label: 'Carrier',
            placeholder: 'Enter Carrier',
            hasFeedback: true,
            title: 'Carrier',
            span: 24,
            width: '100%',
            type: 'double',
          },
          {
            inputType: 'text',
            name: DOT.driver,
            disabled: true,
            label: 'Driver  ',
            placeholder: 'Enter Driver',
            hasFeedback: true,
            title: 'Driver  ',
            span: 24,
            width: '100%',
            type: 'double',
          },
          {
            inputType: 'text',
            name: DOT.period,
            disabled: true,
            label: 'Period  ',
            placeholder: 'Enter Period',
            hasFeedback: true,
            title: 'Period  ',
            span: 24,
            width: '100%',
            type: 'double',
          },
          {
            type: 'double',
            inputType: 'text',
            disabled: true,
            name: DOT.origin,
            label: 'Origin',
            placeholder: 'Enter Origin',
            hasFeedback: true,
            title: 'Origin  ',
            span: 24,
            width: '100%',
          },
        ],
      },
      {
        title: 'Report Transfer Details',
        input: [
          {
            inputType: 'text',
            type: 'double',
            disabled: true,
            name: DOT.user,
            label: 'User  ',
            placeholder: 'Enter user',
            hasFeedback: true,
            title: 'User  ',
            span: 24,
            width: '100%',
          },
          {
            inputType: 'text',
            type: 'double',
            disabled: true,
            name: DOT.method,
            label: 'Method',
            placeholder: 'Enter Method',
            hasFeedback: true,
            title: 'Method  ',
            span: 24,
            width: '100%',
          },
          {
            inputType: 'text',
            type: 'double',
            disabled: true,
            name: DOT.status,
            label: 'Status',
            placeholder: 'Enter Status',
            hasFeedback: true,
            title: 'Status',
            span: 24,
            width: '100%',
          },
          {
            inputType: 'text',
            disabled: true,
            type: 'double',
            name: DOT.code,
            label: 'Safety Official / Investigation Code  ',
            placeholder: 'Enter Safety Official / Investigation Code',
            hasFeedback: true,
            title: 'Safety Official / Investigation Code  ',
            span: 24,
            width: '100%',
          },
        ],
      },
      {
        title: 'Timing Information',
        input: [
          {
            inputType: 'text',
            disabled: true,
            type: 'double',
            name: DOT.createTime,
            label: 'Create Time  ',
            placeholder: 'Enter Create Time',
            hasFeedback: true,
            title: 'Create Time  ',
            span: 24,
            width: '100%',
          },
          {
            inputType: 'text',
            type: 'double',
            disabled: true,
            name: DOT.processedTime,
            label: 'Processed Time  ',
            placeholder: 'Enter Processed Time',
            hasFeedback: true,
            title: 'Processed Time  ',
            span: 24,
            width: '100%',
          },
        ],
      },
      {
        title: 'Location and Submission ID',
        input: [
          {
            inputType: 'text',
            disabled: true,
            type: 'double',
            name: DOT.location,
            label: 'Location  ',
            placeholder: 'Enter Location',
            hasFeedback: true,
            title: 'Location  ',
            span: 24,
            width: '100%',
          },
          {
            inputType: 'text',
            disabled: true,
            type: 'double',
            name: DOT.submissionId,
            label: 'Submission ID  ',
            placeholder: 'Enter Submission ID',
            hasFeedback: true,
            title: 'Submission ID  ',
            span: 24,
            width: '100%',
          },
        ],
      },
      {
        title: 'Report Documents',
        input: [
          {
            inputType: 'upload',
            type: 'single',
            name: DOT.reports,
            label: 'Reports',
            placeholder: '',
            hasFeedback: true,
            title: 'Reports',
            span: 24,
            width: '100%',
          },
        ],
      },
      {
        title: 'Additional Information',
        input: [
          {
            inputType: 'notice',
            type: 'single',
            name: DOT.notice,
            label: 'Notice',
            placeholder: 'Enter Notice',
            hasFeedback: true,
            title: 'Notice',
            span: 24,
            width: '100%',
          },
        ],
      },
    ],
  };
};

const DOTInspectionFileds = (input: IInput[]): IInputsBlockProps => {
  return {
    title: 'Citation ',
    add: true,
    blocks: [
      {
        title: 'Citation',
        input,
      },
    ],
  };
};

export const DOTFields = (fileds: IInput[]) => {
  const inpections = DOTInspectionFileds(fileds);
  const general = DOTGenaralFileds();
  return [{ ...general }, { ...inpections }];
};
