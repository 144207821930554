import { DatePicker, TimeRangePickerProps } from 'antd';
import { CHARTCOLORS } from '../driver_log/constant';
import UnitRouteItem from './UnitRouteItem';
import styles from './UnitRoutes.module.css';
import dayjs from 'dayjs';
import { disabledDate } from '../dashboard/DasboardHeader';
const UnitRoute = () => {
  const { RangePicker } = DatePicker;

  const routes = [
    {
      label: 'SB',
      color: CHARTCOLORS.SB,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
      violation: 'Malfunction',
    },
    {
      label: 'D',
      color: CHARTCOLORS.D,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'OFF',
      color: CHARTCOLORS.OFF,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'ON',
      color: CHARTCOLORS.ON,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'SB',
      color: CHARTCOLORS.SB,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'SB',
      color: CHARTCOLORS.SB,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'SB',
      color: CHARTCOLORS.SB,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'SB',
      color: CHARTCOLORS.SB,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'SB',
      color: CHARTCOLORS.SB,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
    {
      label: 'SB',
      color: CHARTCOLORS.SB,
      timestamp: 1721141433,
      adress: '2000s congress Ave,Austin,TX 78704',
    },
  ];
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: '2023 - Q3',
      value: [
        dayjs('2023-07-01').startOf('quarter'),
        dayjs('2023-09-30').endOf('quarter'),
      ],
    },
    {
      label: '2023 - Q4',
      value: [
        dayjs('2023-10-01').startOf('quarter'),
        dayjs('2023-12-31').endOf('quarter'),
      ],
    },
    {
      label: '2024 - Q1',
      value: [
        dayjs('2024-01-01').startOf('quarter'),
        dayjs('2024-03-31').endOf('quarter'),
      ],
    },
    {
      label: '2024 - Q2',
      value: [dayjs('2024-04-01').startOf('quarter'), dayjs()],
    },
  ];

  return (
    <div className={styles.container}>
      <div style={{ width: '100%', marginBottom: 15 }}>
        <div className="input-item-title">Period</div>
        <RangePicker
          style={{
            width: '100%',
          }}
          name={'range'}
          presets={rangePresets}
          className={styles.rangePicker}
          disabledDate={disabledDate}
        />
      </div>
      {routes.map((card, index) => (
        <>
          <UnitRouteItem
            violation={card.violation}
            timestamp={card.timestamp}
            label={card.label}
            color={card.color}
            adress={card.adress}
            index={index}
            lastIndex={routes.length - 1}
          />
        </>
      ))}
    </div>
  );
};

export default UnitRoute;
