import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Table,
  Dropdown,
  Row,
  Col,
  Select,
  Button,
  Tooltip,
  DatePicker,
  Space,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import { defaultTo } from 'lodash';
import {
  getVehicleListReq,
  getVehicleListRootReq,
  // getCarrierPasswordReq,
} from '../../../actions/vehicle';
import { getParams } from '../../../routes/utils';
import { InputSearch } from '../../common/doubleinput/InputSearch';

import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import { carrierData } from './constant';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { BurgerIcon } from '../../header/logo';
import { LogTabs } from './LogTabs/LogTabs';
import { CreateDriverLogModal } from './CreateLogModal';
import quarterClock from '../../../img/quarter-clock.svg';
import edit from '../../../img/edit.svg';
import copyAlt from '../../../img/copy-alt.svg';

import download from '../../../img/download.svg';
import trash from '../../../img/trash.svg';

import { LogBulkPanel } from './logs-panels/LogBulk';
import {
  getEventLabel,
  getOriginLabel,
  parseDateGeneralStringFormat,
} from './log-utils';
import {
  deleteDriverLogReq,
  getDriverLogListReq,
  getLogTransactions,
} from '../../../actions';
import { EditDriverLogModal } from './EditLogModal';
import { transactionRolllBackReq } from '../../../actions/transaction';
import TableSpinner from '../../spiner/TableSpinner';

const { RangePicker } = DatePicker;

const App: React.FC = () => (
  <Space direction="vertical" size={12}>
    <RangePicker />
    <RangePicker showTime />
    <RangePicker picker="week" />
    <RangePicker picker="month" />
    <RangePicker picker="quarter" />
    <RangePicker picker="year" />
  </Space>
);

dayjs.extend(customParseFormat);

export const LogTableTransaction: React.FC = () => {
  const dispatch = useDispatch();
  const { handleTableChange, tableParams, clearCustomFilter, setCustomFilter } =
    useTableParams({});
  const logs = useSelector((state: any) => state.driverLog.logList);
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const trasactions = useSelector((state: any) => state?.log?.trasactions);
  const params = useParams();
  const count = useSelector((state: any) => state.log.count);
  const loading = useSelector((state: any) => state.log.loading);
  const [logDateFilter, setLogDateFilter] = React.useState<any>('');
  const [logDateCreatedAtFilter, setLogDateCreatedAtFilter] =
    React.useState<any>('');
  const [data, setData] = useState<any[]>([]);

  React.useEffect(() => {
    dispatch(
      getDriverLogListReq({
        queryParams: {
          with: ['driver_groups', 'vehicles', 'drivers', 'vehicle'],
        },
        driverid: params?.driverid,
        date: driverLogDate,
      }),
    );
  }, [driverLogDate]);

  useEffect(() => {
    dispatch(
      getLogTransactions({
        id: params?.driverid,
        queryParams: {
          with: ['count', 'events', 'driver', 'unit', 'user'],
        },
      }),
    );
  }, [driverLogDate]);
  useEffect(() => {
    setData(trasactions);
  }, [trasactions]);
  const columns: ColumnsType<any> = [
    {
      title: 'Period',
      key: 'identificator_log',
      dataIndex: 'identificator',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            {dayjs(record?.time_from * 1000).format('MM/DD/YYYY')} -{' '}
            {dayjs(record?.time_to * 1000).format('MM/DD/YYYY')}
          </div>
        );
      },
      width: '16%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <RangePicker
              onChange={(v, d) => {
                setLogDateFilter(d);
              }}
            />
            <Button
              onClick={() => {
                setCustomFilter('period', logDateFilter);
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.period || null,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '15%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {dayjs(record?.created_at).format('MM/DD/YYYY hh:mm A')}
          </div>
        );
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <RangePicker
              onChange={(v, d) => {
                setLogDateCreatedAtFilter(d);
              }}
            />
            <Button
              onClick={() => {
                setCustomFilter('created_at', logDateCreatedAtFilter);
              }}
            >
              Apply
            </Button>
          </div>
        );
      },
      filteredValue: tableParams?.filters?.created_at || null,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.status_value}
          </div>
        );
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.carrier}
                onChange={(value) => {
                  setCustomFilter('user', value);
                }}
              >
                {[
                  { name: 'Status_1', id: '1' },
                  { name: 'Status_2', id: '2' },
                ]?.map((user: any) => {
                  return (
                    <Select.Option key={user.id} value={user.id}>
                      {user.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('user');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: '',
    },
    {
      title: 'Info',
      dataIndex: 'info',
      key: 'info',
      width: '9%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.events?.length}
          </div>
        );
      },
    },

    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      width: '13%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div className="ubuntu" style={{ cursor: 'pointer' }}>
            {record?.user?.first_name} {record?.user?.last_name}
          </div>
        );
      },
    },
    {
      title: 'Roll Back',
      dataIndex: 'total_hours',
      key: 'total_hours',
      width: '8%',
      ellipsis: true,
      render: (value, record, index) => {
        const click = () => {
          dispatch(
            transactionRolllBackReq({
              id: record.id,
            }),
          );
        };
        return (
          <div className="ubuntu" style={{ display: 'flex' }}>
            <button onClick={click} className="roll-back-button">
              Roll Back
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getVehicleListReq({
        queryParams: {
          ...getParams(tableParams),
          with: ['carrier'],
        },
      }),
    );
  }, [tableParams]);

  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <div style={{ width: '100%' }} className="logs-table">
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={{
                ...tableParams.pagination,
                position: ['bottomCenter'],
                total: count,
              }}
              loading={{
                spinning: loading,
                indicator: <TableSpinner />,
              }}
              onChange={handleTableChange}
              //   rowSelection={{ ...rowSelection, columnWidth: 10 }}
              className="table-custom-original-tab"
              //   sticky
              //   scroll={{ y: window.innerHeight - 235 }}
            />
          </div>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
