function MalfunctionIcon({ fill }: { fill: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 25 25"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.716"
        d="M22.97 5.7a1.817 1.817 0 000-2.554L21.684 1.86a1.817 1.817 0 00-2.468 0l-6.857 6.772L5.587 1.86a1.817 1.817 0 00-2.554 0L1.747 3.146a1.817 1.817 0 000 2.554l6.857 6.858-6.857 6.771a1.817 1.817 0 000 2.554l1.286 1.286a1.817 1.817 0 002.554 0l6.772-6.857 6.857 6.771a1.817 1.817 0 002.554 0l1.286-1.285a1.817 1.817 0 000-2.555l-6.857-6.771L22.97 5.7z"
      ></path>
    </svg>
  );
}

export default MalfunctionIcon;
