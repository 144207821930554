import axios from 'axios';
import { ENV } from '../utils/constants';
import { getLocalAccessToken } from '../utils/requestCarrier';

const instance = axios.create({
  baseURL: `${ENV}/api/support/common/v1`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getLocalAccessToken()}`,
  },
});
const instanceMonitoring = axios.create({
  baseURL: `${ENV}/api/support/monitoring/v1`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getLocalAccessToken()}`,
  },
});

const uploadFileReq = async (file: FileList) => {
  const response = await instance.post('/storage/upload/chat', file);
  return response.data.data;
};

const getAllDOTReposts = async () => {
  const response = await instanceMonitoring.get('/report');
  const data = response.data;
  const returnData: IDOT = data.data;
  return returnData;
};

const getSingleDOTRepost = async (dotId: string) => {
  const response = await instanceMonitoring.get(`/report/${dotId}`);
  const data = response.data;
  const returnData: IDOTreq = data.data;
  return returnData;
};

const sendDOTReport = async (data: ISendDotReport) => {
  const response = await instanceMonitoring.post('/report/fmcsa/', data);
  const resData = response.data;
  const dot: IDOTreq = resData.data;
  if (!dot?.id) {
    const validJsonString = resData.substring(0, resData.indexOf('}{') + 1);
    const parsedObject = JSON.parse(validJsonString);
    const dot: IDOTreq = parsedObject.data;
    return dot.id;
  }
  return dot.id;
};
interface IGetForm {
  id: string;
  timestamp: number;
}
const getFormReq = async (data: IGetForm) => {
  const response = await instanceMonitoring.get(
    `/log/form/${data.id}/${data.timestamp}`,
    {
      params: ['driver', 'vehicle', 'carrier', 'unit'],
    },
  );
  const resData = response.data;
  const resDataStatus = resData.data;
  return resDataStatus;
};

const getHos = async (driverId: string) => {
  const response = await instanceMonitoring.get(`hos/get/${driverId}`);
  const resData = response.data;
  const resDataStatus: IHOS = resData.data;
  return resDataStatus;
};

const getRecap = async (driverId: string, date: string) => {
  const response = await instanceMonitoring.get(
    `hos/recap/${driverId}/${date}`,
  );
  const resData = response.data;
  const resDataStatus: IRecap[] = resData.data;
  return resDataStatus;
};

export const API = {
  uploadFileReq,
  getAllDOTReposts,
  sendDOTReport,
  getSingleDOTRepost,
  getFormReq,
  getHos,
  getRecap,
};

export interface IRecap {
  date: string;
  onduty: number;
  driving: number;
  vehicle_dist: number;
  driver_dist: number;
}

export interface IHOS {
  active: {
    cycle: number;
    prev_cycle: number;
    shift: number;
    drive: number;
    break: number;
  };
  suggested: {
    cycle: number;
    prev_cycle: number;
    shift: number;
    drive: number;
    break: number;
  };
}

export type IDOT = IDOTreq[];

export interface IDOTreq {
  driver: Driver;
  user: User;
  id: string;
  type: number;
  type_value: string;
  created_at: string;
  date_from: string;
  date_to: string;
  origin: number;
  method: number;
  status: number;
  status_value: string;
  code: string;
  processed_at: any;
  location: any;
  submission: any;
  reports: any;
  notice: string;
  violations: any;
  carrier: Carrier;
}
export interface Carrier {
  id: string;
  name: string;
  usdot: string;
  mcnumber: string;
  status: number;
  status_value: string;
  tz: any;
  tz_value: any;
  note: string;
  logo: string;
  with_account: boolean;
  address: any;
  company: any;
  offices: any;
  settings: any;
  terminals: any;
  authority_terminal: any;
  driver_groups: any;
  drivers: any;
  vehicles: any;
  devices: any;
}

export interface Driver {
  documents: any;
  id: string;
  first_name: string;
  last_name: string;
  username: string;
  status: number;
  phone: string;
  email: string;
  cdl: string;
  cdl_state: number;
  cdl_state_value: string;
  cdl_expiration: string;
  medical_card: string;
  medical_card_expiration: string;
  measurement_system: number;
  measurement_system_value: string;
  dst: number;
  dst_value: string;
  first_day: number;
  period_starting_time: string;
  compliance_mode: number;
  compliance_mode_value: string;
  motion_threshold: number;
  cargo_type: number[];
  cargo_type_values: string[];
  hos_rules: number;
  hos_rules_value: string;
  restart: number;
  restart_value: string;
  rest_break: number;
  rest_break_value: string;
  short_haul: boolean;
  exempt_driver: boolean;
  exempt_driver_notice: any;
  personal_conveyance: boolean;
  yard_move: boolean;
  adverse_condition: boolean;
  unlimited_trailers: boolean;
  unlimited_documents: boolean;
  alarm_allow: any;
  notes: any;
  signature: any;
}

export interface User {
  role: any;
  logs: any;
  company: any;
  offices: any;
  address: any;
  id: string;
  email: string;
  personal_email: any;
  phone: string;
  first_name: string;
  last_name: string;
  status: number;
  note: any;
}

export interface ISendDotReport {
  driver?: string;
  type?: string;
  origin?: string;
  date_from?: string;
  date_to?: string;
  code?: string;
  user?: string;
  method?: string;
  notice?: string;
}
