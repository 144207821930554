import { useNavigate } from 'react-router-dom';
import styles from './MainHeader.module.css';
import { motion } from 'framer-motion';
import BackAngleIcon from '../../img/exitIcon.svg';
import { SetPassword } from '../modules/carrier/modals/CarrierSetPassword';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCarrierPasswordReq } from '../../actions';
interface IMainHeaderProps {
  titles: { text: string }[];
  back?: boolean;
  link?: any;
  password?: boolean;
  trailer?: string[];
}

type MainHeaderPropsWithCondition = IMainHeaderProps &
  ({ back: true; link: string | number } | { back?: false; link?: string });

const MainHeader = ({
  titles,
  back,
  link,
  password,
  trailer,
}: MainHeaderPropsWithCondition) => {
  const navigate = useNavigate();
  const backClick = () => {
    if (!back) return;
    navigate(link);
  };
  const [currentCarrier, setCurrentCarrier] = useState({
    id: '',
    name: '',
  });
  const [accautnModalOpen, setAccauntModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [showMore, setShowMore] = useState(1);

  const showMoreClick = () => {
    setShowMore((prevState) => {
      if (prevState === 1) {
        return trailer?.length || 0;
      } else {
        return 1;
      }
    });
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerCon}>
        {back && (
          <motion.button
            initial={{
              opacity: 0,
              scale: 0,
            }}
            whileInView={{
              opacity: [0, 1],
              scale: [0, 1],
            }}
            onClick={backClick}
            className={styles.back}
          >
            <img src={BackAngleIcon} alt="back" />
          </motion.button>
        )}
        {titles.map((title, index) => (
          <h2
            key={index}
            className={`${index != 0 ? `${styles.second}` : `${styles.main}`}`}
          >
            {index != 0 && <span className={styles.point} />}
            {title.text}
          </h2>
        ))}
      </div>
      {trailer?.length != 0 && trailer && (
        <>
          <p>
            <span
              style={{
                fontWeight: 700,
              }}
              className="bold ubuntu"
            >
              Current Trailer:{' '}
            </span>
            {trailer.length != 0 && (
              <>
                {trailer.slice(0, showMore).map((tr, index) => (
                  <>
                    {tr} / type {index}
                    {index !== trailer.length - 1 && showMore != 1 && ', '}
                  </>
                ))}
              </>
            )}{' '}
            {trailer.length >= 2 && (
              <span
                style={{
                  fontWeight: 700,
                }}
                onClick={showMoreClick}
                className="orange pointer"
              >
                Show more
              </span>
            )}
          </p>
        </>
      )}
    </div>
  );
};

export default MainHeader;
