import { useEffect, useState } from 'react';
import { Header } from '../header/header';
import styles from './ClientStyles.module.css';
import { Footer } from '../header/footer';
import { SideBar } from '../SideBar/SideBar';
import { motion } from 'framer-motion';
import { Outlet } from 'react-router-dom';
import Chat from '../chat/Chat';
import { getTzValue } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getDriverLogDate } from '../../actions';
import dayjs from 'dayjs';
import { ChatProvider } from '../chat/ChatProvider';

const ClientLayoutTest = ({}) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);

  return (
    <ChatProvider>
      <div className={styles.container}>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          className={styles.mainHeader}
        >
          <Header toggleMenu={() => setIsOpenSidebar(!isOpenSidebar)} />
        </motion.div>
        <div className={styles.body}>
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            className={styles.header}
          >
            <SideBar isOpen={isOpenSidebar} />
          </motion.div>
          <div className={styles.client}>
            <Outlet />
          </div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
        >
          <Footer />
        </motion.div>
        <Chat />
      </div>
    </ChatProvider>
  );
};

export default ClientLayoutTest;
