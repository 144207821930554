import { Button, DatePicker, Form, Modal } from 'antd';
import { InputSelectV2, TextInputV2 } from '../../common/doubleinput';
import { disabledDate } from '../dashboard/DasboardHeader';
import styles from './DVIRModal.module.css';
import { TimePickerLogFieldTest } from '../../common/doubleinput/TimePickerLogFieldTest';
interface IDVIRModalProps {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const DVIRModal = ({ open, onOk, onCancel }: IDVIRModalProps) => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const createRecordInputs = [
    {
      type: 'date',
      title: 'Date',
      placeholder: 'Set Date',
      name: 'date',
    },
    {
      type: 'text',
      title: 'Time',
      placeholder: 'Set time',
      name: 'time',
    },
    {
      type: 'text',
      title: 'Location',
      placeholder: 'Set Location',
      name: 'location',
    },
    {
      type: 'select',
      title: 'Type',
      placeholder: 'Select Type',
      name: 'type',
      options: [
        {
          key: 'Pre TI',
          value: 'Pre TI',
        },
        {
          key: 'Post TI',
          value: 'Post TI',
        },
        {
          key: 'Other',
          value: 'Other',
        },
      ],
    },
    {
      type: 'select',
      title: 'Vehicle',
      placeholder: 'Select Vehicle',
      name: 'vehicle',
      options: [
        {
          key: 'Vehicle1',
          value: 'Vehicle1',
        },
        {
          key: 'Vehicle2',
          value: 'Vehicle2',
        },
      ],
    },
    {
      type: 'text',
      title: 'Trailer',
      placeholder: 'Set Trailer',
      name: 'trailer',
    },
    {
      type: 'text',
      title: 'Photo',
      placeholder: 'Set Photo',
      name: 'photo',
    },
    {
      type: 'text',
      title: 'Description',
      placeholder: 'Set Description',
      name: 'description',
    },
    {
      type: 'select',
      title: 'Status',
      placeholder: 'Select Vehicle',
      name: 'vehicle',
      options: [
        {
          key: 'Resolved',
          value: 'Resolved',
        },
        {
          key: 'UnResolved',
          value: 'UnResolved',
        },
      ],
    },
    {
      type: 'select',
      title: 'Signature',
      placeholder: 'Select Signature',
      name: 'signature',
      options: [
        {
          key: 'Signed',
          value: 'Signed',
        },
        {
          key: 'UnSigned',
          value: 'UnSigned',
        },
      ],
    },
  ];
  return (
    <>
      <Modal
        title="Create DVIR"
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        width={650}
        footer={null}
      >
        <Form>
          <div className={styles.form}>
            {createRecordInputs.map((input) => (
              <>
                {input.type === 'select' && (
                  <InputSelectV2
                    showSearch
                    title={input.title}
                    name={input.name}
                    options={input.options}
                    placeholder={input.placeholder}
                    // onChange={handleSelectChange}
                  />
                )}
                {input.type === 'date' && (
                  <TimePickerLogFieldTest
                    form={form}
                    title={input.title}
                    name={input.name}
                  />
                )}
                {input.type === 'range' && (
                  <Form.Item name={input.name}>
                    <div className="input-item-title">{input.title}</div>
                    <RangePicker
                      name={input.name}
                      //   onChange={(e) => rangePickerChange(e)}
                      //   defaultValue={[today.subtract(7, 'days'), today]}
                      className={styles.rangePicker}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                )}
                {input.type === 'text' && (
                  <TextInputV2
                    title={input.title}
                    name={input.name}
                    placeholder={input.placeholder}
                  />
                )}
              </>
            ))}
            <div>
              <Form.Item style={{ width: '100%', display: 'flex' }}>
                <Button
                  onClick={onCancel}
                  type="primary"
                  htmlType="submit"
                  className="orange"
                  style={{ width: '65px', marginRight: 12 }}
                >
                  Save
                </Button>
                <Button
                  className="grey"
                  onClick={onCancel}
                  style={{ width: '85px', marginRight: 12 }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DVIRModal;
