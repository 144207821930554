import dayjs from 'dayjs';
import { parseDateGeneralStringFormat } from '../../../log-utils';
import { ChartPointsData } from '../interface/ChartInterface';
import EngineIcon from '../icons/EngineIcon';

const drawChartDataPoints = (
  timestamp: number,
  cellWidth: number,
  timezone: string,
) => {
  const t = parseDateGeneralStringFormat(timestamp);
  const timeOnly = dayjs(t, 'MM/DD/YYYY hh:mm:ss A')
    .tz(timezone)
    .format('HH:mm:ss');
  const [hours, minutes, seconds] = timeOnly.split(':').map(parseFloat);
  const totalMinutes = hours * 60 + minutes + seconds / 60;
  return (totalMinutes / 60) * cellWidth;
};
export const chartDataCreatePoint = (
  type: string | undefined,
  timestamp: number,
  cellWidth: number,
  timezone: string,
  id: string,
): null | ChartPointsData => {
  switch (type) {
    case 'Power-up':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        color: 'green',
        tooltipText: type,
        borderStyle: 'solid',
        img: 'Engine',
        id,
      };
    case 'Power-up (RLP)':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        color: 'green',
        borderStyle: 'dashed',
        tooltipText: type,
        img: 'Engine',
        id,
      };
    case 'Shut-down':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        borderStyle: 'solid',
        color: 'red',
        tooltipText: type,
        img: 'Engine',
        id,
      };
    case 'Shut-Down (RLP)':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        color: 'red',
        borderStyle: 'dashed',
        img: 'Engine',
        tooltipText: type,
        id,
      };
    case 'Login':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        color: 'green',
        borderStyle: 'dashed',
        img: 'LogIn',
        tooltipText: type,
        id,
      };
    case 'Logout':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        color: 'red',
        borderStyle: 'dashed',
        img: 'LogOut',
        tooltipText: type,
        id,
      };
    case 'Certification (1)-MM/DD/YYYY':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        color: 'blue',
        borderStyle: 'dashed',
        img: 'Circle',
        tooltipText: type,
        id,
      };
    case 'Certification (2 - 9)- MM/DD/YYYY':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'bottom',
        color: 'blue',
        borderStyle: 'dashed',
        img: 'Circle',
        tooltipText: type,
        id,
      };
    case 'Malfunction':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'top',
        color: 'red',
        borderStyle: 'dashed',
        img: 'Malfunction',
        tooltipText: type,
        id,
      };

    case 'Malfunction cleared':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'top',
        color: 'green',
        borderStyle: 'dashed',
        img: 'Malfunction cleared',
        tooltipText: type,
        id,
      };
    case 'Power data':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'top',
        color: 'red',
        borderStyle: 'dashed',
        img: 'Malfunction',
        tooltipText: type,
        id,
      };

    case 'Power data cleared':
      return {
        label: type,
        x: drawChartDataPoints(timestamp, cellWidth, timezone),
        postion: 'top',
        color: 'green',
        borderStyle: 'dashed',
        img: 'Malfunction cleared',
        tooltipText: type,
        id,
      };
    default:
      return null;
  }
};
