import { Col, Form } from 'antd';
import { useMemo } from 'react';
import styles from './RecordPage.module.css';
const RecordErrorField = (props: any) => {
  const {
    rules = [],
    name = '',
    title = '',
    span = 24,
    pathName = '',
    error,
    errorVisible,
    secondError,
    onClick,
    value,
  } = props;
  const getName = useMemo(() => {
    return pathName ? [...pathName, name] : name;
  }, [pathName, name]);
  return (
    <Col
      span={span}
      className="input-container-v2"
      style={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {title ? (
        title === 'invisible' ? (
          <div
            className="input-item-title input-title ubuntu"
            style={{ opacity: 0 }}
          >
            {title}
          </div>
        ) : (
          <div className="input-item-title input-title ubuntu">{title}</div>
        )
      ) : null}
      <Form.Item rules={rules} name={getName} style={{ width: '100%' }}>
        <div
          className={styles.item}
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        >
          {value}
        </div>
      </Form.Item>
      <div className="input-item-error">
        {errorVisible === 1 && error}
        {errorVisible === 2 && secondError}
      </div>
    </Col>
  );
};

export default RecordErrorField;
