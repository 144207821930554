import { useState } from 'react';

import { Row, Col } from 'antd';
import { Form, Button, Select } from 'antd';
import './log-top-panel.scss';
import LogBulkInput from './LogBulkInput';

export const LogBulkPanel = () => {
  const [form] = Form.useForm();
  const handleSubmit = async () => {};

  const options = [
    { value: 'HH.MM', label: 'Time' },
    { value: 'Odometer', label: 'Odometer' },
    { value: 'Engine Hours', label: 'Engine Hours' },
    { value: 'Select Driver', label: 'Driver' },
    { value: 'Select Vehicle', label: 'Vehicle' },
    { value: 'Select ELD', label: 'ELD' },
  ];
  const [placeholder, setPlaceholder] = useState<any>('');

  return (
    <Row style={{ width: '100%' }}>
      <Col
        span={24}
        className="ubuntu bulk-container"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Form
          form={form}
          name="test"
          onFinish={handleSubmit}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 5,
          }}
        >
          <Form.Item name={'type'}>
            <Select
              optionFilterProp="children"
              options={options}
              style={{ width: 150, height: '100%' }}
              onChange={(e) => setPlaceholder(e)}
            />
          </Form.Item>
          <Form.Item name={'value'} style={{ minWidth: 200 }}>
            <LogBulkInput title="value" type={placeholder} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="orange"
              style={{ width: '65px', marginLeft: 12 }}
            >
              Apply
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
