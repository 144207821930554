import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Col, Row } from 'antd';
import { InputFetchCarrierSelectV2 } from '../../../common/doubleinput/InputFetchCarrierSelect';
import { InputSelectV2, TextInputV2 } from '../../../common/doubleinput';
import { carrierData } from '../../driver/constant';
import { setCurrentDeviceCarrier } from '../../../../actions';

import { getValidation, VALIDATION_TYPE } from '../../../../utils/validation';
import { DeviceField } from '../constant';
const { REQUIRED } = VALIDATION_TYPE;

export const CarrierDynamicDeviceField = (props: any) => {
  const { isRequired = false, isReadonlyCarrier = false, form } = props;

  const dispatch = useDispatch();
  const { loading, vehicle } = useSelector((state: any) => state.device);
  const { loading: carrierLoading, carrierList } = useSelector(
    (state: any) => state.carrier,
  );
  const { currentCarrier } = useSelector((state: any) => state.device);

  const [carrierOptions, setCarrierOptions] = useState([]);

  const [statusOptions, setStatusOptions] = useState(
    carrierData.status.filter((item) => item.value !== 'Blocked').reverse(),
  );

  useEffect(() => {
    setCarrierOptions(
      carrierList.map((carrier: any) => {
        return {
          key: carrier.name,
          value: carrier.id,
        };
      }),
    );
  }, [carrierList]);

  React.useEffect(() => {
    const inactiveStatus = carrierData.status.filter(
      (status: any) => status.key !== 1,
    );
    if (currentCarrier.status_value) {
      form.setFieldValue('status_value', currentCarrier.status_value);
    }
    if (currentCarrier?.status === 1) {
      setStatusOptions(
        carrierData.status.filter((item) => item.value !== 'Blocked').reverse(),
      );
    } else {
      setStatusOptions(inactiveStatus);
    }
  }, [currentCarrier]);

  const StatusProps = {
    name: 'status_value',
    label: 'Carrier Status',
    rules: [getValidation(REQUIRED, '')],
    hasFeedback: true,
    title: 'Carrier Status *',
    span: 24,
    width: '100%',
    disabled: 'true',
  };

  return (
    <>
      <Row>
        <Col
          span={6}
          className="input-item-wrapper"
          style={{ alignItems: 'flex-start' }}
        >
          <div className="input-item-wrapper">
            <div>
              {'Carrier'}
              {isRequired && ' *'}
            </div>
          </div>
        </Col>
        <Col span={9}>
          <InputFetchCarrierSelectV2
            {...props}
            rules={[getValidation(REQUIRED, 'Status')]}
            name={DeviceField.CARRIER}
            title={'Carrier*'}
            placeholder="Select Carrier"
            options={carrierOptions}
            span={24}
            width={'95%'}
            isReadonlyCarrier={isReadonlyCarrier}
            onChange={(id: any) => {
              const foundCarrier = carrierList.find(
                (carrier: any) => carrier.id === id,
              );
              dispatch(
                setCurrentDeviceCarrier({
                  ...foundCarrier,
                  defaultCarrier: false,
                }),
              );
            }}
          />
        </Col>
        <Col span={9}>
          <TextInputV2 {...StatusProps} />
        </Col>
      </Row>
    </>
  );
};
