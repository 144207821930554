import { PieChart } from '@mui/x-charts/PieChart';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import styles from './LogDashboardPanelItem.module.css';
interface ILogDashboardPanelItem {
  series?: MakeOptional<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type'
  >[];
  text: string;
  time?: string;
  certificated?: boolean;
  color: string;
}

const LogDashboardPanelItem = ({
  series,
  text,
  time,
  certificated,
  color = '#FFAB00',
}: ILogDashboardPanelItem) => {
  return (
    <div className={styles.item}>
      {series && (
        <div>
          <PieChart
            style={{ pointerEvents: 'none' }}
            skipAnimation
            series={series}
            width={34}
            height={30}
            colors={[color, '#D8E3F3']}
          />
        </div>
      )}
      <div>
        <div style={{ fontWeight: 'bold', marginBottom: 5, color: '#374957' }}>
          {text}
        </div>
        <div
          style={{
            color: certificated === false ? '#374957' : '#374957',
          }}
        >
          {certificated ? (
            <p
              style={{
                margin: 0,
                color: '#374957',
              }}
            >
              {time === 'DVIR' ? 'Yes' : 'Certificated'}
            </p>
          ) : (
            time
          )}
        </div>
      </div>
    </div>
  );
};

export default LogDashboardPanelItem;
