import { notification } from 'antd';
import { put, call, all, takeLatest } from 'redux-saga/effects';
import { getLogListFailed } from '../actions';
import request from '../utils/requestMonitoring';
import { getUnLogReqSucces, UnLogActionTypes } from '../actions/undifiend';

export function* getUnLogListReq({ payload }: any): any {
  try {
    const { data } = yield call(request.get, `log/undefined`, {
      params: payload.queryParams,
    });
    yield put(getUnLogReqSucces(data));
  } catch (e: any) {
    yield put(getLogListFailed(e.message));
    yield call(notification.error, {
      message: 'Something went wrong, try again later',
    });
  }
}

export function* assignUnLogs({ payload }: any): any {
  try {
    const { data } = yield call(request.post, `log/reassign`, payload.body);
    yield call(notification.success, {
      message: 'Log updated successfully',
    });
  } catch (e: any) {
    yield put(getLogListFailed(e.message));
    yield call(notification.error, {
      message: 'Something went wrong, try again later',
    });
  }
}

export default function* root() {
  yield all([takeLatest(UnLogActionTypes.GET_LOG_LIGST_REQ, getUnLogListReq)]);
  yield all([takeLatest(UnLogActionTypes.ASSIGN_UN_LOG_REQ, assignUnLogs)]);
}
