function TimerIcon({ fill }: { fill: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 0 23 25"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.716"
        d="M11.272 24.037a9.429 9.429 0 10-9.428-9.429 9.429 9.429 0 009.428 9.429zM8.7 1.75h5.142M11.27 1.75v3.429"
      ></path>
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.716"
        d="M8.7 11.18l2.57 3.428h4.286M19.84 4.32l1.714 1.715M2.699 4.32L.984 6.035"
      ></path>
    </svg>
  );
}

export default TimerIcon;
