// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyD2MqVnDlqsAGe2Ql8fRfMfLyANsxQUvdE',
  authDomain: 'hg-driver.firebaseapp.com',
  databaseURL: 'https://hg-driver-default-rtdb.firebaseio.com',
  projectId: 'hg-driver',
  storageBucket: 'hg-driver.appspot.com',
  messagingSenderId: '302672750199',
  appId: '1:302672750199:web:c3c1ab1340cfadee718872',
  measurementId: 'G-YP48E37RQG',
};

const firebase = initializeApp(firebaseConfig);
const database = getDatabase(firebase);
export { firebase, database };
