import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-datalabels';
import 'chartjs-adapter-date-fns';
import styles from './ChartTestV.module.css';
import ChartBg from './ChartBg';
import cellImg from '../../../../../img/cell.png';
import { useSelector } from 'react-redux';
import {
  ChartDalabels,
  ChartDataSets,
  ChartForrmaterData,
  ChartPointsData,
  DriverLogs,
} from './interface/ChartInterface';
import {
  CahrtOptions,
  ChartPlugin,
  generateLabels,
} from './utils/ChartSettings';
import { getEventLabel } from '../../log-utils';
import { generateChartType } from './utils/ChartType';
import {
  chartFromDateToTimeStamp,
  convertTimestampToTimezone,
  getCurrentDate,
} from './utils/ChartDateForrmatter';
import { chartDataCreate } from './utils/ChartDataCreate';
import { chartDataLables } from './utils/ChartDataLabelsCreate';
import { chartDataCreatePoint } from './utils/ChartDataCreatePoints';
import ChartPoints from './components/CahrtPoints';
import { useWindowResize } from '../../../../../hooks/useWindowResize';
import { getTzValue } from '../../../../../utils/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IChoosed } from '../../DriverLogList';
dayjs.extend(utc);
dayjs.extend(timezone);
Chart.register(...registerables);
const ChartTestV = ({
  datalabels,
  setDataLabels,
  choosed,
  setChoosed,
}: {
  datalabels: ChartDalabels[];
  setDataLabels: React.Dispatch<React.SetStateAction<ChartDalabels[]>>;
  choosed: string;
  setChoosed: React.Dispatch<React.SetStateAction<IChoosed>>;
}) => {
  const labels: string[] = generateLabels();
  const divRef = useRef<HTMLDivElement>(null);
  const [cellWidth, setCellWidth] = useState(0);
  const [bgHeight, setBgHeight] = useState(0);
  const logs: DriverLogs = useSelector((state: any) => state.driverLog.logList);
  const [datasets, setDatasets] = useState<ChartDataSets[]>([]);
  const [dataPoints, setDataPoints] = useState<ChartPointsData[]>([]);
  const [offAllTime, setOffAllTime] = useState({
    h: 0,
    m: 0,
    s: 0,
  });
  const [sbAllTime, setSbAllTime] = useState({
    h: 0,
    m: 0,
    s: 0,
  });
  const [dAllTime, setDAllTime] = useState({
    h: 0,
    m: 0,
    s: 0,
  });
  const [onAllTime, setOnAllTime] = useState({
    h: 0,
    m: 0,
    s: 0,
  });
  const { windowWidth } = useWindowResize();
  const driverLogDate = useSelector(
    (state: any) => state?.driverLog?.driverLogDate,
  );
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;

  const tz = getTzValue(tzValue);
  useEffect(() => {
    if (divRef.current) {
      const width =
        divRef.current.getBoundingClientRect().width / labels.length;
      setCellWidth(width - 0.05);
    }
  }, [windowWidth]);

  useEffect(() => {
    setDataLabels([]);
    const chartFormmatedData: ChartForrmaterData[] = [];
    const chartDataPoints: ChartPointsData[] = [];
    const currentDate = getCurrentDate(tz);
    const inittialTime = chartFromDateToTimeStamp(driverLogDate, tz);
    const data: ChartDataSets[] = [
      {
        label: 'start',
        data: [{ x: inittialTime.startOfDay, y: 3 }],
        pointRadius: 0,
        borderWidth: 2,
        borderColor: '#FF6B00',
        stepped: true,
        borderDash: [],
      },
      {
        label: 'end',
        data: [{ x: inittialTime.endOfDay, y: 3 }],
        pointRadius: 0,
        borderWidth: 2,
        borderColor: 'orange',
        stepped: true,
        borderDash: [],
      },
      // {
      //   borderColor: '#468DCE',
      //   borderDash: [],
      //   data: [
      //     { x: 1722546000000, y: 4 },
      //     { x: 1722509735000, y: 4 },
      //     { x: 1722509735000, y: 3 },
      //   ],
      //   label: 'OFF',
      //   pointRadius: 0,
      //   stepped: true,
      //   borderWidth: 2,
      // },
      // {
      //   borderColor: '#468DCE',
      //   borderDash: [],
      //   data: [
      //     { x: 1722509735000, y: 3 },
      //     { x: 1722509743000, y: 3 },
      //     { x: 1722509743000, y: 4 },
      //   ],
      //   label: 'ON',
      //   pointRadius: 0,
      //   stepped: true,
      //   borderWidth: 2,
      // },
    ];
    logs?.map((log) => {
      const chartLineInfo = generateChartType(
        getEventLabel(log.event_type, log.event_code),
        convertTimestampToTimezone(log.timestamp, tz),
        log.id,
      );
      const chartPointsInfo = chartDataCreatePoint(
        getEventLabel(log.event_type, log.event_code),
        convertTimestampToTimezone(log.timestamp, tz),
        cellWidth,
        tz,
        log.id,
      );
      if (chartPointsInfo) {
        chartDataPoints.push(chartPointsInfo);
      }
      if (chartLineInfo) {
        chartFormmatedData.push(chartLineInfo);
      }
    });
    chartDataLables(
      cellWidth,
      bgHeight,
      chartFormmatedData,
      setDataLabels,
      driverLogDate,
      inittialTime.endOfDay,
      inittialTime.startOfDay,
      tz,
    );
    chartDataCreate(
      driverLogDate,
      currentDate,
      chartFormmatedData,
      data,
      inittialTime.startOfDay,
      inittialTime.endOfDay,
    );
    setDataPoints(chartDataPoints);
    setDatasets(data);
  }, [logs, driverLogDate, tzValue]);

  const dataLabelClick = (id: string, index: number, color: string) => {
    setChoosed({ id, color });
    labelsRefs.current[index].focus();
  };
  const labelsRefs = useRef<HTMLDivElement[]>([]);

  const handleBlur = () => {
    setChoosed({ id: '', color: 'F' });
  };
  const data = {
    datasets,
  };

  const getLablesTime = (labelNumber: number) => {
    const labels = datalabels
      .map((label) => {
        if (label.y === labelNumber) {
          return {
            h: label.hour,
            m: label.minute,
            s: label.seconds,
          };
        }
        return null;
      })
      .filter(
        (label): label is { h: number; m: number; s: number } => label !== null,
      );

    const result = labels.reduce(
      (accumulator, current) => {
        return {
          h: accumulator.h + current.h,
          m: accumulator.m + current.m,
          s: accumulator.s + current.s,
        };
      },
      { h: 0, m: 0, s: 0 },
    );

    result.m += Math.floor(result.s / 60);
    result.s = result.s % 60;

    result.h += Math.floor(result.m / 60);
    result.m = result.m % 60;

    return result;
  };

  useEffect(() => {
    if (datalabels.length !== 0) {
      const offTime = getLablesTime(4);
      setOffAllTime(offTime);
      const sbTime = getLablesTime(3);
      setSbAllTime(sbTime);
      const dTime = getLablesTime(2);
      setDAllTime(dTime);
      const onTime = getLablesTime(1);
      setOnAllTime(onTime);
    }
  }, [datalabels]);

  function addTimes(times: { h: number; m: number; s: number }[]): string {
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    times.forEach((time) => {
      totalHours += time.h;
      totalMinutes += time.m;
      totalSeconds += time.s;
    });

    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds = totalSeconds % 60;

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes = totalMinutes % 60;

    const result = `${totalHours}hr ${totalMinutes}min`;
    return result;
  }
  return (
    <>
      <div className={styles.chart}>
        <div className={styles.leftLabels}>
          <div
            style={{ height: bgHeight / 5 - 1 }}
            className={styles.leftLabelsTop}
          >
            Off
          </div>
          <div
            style={{ height: bgHeight / 5 - 1 }}
            className={styles.leftLabelsMiddle}
          >
            SB
          </div>
          <div
            style={{ height: bgHeight / 5 - 1 }}
            className={styles.leftLabelsMiddle}
          >
            D
          </div>
          <div
            style={{ height: bgHeight / 5 }}
            className={styles.leftLabelsBottom}
          >
            On
          </div>
        </div>
        <div style={{ width: '100%', position: 'relative' }}>
          <div className={styles.topLabel}>
            {labels.map((text, index) => {
              return (
                <>
                  <p
                    key={index}
                    style={{
                      fontSize: text === 'NooN' ? 10 : 12,
                      width: cellWidth,
                    }}
                    className={styles.text}
                  >
                    {text}
                  </p>
                </>
              );
            })}
          </div>
          <div ref={divRef} style={{ width: '100%', position: 'relative' }}>
            <div style={{ width: '100%' }}>
              <ChartBg
                setBgHeight={setBgHeight}
                cellImg={cellImg}
                cellWidth={cellWidth}
                labelsLength={labels.length}
                height={bgHeight}
              />
              {datalabels.map((datalabel, index) => {
                const isTop = datalabels[index + 1]
                  ? datalabels[index + 1].top < datalabel.top
                    ? datalabel.top + 15.5
                    : datalabel.top + 2.5
                  : datalabel.top + 2.5;
                const smallTime = datalabel.width < 20;
                return (
                  <>
                    {datalabel.hour + datalabel.minute != 0 && (
                      <div
                        tabIndex={0}
                        ref={(el) => {
                          if (el) labelsRefs.current[index] = el;
                        }}
                        onClick={() =>
                          dataLabelClick(datalabel.id, index, datalabel.color)
                        }
                        onBlur={handleBlur}
                        key={index}
                        style={{
                          height: 15,
                          top: isTop,
                          left: datalabel.left,
                          width: smallTime ? 'fit-content' : datalabel.width,
                          display: 'flex',
                          alignItems: 'start',
                          zIndex: 4,
                          cursor: 'pointer',
                        }}
                        className={styles.dataLabel}
                      >
                        <p>
                          {datalabel.hour != 0 ? `${datalabel.hour}h` : <></>}{' '}
                          {datalabel.minute}m
                        </p>
                      </div>
                    )}
                  </>
                );
              })}
              <ChartPoints
                choosed={choosed}
                setChoosed={setChoosed}
                points={dataPoints}
                bgHeight={bgHeight}
              />
            </div>
            <Line
              style={{
                height: bgHeight,
                position: 'absolute',
                top: -26,
                width: '100%',
                zIndex: 2,
              }}
              options={CahrtOptions}
              plugins={ChartPlugin}
              data={data}
            />
          </div>
        </div>
        <div className={styles.rightLabels}>
          <div
            style={{ height: bgHeight / 5 - 1 }}
            className={styles.rightLabelsTop}
          >
            {offAllTime.h}hr {offAllTime.m}min
          </div>
          <div
            style={{ height: bgHeight / 5 - 1 }}
            className={styles.rightLabelsMiddle}
          >
            {sbAllTime.h}hr {sbAllTime.m}min
          </div>
          <div
            style={{ height: bgHeight / 5 - 1 }}
            className={styles.rightLabelsMiddle}
          >
            {dAllTime.h}hr {dAllTime.m}min
          </div>
          <div
            style={{ height: bgHeight / 5 }}
            className={styles.rightLabelsBottom}
          >
            {onAllTime.h}hr {onAllTime.m}min
          </div>
          <div
            style={{
              height: bgHeight / 5,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {addTimes([offAllTime, sbAllTime, dAllTime, onAllTime])}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartTestV;
