import React, { useState } from 'react';
import { Form, Select, Radio, Checkbox } from 'antd';

export const InputCheckboxGroupV2 = (props: any) => {
  const {
    rules = [],
    name = '',
    icon = '',
    placeholder = '',
    label = '',
    disabled = false,
    hasFeedback = true,
    options = [],
    direction = 'row',
    form,
    isGroup = false,
    isSingleField = false,
  } = props;

  const { Option } = Select;
  const getName = (name: any, type: any) => {
    if (isSingleField) {
      return type;
    }
    return isGroup ? [name, type] : name;
  };
  return (
    <div
      className="input-container-v2 input-checkbox"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1.1fr 0.9fr',
        gap: 5,
      }}
    >
      {options.map((option: any, i: any) => {
        return (
          <Form.Item
            name={getName(name, option.value)}
            hasFeedback={hasFeedback}
            key={i}
            valuePropName={'checked'}
          >
            <Checkbox
              onChange={(e) => {
                form.setFieldValue(
                  getName(name, option.value),
                  e.target.checked,
                );
              }}
              style={{
                lineHeight: '32px',
                margin: direction === 'row' ? '0px 16px 0px 0px' : '0px 0px',
              }}
              disabled={disabled}
              checked
            >
              {option.key}
            </Checkbox>
          </Form.Item>
        );
      })}
    </div>
  );
};
