import styles from '../ChartTestV.module.css';
interface IChartTooltip {
  left: number;
  text: string;
  visible: boolean;
  position: string;
}

const ChartTooltip = ({ left, text, visible, position }: IChartTooltip) => {
  return (
    <>
      <div
        style={{
          left: left + 2,
          opacity: visible ? 1 : 0,
          top: position === 'top' ? -50 : undefined,
        }}
        className={styles.pointTooltip}
      >
        <p> {text}</p>
      </div>
    </>
  );
};

export default ChartTooltip;
