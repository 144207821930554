import { Table, DatePicker, Button, Select } from 'antd';
import { useEffect, useState } from 'react';
import { NoPermission } from '../../common/NoPermission';
import { InputSearch } from '../../common/doubleinput/InputSearch';
import { AllPermissionsType } from '../role/constant';
import { usePermissions } from '../../../hooks/usePermissions';
import { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import CreateRecordModal from './CreateRecordModal';
import { useNavigate } from 'react-router-dom';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import { API, IDOT, IDOTreq } from '../../../api/API';
import dayjs from 'dayjs';
import TableSpinner from '../../spiner/TableSpinner';
const { RangePicker } = DatePicker;

const DotPage = () => {
  const { checkPermission } = usePermissions();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    clearOrderFilters,
    setSearchParam,
    hasFiltersOrOrder,
    clearFilter,
    clearOrder,
    tableParams,
    handleTableChange,
  } = useTableParams({});

  const [dot, setDot] = useState<IDOT>([]);
  const [loading, setLoading] = useState(true);
  const columns: ColumnsType<IDOTreq> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Driver',
      key: 'driver',
      dataIndex: 'driver',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>
            {record.driver.first_name} {record.driver.last_name} (
            {record.driver.username})
          </div>
        );
      },

      width: '15%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <RangePicker />
            <Button>Apply</Button>
          </div>
        );
      },
      sorter: {
        compare: (a: any, b: any) => a.identificator - b.identificator,
        multiple: 5,
      },
      filteredValue: null,
    },
    {
      title: 'Period',
      key: 'period',
      dataIndex: 'period',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>
            {dayjs(record.date_from).format('MM/DD/YYYY')}
            {` - `}
            {dayjs(record.date_to).format('MM/DD/YYYY')}
          </div>
        );
      },
      width: '25%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <RangePicker />
            <Button>Apply</Button>
          </div>
        );
      },
      sorter: {
        compare: (a: any, b: any) => a.identificator - b.identificator,
        multiple: 5,
      },
      filteredValue: null,
    },
    {
      title: 'Created at',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (name, record, index) => {
        return (
          <div style={{ marginLeft: 10 }}>
            {dayjs(record.created_at).format('MM/DD/YYYY hh:mm:ss')}
          </div>
        );
      },
      width: '25%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <RangePicker />
            <Button>Apply</Button>
          </div>
        );
      },
      sorter: {
        compare: (a: any, b: any) => a.identificator - b.identificator,
        multiple: 5,
      },
      filteredValue: null,
    },
    {
      title: 'Carrier',
      key: 'carrier',
      dataIndex: 'carrier',
      render: (name, record, index) => {
        return <div style={{ marginLeft: 10 }}>{record.carrier.name}</div>;
      },
      width: '10%',
      ellipsis: true,
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select style={{ width: 200, marginBottom: 20 }}>
                <Select.Option value={'carrier1'}>carrier1</Select.Option>
                <Select.Option value={'carrier2'}>carrier2</Select.Option>
                <Select.Option value={'carrier3'}>carrier3</Select.Option>
              </Select>
            </div>
            <Button style={{ width: 80, height: 40 }} className="orange">
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: null,
    },
    {
      title: 'Origin',
      key: 'origin',
      dataIndex: 'origin',
      render: (name, record, index) => {
        const origin: any = {
          0: 'User',
          1: 'Driver',
        };
        return <div style={{ marginLeft: 10 }}>{origin[record.origin]}</div>;
      },
      width: '10%',
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => a.identificator - b.identificator,
        multiple: 5,
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select style={{ width: 200, marginBottom: 20 }}>
                <Select.Option value={'origin1'}>origin1</Select.Option>
                <Select.Option value={'origin2'}>origin2</Select.Option>
                <Select.Option value={'origin3'}>origin3</Select.Option>
              </Select>
            </div>
            <Button style={{ width: 80, height: 40 }} className="orange">
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: null,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (name, record, index) => {
        return <div style={{ marginLeft: 10 }}>{record.status_value}</div>;
      },
      width: '10%',
      ellipsis: true,
      sorter: {
        compare: (a: any, b: any) => a.identificator - b.identificator,
        multiple: 5,
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select style={{ width: 200, marginBottom: 20 }}>
                <Select.Option value={'status1'}>status1</Select.Option>
                <Select.Option value={'status2'}>status2</Select.Option>
                <Select.Option value={'status3'}>status3</Select.Option>
              </Select>
            </div>
            <Button style={{ width: 80, height: 40 }} className="orange">
              Reset
            </Button>
          </div>
        );
      },
      filteredValue: null,
    },
  ];
  const onRowClick = (record: any) => {
    navigate(`/client/dot/${record.id}`);
  };
  const { getAllDOTReposts } = API;

  const init = async () => {
    setLoading(true);
    const response = await getAllDOTReposts();
    setLoading(false);
    setDot(response);
  };
  useEffect(() => {
    if (!isModalOpen) {
      init();
    }
  }, [isModalOpen]);
  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_LIST) ? (
        <>
          <MainContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MainHeader titles={[{ text: 'DOT Reports' }]} />
              </div>
              <div
                style={{
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
                <CreateRecordModal
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                />
                <InputSearch
                  onChange={setSearchParam}
                  onClear={clearOrderFilters}
                  hasFilters={hasFiltersOrOrder}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={clearOrder}
                >
                  <div style={{ marginRight: 5 }}>
                    <img src={ResetSort} />
                  </div>
                  <div
                    className="ubuntu"
                    style={{ color: '#8A8996', fontSize: 12 }}
                  >
                    Reset sorting
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={clearFilter}
                >
                  <div style={{ marginRight: 5 }}>
                    <img src={ResetFilter} />
                  </div>
                  <div
                    className="ubuntu"
                    style={{ color: '#8A8996', fontSize: 12 }}
                  >
                    Reset filter
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }} className="logs-table">
              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={dot}
                pagination={{
                  ...tableParams.pagination,
                  position: ['bottomCenter'],
                }}
                onRow={(record) => ({
                  onClick: () => onRowClick(record),
                })}
                loading={{
                  spinning: loading,
                  indicator: <TableSpinner />,
                }}
                onChange={handleTableChange}
                className="table-custom"
                rowClassName="pointer-row"
              />
            </div>
          </MainContainer>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};

export default DotPage;
