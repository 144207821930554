const EngineIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="8"
      fill="none"
      viewBox="0 0 24 15"
    >
      <path
        fill={fill}
        d="M7.13.292a1.46 1.46 0 01.32-.219c.143-.069.882-.081 3.994-.069 2.98.013 3.845.032 3.965.094.084.044.21.169.283.281.078.113.156.3.174.42.03.156 0 .306-.09.519-.084.193-.192.33-.319.406-.174.094-.402.106-3.172.138v1.25l3.635.032.39.193c.217.107.673.426 1.022.72.348.287.805.7 1.02.906.223.22.463.532.56.72.09.187.192.518.228.744.036.25.054 1.575.042 3.501-.018 2.94-.024 3.115-.138 3.409a3.57 3.57 0 01-.36.65 2.62 2.62 0 01-.511.531c-.15.1-.409.244-.571.32-.288.118-.433.125-4.175.143-2.451.013-4.02-.006-4.266-.043a2.935 2.935 0 01-.714-.238c-.187-.1-.475-.35-.691-.588-.199-.225-.571-.675-1.268-1.607l-2.12-.044-.265-.188a1.417 1.417 0 01-.378-.437c-.108-.225-.12-.394-.15-3.408H1.771V9.91c0 1.35-.012 1.513-.12 1.757-.066.144-.192.3-.288.35-.09.05-.259.106-.373.131a.787.787 0 01-.474-.075.838.838 0 01-.39-.331C0 11.523 0 11.498 0 7.49c0-3.721.006-4.053.102-4.234.072-.138.204-.25.39-.338.21-.093.343-.118.5-.087.113.025.288.094.39.156a.836.836 0 01.282.37c.096.218.108.468.108 3.195h1.802l.012-1.264c.019-1.125.03-1.3.139-1.52.066-.137.24-.33.642-.624l6.116-.032v-1.25h-1.43c-1.291 0-1.447-.013-1.676-.126a.802.802 0 01-.342-.293c-.042-.1-.096-.25-.12-.344a1.17 1.17 0 01.024-.426c.036-.137.12-.312.192-.381zM20.804 5.3c.12-.105.306-.218.402-.25.097-.03.571-.05 1.052-.043.739.013.907.031 1.111.138.132.068.319.25.601.669l.03 8.242-.15.3a1.022 1.022 0 01-.433.463c-.276.162-.318.168-2.15.181l-.3-.206c-.187-.132-.35-.3-.421-.457-.114-.244-.12-.394-.139-4.252-.012-3.427 0-4.046.079-4.296a.929.929 0 01.318-.488z"
      ></path>
    </svg>
  );
};

export default EngineIcon;
