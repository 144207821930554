import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Dropdown, Row, Col, Select, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import {
  getCarriersListReq,
  getCarrierPasswordReq,
} from '../../../actions/carrier';
import {
  getDriverGroupListRootReq,
  getDriverGroupListReq,
} from '../../../actions/driver_group';
import { getParams } from '../../../routes/utils';
import { InputSearch } from '../../common/doubleinput/InputSearch';
import { getOrderFromTableParams } from '../../../hooks/utils';
import { InputPageTitle } from '../../common/doubleinput/InputPageTitle';
import { SetPassword } from './modals/CarrierSetPassword';

import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import { LogoCarrier } from '../../common/LogoCarrier';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { BurgerIcon } from '../../header/logo';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import TableSpinner from '../../spiner/TableSpinner';

export const DriverGroupList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleTableChange,
    onSuccess,
    tableParams,
    rowSelection,
    clearOrderFilters,
    setSearchParam,
    hasFiltersOrOrder,
    clearFilter,
    clearOrder,
    clearCustomFilter,
    setCustomFilter,
  } = useTableParams({});
  const driverGroups = useSelector(
    (state: any) => state.driverGroup.driverGroupList,
  );
  const carriers = useSelector((state: any) => state.carrier.carrierList);

  const count = useSelector((state: any) => state.driverGroup.count);
  const loading = useSelector((state: any) => state.driverGroup.loading);
  const [accautnModalOpen, setAccauntModalOpen] = useState(false);
  const [currentCarrier, setCurrentCarrier] = useState({
    id: '',
    name: '',
  });

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['driver_groups'],
        },
      }),
    );
  }, []);

  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Driver Group Name',
      key: 'name',
      dataIndex: 'name',
      sortOrder: getOrderFromTableParams('name', tableParams),
      sorter: {
        compare: (a: any, b: any) => a.name - b.name,
        multiple: 5,
      },
      render: (name, record, index) => {
        return <div className="ubuntu">{`${name}`}</div>;
      },
      width: '50%',
      ellipsis: true,
    },

    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      width: '50%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <div
            className="ubuntu"
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => {
              navigate(`/client/carriers/${record?.carrier?.id}`);
            }}
          >
            <LogoCarrier
              logo={record?.carrier?.logo}
              onClick={() => null}
              styles={{ width: 30, height: 30 }}
            />
            <div style={{ marginLeft: 20 }}>{`${record?.carrier?.name}`}</div>
          </div>
        );
      },
      filterDropdown: () => {
        return (
          <div style={{ padding: 10 }}>
            <div>
              <Select
                style={{ width: 200, marginBottom: 20 }}
                value={tableParams.filters?.carrier}
                onChange={(value) => {
                  clearCustomFilter('group');
                  setCustomFilter('carrier', value);
                }}
              >
                {carriers?.map((carrier: any) => {
                  return (
                    <Select.Option key={carrier.id} value={carrier.id}>
                      {carrier.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <Button
              style={{ width: 80, height: 40 }}
              className="orange"
              onClick={() => {
                clearCustomFilter('carrier');
                clearCustomFilter('group');
              }}
            >
              Reset
            </Button>
          </div>
        );
      },

      filteredValue: tableParams?.filters?.carrier || null,
    },

    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   width: '5%',
    //   ellipsis: true,
    //   render: (value, record, index) => {
    //     return (
    //       <Dropdown
    //         placement="bottomLeft"
    //         trigger={['click']}
    //         className="menu-option"
    //         menu={{
    //           items: [
    //             {
    //               key: '1',
    //               label: (
    //                 <div
    //                   onClick={() => {
    //                     checkPermission(AllPermissionsType.DRIVER_GROUP_EDIT)
    //                       ? navigate(
    //                           `${location.pathname}/${record.id}?state=EDIT`,
    //                         )
    //                       : navigate(
    //                           `${location.pathname}/${record.id}?state=VIEW`,
    //                         );
    //                   }}
    //                   style={{ display: 'flex', alignItems: 'center' }}
    //                 >
    //                   <span
    //                     className="icon-fi-rr-pencil"
    //                     style={{ marginRight: 5 }}
    //                   ></span>
    //                   Edit
    //                   <div style={{ marginLeft: 10, marginRight: 10 }}>|</div>
    //                   <span
    //                     className="icon-fi-rr-eye"
    //                     style={{ marginRight: 5 }}
    //                   ></span>
    //                   View
    //                 </div>
    //               ),
    //             },
    //             {
    //               key: '3',
    //               label: (
    //                 <div
    //                   onClick={() => {}}
    //                   style={{ display: 'flex', alignItems: 'center' }}
    //                 >
    //                   <span
    //                     className="icon-fi-rr-trash"
    //                     style={{ marginRight: '10px' }}
    //                   ></span>{' '}
    //                   Delete
    //                 </div>
    //               ),
    //             },
    //           ],
    //         }}
    //       >
    //         <span>
    //           <BurgerIcon />
    //         </span>
    //       </Dropdown>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    dispatch(
      getDriverGroupListReq({
        queryParams: {
          ...getParams(tableParams),
          with: ['carrier'],
        },
      }),
    );
  }, [tableParams]);

  const { checkPermission } = usePermissions();
  const onRowClick = (record: any) => {
    navigate(`${location.pathname}/${record.id}`);
  };
  return (
    <>
      {checkPermission(AllPermissionsType.DRIVER_GROUP_LIST) ? (
        <>
          <MainContainer>
            <Row>
              <SetPassword
                currentItem={currentCarrier}
                isOpen={accautnModalOpen}
                toggleModal={(status: any) => setAccauntModalOpen(status)}
                onSubmit={(payload: any) => {
                  dispatch(
                    getCarrierPasswordReq({
                      data: payload,
                      onSuccess: () => {
                        dispatch(
                          getCarriersListReq({
                            queryParams: getParams(tableParams),
                          }),
                        );
                        setAccauntModalOpen(false);
                      },
                    }),
                  );
                }}
              />
              <Col span={12}>
                <MainHeader titles={[{ text: 'Driver Group' }]} />
              </Col>
              <Col
                span={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <InputSearch
                  onChange={setSearchParam}
                  onClear={clearOrderFilters}
                  hasFilters={hasFiltersOrOrder}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="icon-fi-rr-plus ubuntu orange" />
                  <div
                    className="orange ubuntu"
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      marginLeft: 8,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      navigate(`${location.pathname}/create`);
                    }}
                  >
                    Create Driver Group
                  </div>
                </div>
                <div style={{ marginLeft: 25, display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={clearOrder}
                  >
                    <div style={{ marginRight: 5 }}>
                      <img src={ResetSort} />
                    </div>
                    <div
                      className="ubuntu"
                      style={{ color: '#8A8996', fontSize: 12 }}
                    >
                      Reset sorting
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      marginLeft: 25,
                    }}
                    onClick={clearFilter}
                  >
                    <div style={{ marginRight: 5 }}>
                      <img src={ResetFilter} />
                    </div>
                    <div
                      className="ubuntu"
                      style={{ color: '#8A8996', fontSize: 12 }}
                    >
                      Reset filter
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={driverGroups?.map((carrier: any, index: any) => {
                return {
                  ...carrier,
                };
              })}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
              rowClassName="pointer-row"
              pagination={{
                ...tableParams.pagination,
                position: ['bottomCenter'],
                total: count,
              }}
              loading={{
                spinning: loading,
                indicator: <TableSpinner />,
              }}
              onChange={handleTableChange}
              rowSelection={{ ...rowSelection, columnWidth: 10 }}
              className="table-custom"
              //   sticky
              //   scroll={{ y: window.innerHeight - 235 }}
            />
          </MainContainer>
        </>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
