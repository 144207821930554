import styles from './Chip.module.css';

export interface IChip {
  children: string;
  color: string;
}

const Chip = ({ children, color }: IChip) => {
  return (
    <div className={styles.chip} style={{ background: color }}>
      {children}
    </div>
  );
};

export default Chip;
