import { InputPageTitle } from '../../common/doubleinput/InputPageTitle';
import { InputSearch } from '../../common/doubleinput/InputSearch';
import styles from './UserActivity.module.css';
import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import { DatePicker, Table, TimeRangePickerProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
const UserActivity = () => {
  const { tableParams } = useTableParams({});
  const { RangePicker } = DatePicker;
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Period',
      key: 'period',
      dataIndex: 'period',
      width: '11%',
      render: (name, record, index) => {
        return <div>00/00/0000 00:00</div>;
      },
      ellipsis: true,
    },
    {
      title: 'User',
      key: 'user',
      dataIndex: 'user',
      width: '15%',
      render: (name, record, index) => {
        return <div>user</div>;
      },
      ellipsis: true,
      filters: [
        { text: 'user1', value: 'user1' },
        { text: 'user2', value: 'user2' },
      ],
      filteredValue: tableParams?.filters?.user || null,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      width: '12%',
      render: (name, record, index) => {
        return <div>type</div>;
      },
      ellipsis: true,
      filters: [
        { text: 'type1', value: 'type1' },
        { text: 'type2', value: 'type2' },
      ],
      filteredValue: tableParams?.filters?.type || null,
    },
    {
      title: 'Action_entity',
      key: 'actionEntity',
      dataIndex: 'actionEntity',
      width: '12%',
      render: (name, record, index) => {
        return <div>ActionEntity</div>;
      },
      ellipsis: true,
      filters: [
        { text: 'actionEntity1', value: 'actionEntity1' },
        { text: 'actionEntity2', value: 'actionEntity2' },
      ],
      filteredValue: tableParams?.filters?.actionEntity || null,
    },
    {
      title: 'Action_id',
      key: 'actionId',
      dataIndex: 'actionId',
      width: '12%',
      render: (name, record, index) => {
        return <div>actionId</div>;
      },
      ellipsis: true,
    },
    {
      title: 'Message',
      key: 'message',
      dataIndex: 'message',
      render: (name, record, index) => {
        return <div>message</div>;
      },
      ellipsis: true,
    },
  ];
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last 8 days',
      value: [dayjs().subtract(8, 'd'), dayjs()],
    },
    {
      label: 'Last month ',
      value: [dayjs().subtract(1, 'M'), dayjs()],
    },
  ];
  return (
    <MainContainer>
      <div className={styles.container}>
        <MainHeader titles={[{ text: 'Users Activity' }]} />
        <div className={styles.body}>
          <div className={styles.header}>
            <div className={styles.rangeFiled}>
              <p className="input-item-title">Select period</p>
              <RangePicker
                presets={rangePresets}
                className={styles.rangePicker}
              />
            </div>
            <div className={styles.filters}>
              <InputSearch
              // onChange={setSearchParam}
              // onClear={clearOrderFilters}
              // hasFilters={hasFiltersOrOrder}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                // onClick={clearOrder}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={ResetSort} />
                </div>
                <div
                  className="ubuntu"
                  style={{ color: '#8A8996', fontSize: 12 }}
                >
                  Reset sorting
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                // onClick={clearFilter}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={ResetFilter} />
                </div>
                <div
                  className="ubuntu"
                  style={{ color: '#8A8996', fontSize: 12 }}
                >
                  Reset filter
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={[{ id: '0' }, { id: '1' }]}
            pagination={{
              ...tableParams.pagination,
              position: ['bottomCenter'],
            }}
            //   onRow={(record) => ({
            //     onClick: () => onRowClick(record),
            //   })}
            className="table-custom"
            rowClassName="pointer-row"
          />
        </div>
      </div>
    </MainContainer>
  );
};

export default UserActivity;
