import { Button, Modal, Table } from 'antd';
import styles from './RecordPage.module.css';
import { ColumnsType } from 'antd/es/table';

const RecordDotErrorsModal = ({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const openModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      render: (name, record, index) => {
        return <div>{index}</div>;
      },
      width: '5%',
      ellipsis: true,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (name, record, index) => {
        return <div>Error</div>;
      },
      width: '10%',
      ellipsis: true,
    },
    {
      title: 'Message',
      key: 'message',
      dataIndex: 'message',
      render: (name, record, index) => {
        return <div>Invalid VIN</div>;
      },
      width: '12%',
      ellipsis: true,
    },
    {
      title: 'Details',
      key: 'details',
      dataIndex: 'details',
      render: (name, record, index) => {
        return <div>Details of the notification</div>;
      },
      width: '50%',
      ellipsis: true,
    },
  ];
  return (
    <Modal
      title="Errors"
      open={isModalOpen}
      onOk={openModal}
      onCancel={openModal}
      footer={null}
      destroyOnClose
      className={styles.modal}
    >
      <Table
        columns={columns}
        style={{ marginTop: 25 }}
        rowKey={(record) => record.id}
        dataSource={[{ id: '0' }, { id: '1' }]}
        pagination={false}
        bordered
        className="table-custom"
        // rowClassName="pointer-row"
      />
      <div className={styles.modalFooter}>
        <div className="ubuntu">ID: 255554</div>
        <Button
          onClick={openModal}
          type="primary"
          htmlType="button"
          className="grey"
          style={{ width: '65px' }}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default RecordDotErrorsModal;
