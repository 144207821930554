import { all, fork } from 'redux-saga/effects';

import shop from './shop';

import user from './user';
import auth from './auth';
import company from './company';
import office from './office';
import role from './role';
import vehicle from './vehicle';
import trailer from './trailer';
import eld from './eld';
import driver from './driver';
import carrier from './carrier';
import driver_group from './driver_group';
import mechanic from './mechanic';
import device from './device';
import alerts from './alerts';
import unit from './unit';
import log from './log';
import driverLog from './driver_log';
import transaction from './transaction';
import config from './config';
import undefinedLog from './undefined';
/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(user),
    fork(auth),
    fork(company),
    fork(office),
    fork(role),
    fork(vehicle),
    fork(trailer),
    fork(eld),
    fork(driver),
    fork(carrier),
    fork(driver_group),
    fork(mechanic),
    fork(device),
    fork(alerts),
    fork(unit),
    fork(log),
    fork(driverLog),
    fork(transaction),
    fork(config),
    fork(shop),
    fork(undefinedLog),
  ]);
}
