import { useEffect, useRef, useState } from 'react';
import styles from './Chat.module.css';
import ChatInput from './ChatInput';
import { IDriver, IMessage } from './Chat';
import { motion } from 'framer-motion';
import MessageFileIcon from '../../img/MessageFileIcon.svg';
import MessageSendIcon from '../../img/messageSendIcon.svg';
import chatCloseIcon from '../../img/chatCloseIcon.svg';
import { API } from '../../api/API';
import { notification } from 'antd';
import UserMessage from './UserMessage';

interface IMessagesProps {
  allMessages: any[];
  setAllMessages: (value: string, link: string, media: string) => void;
  driver: IDriver | undefined;
  userId: string;
  divRef: React.RefObject<HTMLDivElement>;
  closeClick: () => void;
  username?: string;
  readMessage: (driverId: string, messageId: string) => void;
}
const Messages = ({
  allMessages,
  setAllMessages,
  driver,
  userId,
  divRef,
  closeClick,
  readMessage,
}: IMessagesProps) => {
  const [message, setMessage] = useState('');
  const onChange = (value: string) => {
    setMessage(value);
  };
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messageId, setMessageId] = useState('');
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (message.length === 0) return;
    if (driver && divRef.current) {
      setAllMessages(message, driver.id, '');
      setMessage('');
    }
  };
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [driver]);
  const username = driver
    ? `${driver.first_name} ${driver.last_name}(${driver.username})`
    : '';

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const getMediaType = (media: string): string => {
    return media.split('.').pop() as string;
  };

  const fileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && driver && fileInputRef.current) {
      const files = Array.from(e.target.files);
      const maxSizeInMB = 10;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      const validFiles = files.filter((file) => file.size <= maxSizeInBytes);
      if (validFiles.length !== files.length) {
        notification.error({
          message: `Some files exceed the size limit of ${maxSizeInMB} MB and will not be uploaded.`,
          type: 'error',
        });
        fileInputRef.current.value = '';
      } else {
        const data = await API.uploadFileReq(e.target.files);
        setAllMessages('', driver.id, data[0].file);
        fileInputRef.current.value = '';
      }
    }
  };
  useEffect(() => {
    if (driver) {
      const messages = allMessages.filter(
        (message) => message.id === driver.id,
      );
      if (messages[0]) {
        const { id, ...restMessages } = messages[0];
        const messagesArray: IMessage[] = Object.entries(restMessages).map(
          ([key, value]) => {
            return {
              id: key,
              ...(value as Omit<IMessage, 'id'>),
            };
          },
        );
        setMessages(messagesArray);
      } else {
        setMessages([]);
      }
    }
  }, [allMessages, driver]);
  return (
    <div className={styles.messages}>
      <div className={styles.messagesHeader}>
        <div className={styles.messagesHeaderIcon}>
          {driver && (
            <div
              style={{ backgroundColor: '#EDEEF0' }}
              className={styles.usersIcon}
            >
              {driver.first_name.slice(0, 1).toUpperCase()}
              {driver.last_name.slice(0, 1).toUpperCase()}
            </div>
          )}

          <p className={styles.username}>{username}</p>
        </div>
        <div className={styles.menu}>
          <motion.div
            whileTap={{
              scale: 0.9,
              opacity: 0.5,
            }}
            style={{ cursor: 'pointer' }}
            onClick={closeClick}
          >
            <img src={chatCloseIcon} />
          </motion.div>
        </div>
      </div>
      <div className={styles.messagesBody}>
        <div ref={divRef} className={styles.messagesBodyContainer}>
          {messages.map((message, index) => {
            const mediaType = getMediaType(message.media ? message.media : '');
            return (
              <UserMessage
                lastTenMessages={messages.length != 0 ? messages : []}
                key={index}
                readMessage={readMessage}
                message={message}
                driver={driver ? driver : undefined}
                username={username}
                mediaType={mediaType}
                userId={userId}
              />
            );
          })}
        </div>
      </div>
      <motion.form
        animate={{
          scale: !!driver ? 1 : 0.8,
          y: !!driver ? 0 : 20,
          opacity: !!driver ? 1 : 0,
        }}
        onSubmit={onSubmit}
        className={styles.messagesBodyInputs}
      >
        <input
          ref={fileInputRef}
          type="file"
          id="file-input"
          style={{ display: 'none' }}
          onChange={fileChange}
          accept="image/jpeg, image/png, application/pdf"
        />
        <img
          onClick={fileUploadClick}
          src={MessageFileIcon}
          alt="file"
          style={{ cursor: 'pointer' }}
        />
        <ChatInput
          value={message}
          onChange={onChange}
          placeholder="Enter message..."
        />
        <button className={styles.sendButton}>
          <img src={MessageSendIcon} alt="send" />
        </button>
      </motion.form>
    </div>
  );
};

export default Messages;
