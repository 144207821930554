export const PAGE_STATUS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};

export const DriverField = {
  CARRIER: 'carrier',
  NAME: 'name',
};

export const DriverGroupField = {
  CARRIER: 'carrier',
  NAME: 'name',
};
