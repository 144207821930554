import React from 'react';

function LogInIcon({ fill }: { fill: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fill} clipPath="url(#clip0_1_6)">
        <path d="M12.524 9a1 1 0 001-1V5a3 3 0 013-3H19a3 3 0 013 3v14a3 3 0 01-3 3h-2.476a3 3 0 01-3-3v-3a1 1 0 00-2 0v3a5.006 5.006 0 005 5H19a5.006 5.006 0 005-5V5a5.006 5.006 0 00-5-5h-2.476a5.006 5.006 0 00-5 5v3a1 1 0 001 1z"></path>
        <path d="M1.133 14.12l4.586 4.587a1 1 0 101.414-1.414L2.87 13.03 18 13a1 1 0 000-2l-15.188.03 4.323-4.323A1 1 0 105.72 5.293L1.135 9.879a3 3 0 00-.002 4.242z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_6">
          <path
            fill={fill}
            d="M0 0H24V24H0z"
            transform="rotate(180 12 12)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogInIcon;
