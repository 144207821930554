import styles from './dasboard.module.css';
import positive from '../../../img/positive.svg';
import negative from '../../../img/negative.svg';

interface IDahboardBars {
  bars: IDahboardBarsItem[];
}
export interface IDahboardBarsItem {
  pieColors: string[];
  title: string;
  firstViolation: string;
  secondViolation: string;
  type: string;
  barData: IDahboardBarsItemBarData[];
}
export interface IDahboardBarsItemBarData {
  title: string;
  width: number;
  firstNumber: number;
  secondNumber: number;
}

const DahboardBars = ({ bars }: IDahboardBars) => {
  return (
    <div className={styles.bars}>
      {bars.map((value, index) => (
        <div className={styles.bar} key={index}>
          <div className={styles.barHeader}>
            <p className={styles.barHeaderTitle}>{value.title}</p>
            {value.type === 'viol' ? (
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <div className={styles.barHeaderSubTitle}>
                  <div
                    style={{ background: value.pieColors[0] }}
                    className={styles.barHeaderSubTitleColor}
                  />
                  <p className={styles.barHeaderSubTitleText}>
                    {'>'} {value.firstViolation}m Violation
                  </p>
                </div>
                <div className={styles.barHeaderSubTitle}>
                  <div
                    style={{ background: value.pieColors[1] }}
                    className={styles.barHeaderSubTitleColor}
                  />
                  <p className={styles.barHeaderSubTitleText}>
                    {'<'} {value.secondViolation}m Violation
                  </p>
                </div>
              </div>
            ) : (
              <p className={styles.barHeaderSubTitleText}>Error Count</p>
            )}
          </div>
          <div className={styles.barBody}>
            {value.barData.map((bar) => (
              <div className={styles.barBodyBar}>
                <p className={styles.barBodyBarText}>{bar.title}</p>
                <div
                  style={{ background: value.pieColors[1] }}
                  className={styles.customBar}
                >
                  <div
                    style={{ width: `${bar.width}%` }}
                    className={styles.customBarFirst}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    gap: 5,
                  }}
                >
                  <p className={`ubuntu ${styles.headerItemsBodyNumber} `}>
                    {bar.firstNumber}%
                  </p>

                  <p
                    className={`ubuntu ${bar.secondNumber >= 0 ? styles.headerItemsBodyProcentPsitive : styles.headerItemsBodyProcentNegative}`}
                  >
                    {bar.secondNumber >= 0 ? (
                      <img src={positive} alt="" />
                    ) : (
                      <img src={negative} alt="" />
                    )}
                    {bar.secondNumber}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DahboardBars;
