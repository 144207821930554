export const CONTENT_MAIN_PAGE = {
  SECTION_HEADER: "SECTION_HEADER",
  SECTION_BENEFITS: "SECTION_BENEFITS",
  SECTION_FEAT_DRIVER_APP: "SECTION_FEAT_DRIVER_APP",
  SECTION_DOWNLOAD: "SECTION_DOWNLOAD",
  SECTION_FEATURE: "SECTION_FEATURE",
  SECTION_FAQ: "SECTION_FAQ",
  SECTION_SHOP: "SECTION_SHOP",
  SECTION_BUDNLE: "SECTION_BUDNLE",
  SECTION_DOWNLOAD_2: "SECTION_DOWNLOAD_2",
  SECTION_FOOTER: "SECTION_FOOTER",
};
