export const CarrierStatus = {
  ACTIVE: 'ACTIVE',
};

export const carrierStatusOptions = [
  {
    value: CarrierStatus.ACTIVE,
    key: 'Active',
  },
];

export const COUNTRY = {
  USA: 'USA',
  CANADA: 'CANADA',
};

export const PAGE_STATUS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};

export const DeviceField = {
  identificator: 'identificator',
  TYPE: 'type',
  MAC_ADDRESS: 'mac_address',
  MODEL: 'model',
  FUEL_TYPE: 'fuel_type',
  CARRIER: 'carrier',
  STATUS: 'status',
  SERIAL_NUMBER: 'serial_number',
  FIRMWARE: 'firmware',
  NOTES: 'notes',
  NAME: 'name',
};

export const CompanyField = {
  ADDRESS: 'address',
  NOTES: 'notes',
  NAME: 'name',
  EMAIL: 'email',
  PERSON: 'person',
  WEBSITE: 'website',
  LOGO: 'logo',
  BACK_GROUND: 'background',
  FAVICON: 'favicon',
  OFFICES: 'offices',
  USDOT: 'usdot',
  PHONE: 'phone',
  STATUS: 'status',
  BILLING_PLAN: 'billing_plan',
  MC_NUMBER: 'mcnumber',
  COMPANY: 'company_id',
};

export const companyStatys = [
  {
    key: 1,
    value: 'Active',
  },
  {
    key: 0,
    value: 'Inactive',
  },
  {
    key: 2,
    value: 'Temporary',
  },
];
