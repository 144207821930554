export interface IConfigReq {
  status: string;
  data: IConfigReqData;
}

export interface IConfigReqData {
  status: IConfigReqDataStatus[];
  countries: IConfigReqDataCountry[];
  states: IConfigReqDataState[];
}

export interface IConfigReqDataStatus {
  key: number;
  value: string;
}

export interface IConfigReqDataCountry {
  key: number;
  value: string;
}

export interface IConfigReqDataState {
  key: number;
  code: string;
  value: string;
}

import { createReducer } from '@reduxjs/toolkit';
import { getConfigSucces } from '../actions';

export const configsState: IConfigReq = {
  status: '',
  data: {
    status: [],
    countries: [],
    states: [],
  },
};

export default {
  config: createReducer<IConfigReq>(configsState, (builder) => {
    builder.addCase(getConfigSucces, (state, { payload }) => {
      return {
        ...state,
        data: payload.data,
        status: payload.status,
      };
    });
  }),
};
