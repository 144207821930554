import { InputType } from '../../../constants/inputs';

import {
  UnitColor,
  UnitDynamicFields,
  UnitNotice,
} from './fields/GroupStatusDriverFields';

// Lincoln Oasis Mobil  period

export const carrierForm = (options: any) => {
  return [
    {
      type: InputType.MAIN_HEADER,
      titles: [
        {
          text: 'Units',
        },
        {
          text: 'Create',
        },
      ],
      link: '/client/units',
      back: true,
    },

    {
      type: InputType.TITLE,
      label: 'Unit Basics',
    },

    { ...UnitDynamicFields },
    // { ...UnitColor },
    // { ...UnitNotice },
  ];
};
