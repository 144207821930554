import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, theme, Table, Row, Col, Dropdown } from 'antd';
import { getDocumentNameByType } from '../constant';
import type { ColumnsType } from 'antd/es/table';
import { ENV } from '../../../../utils/constants';
import { deleteDriverDocumentReq } from '../../../../actions';
import TableSpinner from '../../../spiner/TableSpinner';

const { Panel } = Collapse;

export const DOTDocumentsList = (props: any) => {
  const { title = 'Saved Documents', reports } = props;
  const { token } = theme.useToken();
  const documents: any = reports || [];

  const loading = useSelector((state: any) => state.driver.documentsLoading);

  const panelStyle = {
    marginBottom: 24,
    background: 'rgba(239, 246, 255, 0.4)',
    borderRadius: token.borderRadiusLG,
    border: 'none',
    fontFamily: 'Ubuntu',
    color: 'rgba(20, 16, 41, 0.4) !important',
  };

  const columns: ColumnsType<any> = [
    {
      title: 'File',
      key: 'file',
      dataIndex: 'file',
      render: (name, record, index) => {
        const fileName = record.split('/storage/app/public/report/fmcsa/');
        return <div className="orange ubuntu pointer">{fileName}</div>;
      },
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Document Type',
      key: 'type',
      dataIndex: 'type',
      render: (name, record, index) => {
        const parts = record.split('.');
        const type: string = parts.length > 1 ? parts.pop() : '';

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{type.toLocaleUpperCase()}</div>
          </div>
        );
      },
      width: '9%',
      ellipsis: true,
    },
  ];

  const onRowClick = (record: string) => {
    window.open(`${ENV}${record}`, '_blank', 'noopener,noreferrer');
  };
  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          style={{ background: token.colorBgContainer }}
        >
          <Panel header={`View ${title}`} key="1" style={panelStyle}>
            <Row>
              <Col span={24}>
                <Table
                  loading={{
                    spinning: loading,
                    indicator: <TableSpinner />,
                  }}
                  style={{ width: '100%' }}
                  columns={columns}
                  rowKey={(record) => record.id}
                  onRow={(record) => ({
                    onClick: () => onRowClick(record),
                  })}
                  dataSource={documents}
                  rowClassName="pointer-row"
                  className="table-custom"
                  pagination={false}
                />
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
