import { InputType } from '../../../constants/inputs';
import {
  VALIDATION_RULES,
  validate,
  VALIDATION_TYPE,
  getValidation,
} from '../../../utils/validation';
import {
  carrierStatusOptions,
  CarrierField,
  carrierCheckboxGroup,
  DriverField,
} from './constant';
import { NameDriverFields } from './fields/NameDriverFields';
import {
  GroupsStatusFields,
  CarrierFieldInput,
} from './fields/GroupStatusDriverFields';
import { LoginCarrierFields } from './fields/LoginCarrierFields';
import { PreferencesCarrierFields } from './fields/PreferencesCarrierFields';
import { TerminalCarrierFields } from './fields/TerminalCarrierFields';
import {
  HosRulesCarrierFields,
  HosCarrierNotice,
} from './fields/HosRulesCarrierFields';
import { ContactsDriverFields } from './fields/ContactsDriverField';
import { DriverLicense, LicenseDriverFields } from './fields/DriverLicense';
//MedicalCardDriverFields
import { MedicalCardDriverFields } from './fields/DriverMC';

const { ALPHABETICAL, REQUIRED, MIN, MAX, NUMERIC, PASSWORD, EMAIL } =
  VALIDATION_TYPE;

export const carrierForm = ({ visible, onClick, create }: any) => {
  const contacts = visible && ContactsDriverFields;
  const title = create
    ? { type: InputType.TITLEBUTTON, label: 'Contacts' }
    : {
        type: InputType.TITLEBUTTON,
        label: 'Contacts',
        onClick: onClick,
        buttonText: 'Get Contacts',
      };
  const body = create ? { ...ContactsDriverFields } : { ...contacts };
  const preferences = PreferencesCarrierFields({ create });

  return [
    {
      type: InputType.MAIN_HEADER,
      titles: [{ text: 'Drivers' }, { text: `${create ? 'Create' : 'Edit'}` }],
      back: true,
      link: '/client/drivers',
    },

    {
      type: InputType.TITLE,
      label: 'Driver Basics',
    },
    { ...NameDriverFields },

    { ...LicenseDriverFields },
    { ...MedicalCardDriverFields },
    { ...CarrierFieldInput },

    {
      type: InputType.TITLE,
      label: 'Preferences',
    },
    { ...preferences },
    { ...HosRulesCarrierFields },
    { ...HosCarrierNotice },
    {
      type: InputType.TITLE,
      label: 'Adress & Timezone',
    },
    { ...TerminalCarrierFields },
    title,
    body,
  ];
};
