function UndifinedIcon() {
  return (
    <svg
      style={{
        marginRight: 10,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g className="ant-menu-item-icon" clipPath="url(#clip0_25_21467)">
        <path d="M13.638 3.037L8.324.084a.667.667 0 00-.648 0L2.362 3.037a2 2 0 00-1.029 1.748v7.882A3.337 3.337 0 004.667 16h6.666a3.337 3.337 0 003.334-3.333V4.785a2.001 2.001 0 00-1.029-1.748zm-.305 9.63a2 2 0 01-2 2H4.667a2 2 0 01-2-2V4.785A.667.667 0 013.01 4.2L8 1.43l4.99 2.77a.666.666 0 01.343.585v7.882z"></path>
        <path d="M8 5.667a1 1 0 100-2 1 1 0 000 2z"></path>
      </g>
      <defs>
        <clipPath id="clip0_25_21467">
          <path fill="#8b8a97" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UndifinedIcon;
