import { createReducer } from '@reduxjs/toolkit';
import { getUnLogReqSucces } from '../actions/undifiend';

export const undefinedState = {
  undefinedList: [],
};

export default {
  undefined: createReducer<any>(undefinedState, (builder) => {
    builder.addCase(getUnLogReqSucces, (state, { payload }) => {
      return {
        undefinedList: [...payload.data],
        count: payload.params.items.count,
      };
    });
  }),
};
