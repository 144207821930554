import React, { useState } from 'react';
import { Form, Input, Col, Row, Button } from 'antd';
import { CommonInputV2 } from './index';

export const InputTitleButton = (props: any) => {
  const { label, onClick, buttonText } = props;
  return (
    <div className="input-title-with-button">
      <div className="input-title-item ubuntu">{label}</div>
      <div
        onClick={onClick}
        style={{ cursor: 'pointer' }}
        className="orange ubuntu"
      >
        {buttonText}
      </div>
    </div>
  );
};
