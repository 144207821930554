import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { getCarriersListReq } from '../../../actions/carrier';
import {
  updateVehicleReq,
  getVehicleReq,
  setCurrentVehicleCarrier,
} from '../../../actions/vehicle';
import { CARRIER_SELECT_DISABLED } from '../../common/doubleinput/utils';

import { Row, Col, Form, Button, Input, Spin } from 'antd';
import { CommonInput } from '../../common/inputs';
import { carrierForm } from './vehicle-form';
import { InputType } from '../../../constants/inputs';
import { PAGE_STATUS, getDocumentByType } from './constant';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import dayjs from 'dayjs';
import { cleanObject } from '../../../utils/cleanObject';
import MainContainer from '../../layout/MainContainer';
import Spiner from '../../spiner/Spiner';

function buildFormData(formData: any, data: any, parentKey?: any) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export const VehiclePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const [search, setSearch] = useSearchParams();
  const [state, setStateValue] = React.useState(search.get('state'));
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    identificator: '',
    vin: '',
    make: null,
    model: '',
    fuel_type: null,
    carrier: null,
    status: null,
    notes: '',
    license_plate: null,
    license_issuing: '',
    license_expiration: '',
    year: '',
  });
  const { loading, vehicle, currentCarrier } = useSelector(
    (state: any) => state.vehicle,
  );
  const { loading: carrierLoading, carrierList } = useSelector(
    (state: any) => state.carrier,
  );

  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (vehicle?.carrier?.id) {
      const foundCarrier = carrierList.find(
        (carrier: any) => carrier.id === vehicle.carrier.id,
      );

      dispatch(
        setCurrentVehicleCarrier({
          ...foundCarrier,
          defaultSavedCarrier: true,
        }),
      );
    }
  }, [carrierList, vehicle]);

  React.useEffect(() => {
    setStateValue(search.get('state'));
  }, [search]);

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['settings', 'terminals', 'driver_groups', 'documents'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...(!currentCarrier.defaultSavedCarrier ? currentCarrier?.settings : {}),
    });
  }, [currentCarrier]);

  useEffect(() => {
    dispatch(
      getVehicleReq({
        vehicleId: params.vehicleId,
        queryParams: {
          with: ['terminal', 'group', 'carrier', 'documents'],
        },
      }),
    );
  }, []);

  const handleSubmit = async (values: any) => {
    const data = cleanObject({
      ...values,
      license_expiration: values.license_expiration,
    });
    dispatch(
      updateVehicleReq({
        values: data,
        vehicleId: params.vehicleId,
      }),
    );
  };

  React.useEffect(() => {
    setInitialValues({
      ...initialValues,
      ...vehicle,
      carrier: vehicle?.carrier?.id,
      license_plate: vehicle?.license_plate,
      license_expiration: vehicle?.license_expiration,
    });
    form.setFieldsValue({
      ...initialValues,
      ...vehicle,
      carrier: vehicle?.carrier?.id,
      license_plate: vehicle?.license_plate,
      license_expiration: vehicle?.license_expiration,
    });
    form.setFieldValue(
      'license_expiration',
      dayjs(vehicle?.license_expiration),
    );
  }, [vehicle]);

  const { checkPermission } = usePermissions();

  return (
    <>
      {checkPermission(AllPermissionsType.VEHICLE_SHOW) ? (
        <MainContainer half>
          <Row style={{ height: '100%' }}>
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spiner />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                >
                  {carrierForm({}).map((fieldCurrent: any, i: number) => {
                    const field = {
                      ...fieldCurrent,
                      disabled:
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.VEHICLE_EDIT),
                      isReadonlyCarrier: true,
                      isIdentificatorDisabled: true,
                    };

                    if (CARRIER_SELECT_DISABLED.includes(field.type)) {
                      return (
                        <CommonInput
                          currentIndex={currentIndex}
                          fields={fields}
                          key={i}
                          setCurrentIndex={setCurrentIndex}
                          {...field}
                          form={form}
                          isReadonlyCarrier={true}
                        />
                      );
                    }

                    if (field.type === InputType.ADD_DYNAMIC) {
                      return (
                        <CommonInput
                          currentIndex={currentIndex}
                          fields={fields}
                          key={i}
                          setCurrentIndex={setCurrentIndex}
                          {...field}
                          form={form}
                        />
                      );
                    }
                    return (
                      <CommonInput
                        key={i}
                        {...field}
                        form={form}
                        showDocsList={true}
                      />
                    );
                  })}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '65px', marginRight: 12 }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.VEHICLE_EDIT)
                      }
                    >
                      Save
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.VEHICLE_EDIT)
                      }
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
