import { all, put, takeLatest, call } from 'redux-saga/effects';
import { notification } from 'antd';
import request from '../utils/requestMonitoring';

import { getLogListFailed } from '../actions';
import { TransactionTypes, getTransactionSucces } from '../actions/transaction';

notification.config({
  placement: 'topRight',
  bottom: 50,
  duration: 5,
  // rtl: true,
});

export function* getLogTransactions({ payload }: any): any {
  try {
    const { data } = yield call(request.get, `/transaction`, {
      params: payload.queryParams,
    });
    yield put(getTransactionSucces(data.data));
  } catch (e: any) {
    yield put(getLogListFailed(e.message));
    yield call(notification.error, {
      message: 'Something went wrong, try again later',
    });
  }
}

export function* applyDriver({ payload }: any): any {
  try {
    yield call(request.post, `/transaction/driver/${payload.id}/apply`);
    yield call(notification.success, {
      message: 'Apply updated successfully',
    });
  } catch (e: any) {
    yield put(getLogListFailed(e.message));
    yield call(notification.error, {
      message: 'Something went wrong, try again later',
    });
  }
}
export function* forceAprove({ payload }: any): any {
  try {
    yield call(request.post, `/transaction/${payload.id}/approve`);
    yield call(notification.success, {
      message: 'Aprove updated successfully',
    });
  } catch (e: any) {
    yield put(getLogListFailed(e.message));
    yield call(notification.error, {
      message: 'Something went wrong, try again later',
    });
  }
}

export function* rollback({ payload }: any): any {
  try {
    yield call(request.post, `/transaction/${payload.id}/rollback`);
    yield call(notification.success, {
      message: 'Rollback updated successfully',
    });
  } catch (e: any) {
    yield put(getLogListFailed(e.message));
    yield call(notification.error, {
      message: 'Something went wrong, try again later',
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(TransactionTypes.GET_ALL_TRANSACTION, getLogTransactions),
    takeLatest(TransactionTypes.APPLY_BY_DRIVER, applyDriver),
    takeLatest(TransactionTypes.FORCE_APPROVE, forceAprove),
    takeLatest(TransactionTypes.ROLLBACK, rollback),
  ]);
}
