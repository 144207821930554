import { Row, Spin, Tabs } from 'antd';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUnitReq } from '../../../actions';
import { useParams } from 'react-router-dom';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import styles from './Unit.module.css';
import { OpenLayerMap } from '../../common/google-map/olMap';
import { Tab } from 'rc-tabs/lib/interface';
import UnitInfo from './UnitInfo';
import UnitRoute from './UnitRoute';
import UnitHistoryTab from './UnitHistoryTab';
import { socket } from '../../../socket';
import Spiner from '../../spiner/Spiner';
export const UnitPage = () => {
  const { checkPermission } = usePermissions();
  const dispatch = useDispatch();
  const unit = useSelector((state: any) => state.units.unit);
  const [driverName, setDriverName] = useState('');
  const params = useParams();
  useEffect(() => {
    dispatch(
      getUnitReq({
        unitId: params.unitId,
        with: ['driver'],
      }),
    );
  }, []);
  useEffect(() => {
    if (unit.driver) {
      setDriverName(`${unit.driver.first_name + ' ' + unit.driver.last_name}`);
    }
  }, [unit]);

  // useEffect(() => {
  //   socket.on('message', (data: any) => {
  //     console.log('message', data.message);
  //   });
  // }, [socket]);
  const titles = [
    {
      text: `Units`,
    },
    {
      text: driverName,
    },
  ];

  const items: Tab[] = [
    {
      label: 'Profile',
      children: <></>,
      key: '1',
    },
    {
      label: 'Route',
      children: <UnitRoute />,
      key: '2',
    },
    {
      label: 'Unit History',
      children: <UnitHistoryTab />,
      key: '3',
    },
    {
      label: 'Places Nearby',
      children: <UnitInfo />,
      key: '4',
    },
  ];
  return (
    <>
      {checkPermission(AllPermissionsType.DRIVER_SHOW) ? (
        <MainContainer back>
          <Row style={{ height: '100%' }}>
            {false ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spiner />
              </div>
            ) : (
              <>
                <MainHeader titles={titles} back={true} link="/client/units" />
                <div className={styles.container}>
                  <div className={styles.body}>
                    <div className={styles.tabs}>
                      <Tabs
                        style={{ width: '100%' }}
                        defaultActiveKey="1"
                        items={items}
                        tabBarStyle={{
                          border: 'none',
                          position: 'fixed',
                          zIndex: 10,
                          background: 'white',
                          width: '30%',
                        }}
                      />
                    </div>
                    <div className={styles.map}>
                      <OpenLayerMap height={'100%'} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
