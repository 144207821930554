import styles from './Chat.module.css';
import { ICarrier, IDriver, IMessage } from './Chat';
import { useEffect, useRef, useState } from 'react';
import SearchInput from './SearchInput';
import { motion } from 'framer-motion';
import ChatFiltersIcon from '../../img/chatFilters.svg';
import topAngleIcon from '../../img/topAngleIcon.svg';
import bottomAngleIcon from '../../img/bottomAngleIcon.svg';
interface IUsers {
  drivers: IDriver[];
  selectDriver: IDriver | undefined;
  userId: string;
  open: boolean;
  carriers: ICarrier[];
  usersMessages: any;
  selectDriverClick: (driver: IDriver) => void;
}
const Users = ({
  drivers,
  selectDriver,
  userId,
  open,
  carriers,
  usersMessages,
  selectDriverClick,
}: IUsers) => {
  const [filtredDrivers, setFiltredDrivers] = useState(drivers);
  const [search, setSearch] = useState('');
  const [selectedFilterByCarrierIndex, setSelectedFilterByCarrierIndex] =
    useState(0);
  const [filtersByCarrier, setFiltersByCarrier] = useState<string[]>(['all']);
  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    // const newItems = [...filtredDrivers];
    // const itemToMove = newItems.splice(index, 1)[0];
    // itemToMove.pinned = true;
    // newItems.unshift(itemToMove);
    // setFiltredDrivers(newItems);
  };
  const searchChange = (value: string) => {
    const searchValue = value.toLowerCase();
    setSearch(value);

    const searchArrayByUsername = drivers.filter((driver) =>
      driver.username.toLowerCase().includes(searchValue),
    );
    const searchArrayByFirstName = drivers.filter((driver) =>
      driver.first_name.toLowerCase().includes(searchValue),
    );
    const searchArrayByLastName = drivers.filter((driver) =>
      driver.last_name.toLowerCase().includes(searchValue),
    );
    const combinedArray: IDriver[] = [
      ...searchArrayByUsername,
      ...searchArrayByFirstName,
      ...searchArrayByLastName,
    ];

    const uniqueCombinedArray: IDriver[] = Array.from(
      new Set(combinedArray.map((driver) => driver.id)),
    ).map((id) => combinedArray.find((driver) => driver.id === id)!);
    setFiltredDrivers(uniqueCombinedArray);
  };

  function checkLastElements(
    arr: IMessage[],
    targetId: string,
    driverId: string,
  ): boolean {
    const maxElementsToCheck = 1;

    const filterArray = arr.filter((mes) => mes.senderId != driverId);

    for (
      let i = filterArray.length - 1;
      i >= 0 && filterArray.length - i <= maxElementsToCheck;
      i--
    ) {
      if (filterArray[i].senderId === targetId) {
        return true;
      }
    }

    return false;
  }
  const getUnreadLength = (messages: IMessage[], driverId: string) => {
    const unReadMessages = messages?.map((message) => {
      if (message.senderId === driverId) {
        if (!message.read) {
          return message;
        }
      }
    });
    const filteredArray = unReadMessages?.filter(
      (item) => item !== undefined && item !== null,
    );
    const myReadMessages = checkLastElements(messages, userId, driverId);
    if (myReadMessages) {
      return filteredArray.length;
    }
    return 0;
  };
  useEffect(() => {
    carriers.map((carrier) => {
      return setFiltersByCarrier((prevState) => [...prevState, carrier.name]);
    });
  }, []);

  const setActiveFilter = (index: number, name: string) => {
    setSelectedFilterByCarrierIndex(index);
    if (name === 'all') {
      setFiltredDrivers(drivers);
    } else {
      const filterdDriversByCarrier = drivers.filter(
        (driver) => driver.carrier.name === name,
      );
      setFiltredDrivers(filterdDriversByCarrier);
    }
  };
  // const [openPopup, setOpenPopup] = useState(false);
  // const popUpRef = useRef<HTMLDivElement>(null);
  // const filterClick = () => {
  //   if (popUpRef.current) {
  //     popUpRef.current.focus();
  //     setOpenPopup(true);
  //   }
  // };
  // const alphClick = () => {
  //   const alphArray = filtredDrivers.sort((a, b) =>
  //     a.first_name.localeCompare(b.first_name),
  //   );
  //   setFiltredDrivers(alphArray);
  //   setOpenPopup(false);
  // };
  // const carrierClick = () => {
  //   setSelectedFilterByCarrierIndex(0);
  //   setFiltredDrivers(drivers);
  //   setOpenPopup(false);
  // };
  // filters
  return (
    <div className={styles.users}>
      <div className={styles.usersHeader}>
        <SearchInput
          value={search}
          onChange={searchChange}
          placeholder="Search..."
        />
        {/* <motion.button
          whileTap={{
            scale: 0.9,
            opacity: 0.5,
          }}
          onClick={filterClick}
          className={styles.usersFiltersButton}
        >
          <img src={ChatFiltersIcon} alt="filters" />
        </motion.button>
        <motion.div
          ref={popUpRef}
          tabIndex={0}
          onBlur={() => setOpenPopup(false)}
          className={styles.popup}
          animate={{ opacity: openPopup ? 1 : 0, zIndex: openPopup ? 5 : -1 }}
        >
          <div onClick={alphClick} className={styles.usersFilterPopUpItem}>
            <p>Alphabetical</p> <img src={topAngleIcon} />
          </div>
          <div onClick={carrierClick} className={styles.usersFilterPopUpItem}>
            <p>Carrier</p> <img src={bottomAngleIcon} />
          </div>
        </motion.div> */}
      </div>
      <div className={styles.usersFilters}>
        {filtersByCarrier.map((filter, index) => (
          <motion.div
            whileInView={{
              opacity: [0, 1],
            }}
            whileTap={{
              opacity: 0.5,
              scale: 0.9,
            }}
            style={{
              fontSize: open ? 10 : 12,
            }}
            onClick={() => setActiveFilter(index, filter)}
            className={`${styles.usersFiltersItem} ${index === selectedFilterByCarrierIndex && `${styles.usersFiltersItemActive}`}`}
          >
            {filter}
          </motion.div>
        ))}
      </div>
      <div className={styles.usersBody}>
        {filtredDrivers.map((driver, index) => {
          const messages = usersMessages.filter(
            (mes: any) => mes.id === driver.id,
          );
          const messArray = messages?.[0];
          const messagesArray = messArray
            ? Object.keys(messArray)
                .filter((key) => key !== 'id')
                .map((key) => ({
                  id: key,
                  ...messArray[key as keyof typeof messArray],
                }))
            : [];
          const lastImage: string = messagesArray?.[messagesArray?.length - 1]
            ? messagesArray?.[messagesArray?.length - 1]?.text?.length != 0
              ? messagesArray?.[messagesArray?.length - 1]?.text
                ? messagesArray?.[messagesArray?.length - 1]?.text
                : 'file'
              : 'file'
            : '';
          const messagesLength = getUnreadLength(messagesArray, driver.id);
          return (
            <motion.div
              whileInView={{
                opacity: [0, 1],
              }}
              whileTap={{
                scale: 0.98,
                opacity: 0.5,
              }}
              onContextMenu={(e) => handleContextMenu(e, index)}
              onClick={() => selectDriverClick(driver)}
              key={index}
              className={`${styles.usersBodyItem} ${driver.id === selectDriver?.id && `${styles.usersBodyItemActive}`}`}
            >
              <div
                style={{ backgroundColor: '#EDEEF0' }}
                className={styles.usersIcon}
              >
                {driver.first_name.slice(0, 1).toUpperCase()}
                {driver.last_name.slice(0, 1).toUpperCase()}
              </div>
              <div style={{ width: '100%' }}>
                <div className={styles.usersHeaderUsername}>
                  <p
                    className={`${styles.usersUsername}  ${open && `${styles.usersHeaderUsernameOpen}`} `}
                  >
                    {driver.first_name} {driver.last_name}({driver.username})
                  </p>
                  {/* {<img src={PinnedIcon} alt="pinned" />} */}
                </div>
                {lastImage?.length != 0 && (
                  <div
                    className={`${styles.usersLength} ${open && `${styles.usersLengthOpen}`}`}
                  >
                    <p>
                      {lastImage?.length < 20
                        ? lastImage
                        : lastImage?.slice(0, 20) + '...'}
                    </p>
                    {messagesLength != 0 && (
                      <span className={styles.length}>{messagesLength}</span>
                    )}
                  </div>
                )}
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Users;
