import { ChartDataSets, ChartForrmaterData } from '../interface/ChartInterface';

export const chartDataCreate = (
  driverLogDate: string,
  date: { formattedDate: string; timestamp: number },
  chartFormmatedData: ChartForrmaterData[],
  data: ChartDataSets[],
  startDay: number,
  endDay: number,
) => {
  for (let index = 0; index < chartFormmatedData.length; index++) {
    const element = chartFormmatedData[index];
    const {
      label,
      borderColor,
      borderWidth,
      stepped,
      pointRadius,
      borderDash,
    } = element;
    const time = element.x < startDay;
    const currentDate = driverLogDate === date.formattedDate;
    if (index + 1 < chartFormmatedData.length) {
      if (label === chartFormmatedData[index + 1].label) {
        data.push({
          label,
          data: [
            { x: time ? startDay : element.x, y: element.y },
            { x: chartFormmatedData[index + 1].x, y: element.y },
          ],
          borderColor,
          borderWidth,
          stepped,
          pointRadius,
          borderDash,
        });
      } else {
        data.push({
          label,
          data: [
            { x: time ? startDay : element.x, y: element.y },
            { x: chartFormmatedData[index + 1].x, y: element.y },
            {
              x: chartFormmatedData[index + 1].x,
              y: chartFormmatedData[index + 1].y,
            },
          ],
          borderColor,
          borderWidth,
          stepped,
          borderDash,
          pointRadius,
        });
      }
    } else {
      data.push({
        label,
        data: [
          { x: time ? startDay : element.x, y: element.y },
          { x: currentDate ? date.timestamp : endDay, y: element.y },
        ],
        borderColor,
        borderWidth,
        stepped,
        borderDash,
        pointRadius,
      });
    }
  }
};
