import { useMemo } from 'react';
import { Form, Input, Col } from 'antd';

export const TextInputDot = (props: any) => {
  const {
    rules = [],
    name = '',
    icon,
    placeholder = '',
    disabled,
    width = '100%',
    title = '',
    span = 24,
    styles = {},
    pathName = '',
    error,
    errorVisible,
    secondError,
    onClick,
  } = props;

  const getName = useMemo(() => {
    return pathName ? [...pathName, name] : name;
  }, [pathName, name]);

  return (
    <Col
      span={span}
      className="input-container-v2"
      style={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {title ? (
        title === 'invisible' ? (
          <div
            className="input-item-title input-title ubuntu"
            style={{ opacity: 0 }}
          >
            {title}
          </div>
        ) : (
          <div className="input-item-title input-title ubuntu">{title}</div>
        )
      ) : null}
      <Form.Item rules={rules} name={getName} style={{ width: '100%' }}>
        <Input
          prefix={
            !!icon ? (
              <span className={`icon-icon-${icon} orange`}></span>
            ) : (
              <span></span>
            )
          }
          placeholder={placeholder}
          style={{ width, ...styles }}
          disabled={disabled}
          className={
            disabled
              ? `custom-input-disabled ${onClick && 'custom-input-pointer'}`
              : ''
          }
        />
      </Form.Item>
      <div className="input-item-error">
        {errorVisible === 1 && error}
        {errorVisible === 2 && secondError}
      </div>
    </Col>
  );
};
