import React, { useState, useMemo, useEffect } from 'react';
import { Form, Input, Col, TimePicker, DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const InputDatePickerSingleV2 = (props: any) => {
  const {
    rules = [],
    name = '',
    icon,
    placeholder = '',
    label = '',
    disabled,
    width = '100%',
    title = '',
    isSecondField = false,
    span = 24,
    styles = {},
    pathName = '',
    form,
  } = props;
  const getName = useMemo(() => {
    return pathName ? [...pathName, name] : name;
  }, [pathName, name]);

  return (
    <Col
      span={span}
      className="input-container-v2"
      style={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {title ? (
        title === 'invisible' ? (
          <div
            className="input-item-title input-title ubuntu"
            style={{ opacity: 0 }}
          >
            {title}
          </div>
        ) : (
          <div className="input-item-title input-title ubuntu">{title}</div>
        )
      ) : null}

      <Form.Item style={{ width: '100%', ...styles }} name={getName}>
        <DatePicker
          onChange={(value) => form.setFieldValue(getName, value)}
          // value={form.getFieldValue(getName)}
          name={getName}
          placeholder={placeholder}
          style={{ width, ...styles }}
          disabled={disabled}
          format={'MM/DD/YYYY'}
          clearIcon={false}
        />
      </Form.Item>
    </Col>
  );
};
