function IftaIcon() {
  return (
    <svg
      style={{
        marginRight: 10,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_2892_175261)">
        <path
          className="ant-menu-item-icon"
          d="M13 20a5.006 5.006 0 005-5V6.243a3.97 3.97 0 00-1.172-2.829l-2.242-2.242A3.972 3.972 0 0011.757 0H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6zm-9-5V5a3 3 0 013-3s4.919.014 5 .024V4a2 2 0 002 2h1.976c.01.081.024 9 .024 9a3 3 0 01-3 3H7a3 3 0 01-3-3zm18-7v11a5.006 5.006 0 01-5 5H8a1 1 0 110-2h9a3 3 0 003-3V8a1 1 0 012 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2892_175261">
          <path className="ant-menu-item-icon" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IftaIcon;
