import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Dropdown, Row, Col, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTableParams } from '../../../hooks/useTableParams';
import dayjs from 'dayjs';

import { getRoleListReq } from '../../../actions/role';
import { getParams } from '../../../routes/utils';
import { InputSearch } from '../../common/doubleinput/InputSearch';
import { getOrderFromTableParams } from '../../../hooks/utils';
import { InputPageTitle } from '../../common/doubleinput/InputPageTitle';

import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import { carrierData } from '../driver/constant';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ViewPermissions } from './fields/ViewPermissions';
import { BurgerIcon } from '../../header/logo';
import { AllPermissionsType } from './constant';
import { usePermissions } from '../../../hooks/usePermissions';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
import TableSpinner from '../../spiner/TableSpinner';

dayjs.extend(customParseFormat);

export const RoleList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleTableChange,
    onSuccess,
    tableParams,
    rowSelection,
    clearOrderFilters,
    setSearchParam,
    hasFiltersOrOrder,
    clearFilter,
    clearOrder,
    clearCustomFilter,
    setCustomFilter,
  } = useTableParams({});
  const roles = useSelector((state: any) => state.role.roleList);

  const count = useSelector((state: any) => state.role.count);
  const loading = useSelector((state: any) => state.role.loading);
  const [accautnModalOpen, setAccauntModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { checkPermission } = usePermissions();

  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Role Name',
      key: 'name',
      dataIndex: 'name',
      sortOrder: getOrderFromTableParams('name', tableParams),
      sorter: {
        compare: (a: any, b: any) => a.name - b.name,
        multiple: 5,
      },
      render: (name, record, index) => {
        return (
          <div
            className="orange ubuntu"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(`${location.pathname}/${record.id}`);
            }}
          >
            {`${record.name}`}
          </div>
        );
      },
      width: 200,
      ellipsis: true,
    },

    // {
    //   title: "Data Modified",
    //   key: "serial_number",
    //   dataIndex: "serial_number",
    //   sortOrder: getOrderFromTableParams("serial_number", tableParams),
    //   sorter: {
    //     compare: (a: any, b: any) => a.serial_number - b.serial_number,
    //     multiple: 5,
    //   },
    //   render: (name, record, index) => {
    //     return (
    //       <div
    //         className="orange ubuntu"
    //         onClick={() => {
    //           navigate(`${location.pathname}/${record.id}`);
    //         }}
    //       >
    //         {`TBD`}
    //       </div>
    //     );
    //   },
    //   width: 300,
    //   ellipsis: true,
    // },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      sortOrder: getOrderFromTableParams('mac_address', tableParams),
      sorter: {
        compare: (a: any, b: any) => a.mac_address - b.mac_address,
        multiple: 5,
      },

      render: (name, record, index) => {
        return (
          <div
            className="ubuntu orange"
            style={{ color: '#141029', cursor: 'pointer' }}
          >
            <span className="ubuntu organe" onClick={() => setOpenModal(true)}>
              View
            </span>
            <Modal
              title={`Role: ${record.name} > Permissions`}
              open={openModal}
              onOk={() => setOpenModal(false)}
              onCancel={() => setOpenModal(false)}
              width={'90%'}
              footer={false}
            >
              <ViewPermissions
                {...record}
                onClose={() => setOpenModal(false)}
                permissionsList={Object.values(record?.permissions)}
                onSuccess={() => {
                  dispatch(
                    getRoleListReq({
                      queryParams: {
                        ...getParams(tableParams),
                        with: ['terminal', 'carrier', 'group'],
                      },
                    }),
                  );
                }}
              />
            </Modal>
          </div>
        );
      },
      ellipsis: true,
      width: '50%',
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sortOrder: getOrderFromTableParams("status", tableParams),
    //   key: "status",
    //   sorter: {
    //     compare: (a: any, b: any) => a.mcnumber - b.mcnumber,
    //     multiple: 5,
    //   },
    //   width: "9%",
    //   ellipsis: true,
    //   render: (value, record, index) => {
    //     const status = carrierData.status.find((st) => st.key === value);

    //     return <div>{status?.value}</div>;
    //   },
    //   filters: carrierData.status.map((st: any) => {
    //     return {
    //       text: st.value,
    //       value: st.key,
    //     };
    //   }),
    //   filteredValue: tableParams?.filters?.status || null,
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '5%',
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <Dropdown
            placement="bottomLeft"
            trigger={['click']}
            className="menu-option"
            menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <div
                      onClick={() => {
                        checkPermission(AllPermissionsType.ROLE_EDIT)
                          ? navigate(
                              `${location.pathname}/${record.id}?state=EDIT`,
                            )
                          : navigate(
                              `${location.pathname}/${record.id}?state=VIEW`,
                            );
                      }}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <span
                        className="icon-fi-rr-pencil"
                        style={{ marginRight: 5 }}
                      ></span>
                      Edit
                      <div style={{ marginLeft: 10, marginRight: 10 }}>|</div>
                      <span
                        className="icon-fi-rr-eye"
                        style={{ marginRight: 5 }}
                      ></span>
                      View
                    </div>
                  ),
                },
                {
                  key: '3',
                  label: (
                    <div
                      onClick={() => {}}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <span
                        className="icon-fi-rr-trash"
                        style={{ marginRight: '10px' }}
                      ></span>{' '}
                      Delete
                    </div>
                  ),
                },
              ],
            }}
          >
            <span>
              <BurgerIcon />
            </span>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getRoleListReq({
        queryParams: {
          ...getParams(tableParams),
          with: ['terminal', 'carrier', 'group'],
        },
      }),
    );
  }, [tableParams]);

  return (
    <>
      <MainContainer>
        <Row>
          <Col span={12}>
            <MainHeader titles={[{ text: 'Roles and Permissions' }]} />
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <InputSearch
              onChange={setSearchParam}
              onClear={clearOrderFilters}
              hasFilters={hasFiltersOrOrder}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="icon-fi-rr-plus ubuntu orange" />
              <div
                className="orange ubuntu"
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  marginLeft: 8,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`${location.pathname}/create`);
                }}
              >
                Create Role
              </div>
            </div>
            <div style={{ marginLeft: 25, display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={clearOrder}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={ResetSort} />
                </div>
                <div
                  className="ubuntu"
                  style={{ color: '#8A8996', fontSize: 12 }}
                >
                  Reset sorting
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginLeft: 25,
                }}
                onClick={clearFilter}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={ResetFilter} />
                </div>
                <div
                  className="ubuntu"
                  style={{ color: '#8A8996', fontSize: 12 }}
                >
                  Reset filter
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={roles?.map((carrier: any, index: any) => {
            return {
              ...carrier,
            };
          })}
          pagination={{
            ...tableParams.pagination,
            position: ['bottomCenter'],
            total: count,
          }}
          loading={{
            spinning: loading,
            indicator: <TableSpinner />,
          }}
          onChange={handleTableChange}
          rowSelection={{ ...rowSelection, columnWidth: 10 }}
          className="table-custom"
          //   sticky
          //   scroll={{ y: window.innerHeight - 235 }}
        />
      </MainContainer>
    </>
  );
};
