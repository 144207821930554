export const CahrtOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: true,
      color: 'black',
      font: {
        weight: 'bold' as const,
      },
      formatter: (value: any) => value,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'time',
      display: false,
      displayFormats: {
        unit: 'hour',
      },
      ticks: {
        beginAtZero: true,
        stepSize: 1,
      },
    },
    y: {
      display: false,
      min: 0,
      max: 5,
    },
  },
};

export function generateLabels(): string[] {
  const labels: string[] = [];
  labels.push('M');

  for (let i = 1; i < 12; i++) {
    const hour = i < 10 ? '0' + i : '' + i;
    labels.push(hour);
  }

  labels.push('N');

  for (let i = 1; i < 12; i++) {
    const hour = i < 10 ? '0' + i : '' + i;
    labels.push(hour);
  }
  // labels.push("M");

  return labels;
}

export const ChartPlugin = [
  {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'transparent';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  },
];
