import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { getCarriersListReq } from '../../../actions/carrier';
import {
  updateDriverReq,
  getDriverReq,
  setCurrentCarrier,
  getDriverContactsSucces,
} from '../../../actions/driver';
import { CARRIER_SELECT_DISABLED } from '../../common/doubleinput/utils';

import { Row, Col, Form, Button, Input, Spin, DatePicker } from 'antd';
import { CommonInput } from '../../common/inputs';
import { carrierForm } from './carrier-form';
import { InputType } from '../../../constants/inputs';
import { CarrierField, PAGE_STATUS, getDocumentByType } from './constant';
import { usePermissions } from '../../../hooks/usePermissions';
import { AllPermissionsType } from '../role/constant';
import { NoPermission } from '../../common/NoPermission';
import { cleanObject } from '../../../utils/cleanObject';
import MainContainer from '../../layout/MainContainer';
import Spiner from '../../spiner/Spiner';

export const DriverPage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const [search, setSearch] = useSearchParams();
  const [state, setStateValue] = React.useState(search.get('state'));
  const contacts = useSelector((state: any) => state?.driver?.contacts);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    name: '',
    usdot: '',
    phone: '',
    mcnumber: '',
    email: '',
    person: '',
    status: null,
    notes: '',
    email_second: '',
    measurement_system: null,
    dst: null,
    first_day: null,
    compliance_mode: null,
    motion_treshold: null,
    cargo_type: [],
    restart: null,
    rest_break: null,
    short_haul: false,
    personal_conveyance: false,
    adverse_conditions: false,
    unlimited_documents: false,
    unlimited_trailers: false,
    yard_move: false,
    exempt_driver: false,
    exempt_driver_notice: false,
    period_starting_time: '',
    motion_trashhold: '',
    terminal: null,
    driver_group: null,
    password: '',
  });
  const { loading, driver, currentCarrier } = useSelector(
    (state: any) => state.driver,
  );
  const { loading: carrierLoading, carrierList } = useSelector(
    (state: any) => state.carrier,
  );

  const { user } = useSelector((state: any) => state.auth);
  const [fields, setFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (driver?.carrier?.id) {
      const foundCarrier = carrierList.find(
        (carrier: any) => carrier.id === driver.carrier.id,
      );

      dispatch(
        setCurrentCarrier({ ...foundCarrier, defaultSavedCarrier: true }),
      );
    }
  }, [carrierList, driver]);

  React.useEffect(() => {
    setStateValue(search.get('state'));
  }, [search]);

  React.useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['settings', 'terminals', 'driver_groups', 'documents'],
        },
      }),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...currentCarrier?.settings,
      cargo_type: form.getFieldValue('cargo_type'),
      driver_group: currentCarrier?.defaultSavedCarrier
        ? form.getFieldValue('driver_group')
        : null,
    });
  }, [currentCarrier]);

  useEffect(() => {
    dispatch(
      getDriverReq({
        driverId: params.driverid,
        queryParams: {
          with: ['terminal', 'group', 'carrier', 'documents'],
        },
      }),
    );
  }, []);

  const handleSubmit = async (values: any) => {
    const data = {
      ...values,
      company: user.company.id,
      cdl_state: `${values.cdl_state}`,
      offices: [...user.offices].map((office) => office.id),
      documents: [
        ...(values?.documents_MC?.length > 0
          ? values?.documents_MC?.map((doc: any) => {
              return {
                type: getDocumentByType(doc.fileType) || 2,
                file: doc.originFileObj,
                driver: params.driverid,
              };
            })
          : []),
        ...(values?.documents_CDL?.length > 0
          ? values?.documents_CDL?.map((doc: any) => {
              return {
                type: getDocumentByType(doc.fileType) || 1,
                file: doc.originFileObj,
                driver: params.driverid,
              };
            })
          : []),
      ],
      documents_MC: undefined,
      documents_CDL: undefined,
      password: values.password.length != 0 ? values.password : null,
    };

    dispatch(
      updateDriverReq({
        values: data,
        driverId: params.driverid,
      }),
    );
  };

  React.useEffect(() => {
    setInitialValues({
      ...initialValues,
      ...driver,
      carrier: driver?.carrier?.id,
      terminal: driver?.terminal?.id,
      driver_group: driver?.group?.id,
      cargo_type: driver?.cargo_type?.map((ct: any) => +ct),
    });
    form.setFieldsValue({
      ...initialValues,
      ...driver,
      carrier: driver?.carrier?.id,
      terminal: driver?.terminal?.id,
      driver_group: driver?.group?.id,
      cargo_type: driver?.cargo_type?.map((ct: any) => +ct),
      group: driver?.group?.id,
    });
  }, [driver]);

  const { checkPermission } = usePermissions();
  const [visible, setVisible] = useState(false);
  const setContactFilds = () => {
    form.setFieldValue(CarrierField.EMAIL, contacts?.email);
    form.setFieldValue(CarrierField.PHONE, contacts?.phone);
    return true;
  };
  const getContactsClick = async () => {
    dispatch(
      getDriverContactsSucces({
        carrierId: params.driverid,
      }),
    );
  };
  useEffect(() => {
    if (contacts) {
      const response = setContactFilds();
      setVisible(response);
    }
  }, [contacts]);

  const onChange = () => {
    let value: string = form.getFieldValue('period_starting_time');
    if (value) {
      value = value.replace(/\D/g, '');

      const hours = parseInt(value.slice(0, 2), 10);
      if (hours < 0 || hours > 24) {
        value = value.slice(0, 2);
      }
      const minutes = parseInt(value.slice(2, 4), 10);
      if (minutes < 0 || minutes > 59) {
        value = value.slice(0, 4);
      }
      const seconds = parseInt(value.slice(4, 6), 10);
      if (seconds < 0 || seconds > 59) {
        value = value.slice(0, 5);
      }

      value = value.slice(0, 6);
      value = value.replace(/(..)(?!$)/g, '$1:');
      form.setFieldValue('period_starting_time', value);
    }
  };
  return (
    <>
      {checkPermission(AllPermissionsType.DRIVER_SHOW) ? (
        <MainContainer half>
          <Row style={{ height: '100%' }}>
            {loading ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  minHeight: 600,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spiner />
              </div>
            ) : (
              <Col span={24}>
                <Form
                  form={form}
                  name="test"
                  onFinish={handleSubmit}
                  initialValues={initialValues}
                  onChange={onChange}
                >
                  {carrierForm({ visible, onClick: getContactsClick }).map(
                    (fieldCurrent: any, i: number) => {
                      const field = {
                        ...fieldCurrent,
                        disabled:
                          state === PAGE_STATUS.VIEW ||
                          !checkPermission(AllPermissionsType.DRIVER_EDIT),
                        isReadonlyCarrier: true,
                      };

                      if (CARRIER_SELECT_DISABLED.includes(field.type)) {
                        return (
                          <CommonInput
                            currentIndex={currentIndex}
                            fields={fields}
                            key={i}
                            setCurrentIndex={setCurrentIndex}
                            {...field}
                            form={form}
                            isReadonlyCarrier={true}
                          />
                        );
                      }

                      if (field.type === InputType.ADD_DYNAMIC) {
                        return (
                          <CommonInput
                            currentIndex={currentIndex}
                            fields={fields}
                            key={i}
                            setCurrentIndex={setCurrentIndex}
                            {...field}
                            form={form}
                          />
                        );
                      }
                      return (
                        <CommonInput
                          key={i}
                          {...field}
                          form={form}
                          showDocsList={true}
                        />
                      );
                    },
                  )}
                  <Form.Item style={{ width: '100%', display: 'flex' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="orange"
                      style={{ width: '65px', marginRight: 12 }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.DRIVER_EDIT)
                      }
                    >
                      Save
                    </Button>
                    <Button
                      className="grey"
                      style={{ width: '85px', marginRight: 12 }}
                      onClick={() => {
                        form.setFieldsValue(initialValues);
                      }}
                      disabled={
                        state === PAGE_STATUS.VIEW ||
                        !checkPermission(AllPermissionsType.DRIVER_EDIT)
                      }
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        </MainContainer>
      ) : (
        <NoPermission />
      )}
    </>
  );
};
