import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import React, { useEffect, useLayoutEffect } from 'react';
import { ENV } from '../../utils/constants';
import styles from './Chat.module.css';
import unReadIcon from '../../img/unChecked.svg';
import readIcon from '../../img/cheackedIcon.svg';
import pdfIcon from '../../img/pdfIcon.svg';
import { IDriver, IMessage } from './Chat';

interface IUserMessageProps {
  message: IMessage;
  driver: IDriver | undefined;
  username: string;
  mediaType: string;
  userId: string;
  readMessage: (driverId: string, messageId: string) => void;
  lastTenMessages: any[];
}
const UserMessage = ({
  message,
  driver,
  username,
  mediaType,
  userId,
  readMessage,
  lastTenMessages,
}: IUserMessageProps) => {
  function checkLastElements(
    arr: IMessage[],
    targetId: string,
    driverId: string,
  ): boolean {
    const maxElementsToCheck = 1;

    const filterArray = arr.filter((mes) => mes.senderId != driverId);

    for (
      let i = filterArray.length - 1;
      i >= 0 && filterArray.length - i <= maxElementsToCheck;
      i--
    ) {
      if (filterArray[i].senderId === targetId) {
        return true;
      }
    }

    return false;
  }
  useEffect(() => {
    if (driver && lastTenMessages.length != 0) {
      const checked = checkLastElements(lastTenMessages, userId, driver.id);
      if (checked) {
        readMessage(driver.id, message.id);
      }
    }
  }, [lastTenMessages]);
  return (
    <motion.div
      whileInView={{
        scale: [0.95, 1],
        opacity: [0.5, 1],
      }}
      className={`${styles.messageCon} ${message.senderId != driver?.id && `${styles.right}`} `}
    >
      <div className={styles.messageUsername}>
        {message.senderId === driver?.id ? username : message.username}
      </div>

      <div
        className={` ${
          message.media ? `${styles.messageFile}` : `${styles.message}`
        }  
           ${message.senderId != driver?.id && message.media && `${styles.rightMessage}`} 
           ${message.senderId === userId && !!message.media === false && `${styles.my}`}
           `}
      >
        {message.media && (
          <>
            {mediaType === 'pdf' ? (
              <>
                <a
                  className={styles.pdfLink}
                  href={`${ENV}${message.media}`}
                  target="_blank"
                >
                  <div
                    className={`${message.senderId === userId ? `  ${styles.pdfMy}` : `${styles.pdf}`}`}
                  >
                    <img src={pdfIcon} />
                  </div>
                </a>
              </>
            ) : (
              <>
                <img
                  className={styles.mediaMessages}
                  src={`${ENV}${message.media}`}
                />
              </>
            )}
          </>
        )}
        {message.text}
      </div>
      <div className={styles.messageFooter}>
        {message.senderId != driver?.id && (
          <>
            {message.read ? (
              <img className={styles.readIcon} src={readIcon} />
            ) : (
              <img className={styles.readIcon} src={unReadIcon} />
            )}
          </>
        )}
        {dayjs(message.time).format('hh:mm A')}
      </div>
    </motion.div>
  );
};

export default UserMessage;
