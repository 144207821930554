import { DatePicker, Table, TimeRangePickerProps } from 'antd';
import { InputPageTitle } from '../../common/doubleinput/InputPageTitle';
import styles from './UnitHistory.module.css';
import { ColumnsType } from 'antd/es/table';
import { InputSelectV2 } from '../../common/doubleinput';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCarriersListReq, getUnitListReq } from '../../../actions';
import dayjs from 'dayjs';
import ResetSort from '../../../img/resetSort.svg';
import ResetFilter from '../../../img/resetFilter.svg';
import { InputSearch } from '../../common/doubleinput/InputSearch';
import { useTableParams } from '../../../hooks/useTableParams';
import MainContainer from '../../layout/MainContainer';
import MainHeader from '../../header/MainHeader';
const UnitHistory = () => {
  const { RangePicker } = DatePicker;
  const [selectOptions, setSelectOptions] = useState([]);
  const units = useSelector((state: any) => state.units.units);
  const [filtredUnits, setFiltredUnits] = useState([]);
  const carriers = useSelector((state: any) => state.carrier.carrierList);
  const dispatch = useDispatch();
  const { tableParams } = useTableParams({});
  const [codriverOptions, setCodriverOptions] = useState([]);
  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: 'Driver',
      key: 'driver',
      dataIndex: 'driver',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {record?.driver?.first_name} {record?.driver?.last_name}
          </div>
        );
      },
      filters: filtredUnits.map((value: any) => {
        const text = value?.driver?.first_name + value?.driver?.last_name;
        if (text) {
          return {
            text,
            value: value?.driver?.id,
          };
        } else {
          return {
            text: '',
            value: '',
          };
        }
      }),
      filteredValue: tableParams?.filters?.driver || null,
    },
    {
      title: 'Vehicle',
      key: 'vehicle',
      dataIndex: 'vehicle',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {record?.vehicle?.identificator}
          </div>
        );
      },
      filters: filtredUnits.map((value: any) => {
        const text = value?.vehicle?.identificator;
        if (text) {
          return {
            text,
            value: value?.vehicle?.id,
          };
        } else {
          return {
            text: '',
            value: '',
          };
        }
      }),
      filteredValue: tableParams?.filters?.vehicle || null,
    },
    {
      title: 'ELD',
      key: 'eld',
      dataIndex: 'eld',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {record?.device?.identificator}
          </div>
        );
      },
      filters: filtredUnits.map((value: any) => {
        const text = value?.device?.identificator;
        if (text) {
          return {
            text,
            value: value?.device?.id,
          };
        } else {
          return {
            text: '',
            value: '',
          };
        }
      }),
      filteredValue: tableParams?.filters?.device || null,
    },
    {
      title: 'Co Driver',
      key: 'codriver',
      dataIndex: 'codriver',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {record?.codriver?.first_name} {record?.codriver?.last_name}
          </div>
        );
      },
      filters: codriverOptions.map((value: any) => {
        const text =
          value?.codriver?.first_name + ' ' + value?.codriver?.last_name;
        if (text) {
          return {
            text,
            value: value?.codriver?.id,
          };
        } else {
          return {
            text: '',
            value: '',
          };
        }
      }),
      filteredValue: tableParams?.filters?.codriver || null,
    },
    {
      title: 'Shipping doc',
      key: 'shippingDoc',
      dataIndex: 'shippingDoc',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          ></div>
        );
      },
    },
    {
      title: 'Trailer',
      key: 'trailer',
      dataIndex: 'trailer',
      width: '8%',
      render: (name, record, index) => {
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {record?.trailer}
          </div>
        );
      },
    },
    {
      title: 'Period',
      key: 'period',
      dataIndex: 'period',
      width: '30%',
      render: (name, record, index) => {
        const day = dayjs();
        const period = `${day.subtract(7, 'd').format('MM/DD/YYYY hh:mm:ss A')} - ${day.format('MM/DD/YYYY hh:mm:ss A')}`;
        return (
          <div
            className="ubuntu pointer"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {period}
          </div>
        );
      },
    },
  ];
  const selectProps = {
    inputType: 'text',
    type: 'double',
    options: selectOptions,
    name: 'select',
    title: 'Select carrier',
    label: 'Select *',
    placeholder: 'Please select carrier',
    hasFeedback: true,
    span: 10,
    width: '100%',
  };
  const onChange = (value: string) => {
    const filtred = units.filter((unit: any) => unit?.carrier?.id === value);
    setFiltredUnits(filtred);
    const log = filtred.filter((item: any) => {
      if (item.codriver?.id === undefined) {
        return false;
      } else {
        const text = item.codriver.first_name + ' ' + item.codriver.last_name;
        return {
          text,
          value: item.codriver.id,
        };
      }
    });
    setCodriverOptions(log);
  };
  useEffect(() => {
    dispatch(getCarriersListReq({}));
    dispatch(
      getUnitListReq({
        queryParams: {
          with: ['terminal', 'carrier', 'group', 'vehicle', 'codriver'],
        },
      }),
    );
  }, []);
  useEffect(() => {
    const option = carriers?.map((value: any) => {
      return {
        key: value?.id,
        value: value?.name,
      };
    });
    setSelectOptions(option);
  }, [carriers]);
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last 8 days',
      value: [dayjs().subtract(8, 'd'), dayjs()],
    },
    {
      label: 'Last month ',
      value: [dayjs().subtract(1, 'M'), dayjs()],
    },
  ];
  return (
    <MainContainer>
      <div className={styles.container}>
        <MainHeader titles={[{ text: 'Unit History' }]} />
        <div className={styles.body}>
          <div className={styles.filters}>
            <div className={styles.pickers}>
              <InputSelectV2 onChange={onChange} {...selectProps} />
              <div className={styles.rangeFiled}>
                <p className="input-item-title">Select period</p>
                <RangePicker
                  presets={rangePresets}
                  className={styles.rangePicker}
                />
              </div>
            </div>
            <div className={styles.defaultFilters}>
              <InputSearch
              // onChange={setSearchParam}
              // onClear={clearOrderFilters}
              // hasFilters={hasFiltersOrOrder}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                // onClick={clearOrder}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={ResetSort} />
                </div>
                <div
                  className="ubuntu"
                  style={{ color: '#8A8996', fontSize: 12 }}
                >
                  Reset sorting
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginLeft: 25,
                }}
              >
                <div style={{ marginRight: 5 }}>
                  <img src={ResetFilter} />
                </div>
                <div
                  className="ubuntu"
                  style={{ color: '#8A8996', fontSize: 12 }}
                >
                  Reset filter
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={filtredUnits}
            pagination={{
              ...tableParams.pagination,
              position: ['bottomCenter'],
            }}
            className="table-custom"
            rowClassName="pointer-row"
          />
        </div>
      </div>
    </MainContainer>
  );
};

export default UnitHistory;
