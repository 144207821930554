import React from 'react';
import UnitCard, { IUnitCardProps } from './UnitCard';
import styles from './Unit.module.css';
import { DatePicker, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import { disabledDate } from '../dashboard/DasboardHeader';
const UnitHistoryTab = () => {
  const unitArray: IUnitCardProps[] = [
    {
      title: '07/16/24 05:56 am - Currrent',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      icon: 'icon-fi-rr-document-signed',
      body: [
        {
          title: 'Driver',
          icon: 'icon-fi-rr-document-signed',
          changed: true,
        },
        {
          title: 'Vehicle',
          changed: false,
        },
        {
          title: 'ELD',
          changed: false,
        },
        {
          title: 'Co Driver',
          changed: false,
        },
        {
          title: 'Shipping doc',
          changed: false,
        },
        {
          title: 'Trailer',
          changed: false,
        },
      ],
    },
    {
      title: '07/16/24 05:56 am - 07/16/24 05:56 pm ',
      location: '700 E Tri ST Toliway, South Holland, IL 46324',
      icon: 'icon-fi-rr-document-signed',
      body: [
        {
          title: 'Driver',
          icon: 'icon-fi-rr-document-signed',
          changed: true,
        },
        {
          title: 'Vehicle',
          changed: false,
        },
        {
          title: 'ELD',
          changed: false,
        },
        {
          title: 'Co Driver',
          changed: false,
        },
        {
          title: 'Shipping doc',
          changed: false,
        },
        {
          title: 'Trailer',
          changed: false,
        },
      ],
    },
  ];
  const { RangePicker } = DatePicker;
  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: '2023 - Q3',
      value: [
        dayjs('2023-07-01').startOf('quarter'),
        dayjs('2023-09-30').endOf('quarter'),
      ],
    },
    {
      label: '2023 - Q4',
      value: [
        dayjs('2023-10-01').startOf('quarter'),
        dayjs('2023-12-31').endOf('quarter'),
      ],
    },
    {
      label: '2024 - Q1',
      value: [
        dayjs('2024-01-01').startOf('quarter'),
        dayjs('2024-03-31').endOf('quarter'),
      ],
    },
    {
      label: '2024 - Q2',
      value: [dayjs('2024-04-01').startOf('quarter'), dayjs()],
    },
  ];
  return (
    <div className={styles.info}>
      <div style={{ width: '100%', marginBottom: 15 }}>
        <div className="input-item-title">Period</div>
        <RangePicker
          style={{
            width: '100%',
          }}
          name={'range'}
          presets={rangePresets}
          className={styles.rangePicker}
          disabledDate={disabledDate}
        />
      </div>
      <div className={styles.infoBody}>
        {unitArray.map((unit) => (
          <UnitCard
            icon={unit.icon}
            title={unit.title}
            body={unit.body}
            location={unit.location}
          />
        ))}
      </div>
    </div>
  );
};

export default UnitHistoryTab;
