import { useEffect, useState } from 'react';

import './log-top-panel.scss';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { getCurrentLogDateReq } from '../../../../actions';
import { getTzValue } from '../../../../utils/utils';
import styles from './LogViolationPanel.module.css';
import { motion } from 'framer-motion';
import { getCurrentDate } from '../LogTabs/Chart/utils/ChartDateForrmatter';
import { Root2 } from '../LogTabs/Chart/interface/ChartInterface';
import { timeFormat } from './LogDashboardPanel';
import { getEventLabel } from '../log-utils';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
export const LogViolationPanel = () => {
  const dispatch = useDispatch();
  const currentLogs = useSelector(
    (state: any) => state?.driverLog?.currentLogs,
  );
  const [currentLog, setCurrentLog] = useState<{
    label: string | undefined;
    day: number;
    hour: number;
    minute: number;
    id: string;
  }>();
  const params = useParams();
  const driverLog = useSelector((state: any) => state.driverLog);
  const tzValue = driverLog?.driverData?.driver?.terminal?.tz_value;
  const tz = getTzValue(tzValue);
  const [lastStatus, setLastStatus] = useState('');
  useEffect(() => {
    dispatch(
      getCurrentLogDateReq({
        queryParams: {},
        driverid: params?.driverid,
        current: true,
        date: dayjs().tz(tz).format('YYYY-MM-DD'),
      }),
    );
  }, [params?.driverid]);
  const violations = [
    {
      text: '11 Hours driving limit',
    },
    {
      text: '12 Hours driving limit',
    },
    {
      text: '13 Hours driving limit',
    },
    {
      text: '14 Hours driving limit',
    },
    {
      text: '15 Hours driving limit',
    },
    {
      text: '16 Hours driving limit',
    },
    {
      text: '17 Hours driving limit',
    },
    {
      text: '18 Hours driving limit',
    },
  ];

  const statuses = [
    {
      text: 'Current Status',
      time: lastStatus,
    },
    {
      text: 'Certification',
      certificated: true,
      time: '',
    },
    {
      text: 'DVIR',
      certificated: false,
      time: '',
    },
  ];
  const [sliceIndex, setSliceIndex] = useState(3);
  const hideViolations = () => {
    if (sliceIndex === 3) return setSliceIndex(violations.length);
    return setSliceIndex(3);
  };

  const getCurrentStatus = () => {
    const filtredLogs: Root2[] = currentLogs.map((item: any) => {
      if (item.event_type === 1 || item.event_type === 3) return item;
    });
    const date = getCurrentDate(tz);

    const lastLog = filtredLogs[filtredLogs?.length - 1];
    if (lastLog) {
      const duration = date.timestamp / 1000 - lastLog.timestamp;
      const time = timeFormat(duration);
      const currentLog = {
        label: getEventLabel(lastLog.event_type, lastLog.event_code),
        day: time.days,
        hour: time.hours,
        minute: time.minutes,
        id: lastLog.id,
      };
      setCurrentLog(currentLog);
      setLastStatus(
        `${currentLog?.label} ${currentLog?.day}d ${currentLog?.hour}h ${currentLog?.minute}m`,
      );
    }
  };

  useEffect(() => {
    currentLogs && getCurrentStatus();
  }, [currentLogs]);
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 10px 10px 10px',
      }}
    >
      <div
        className={`ubuntu ${sliceIndex === 3 ? `${styles.container}` : `${styles.containerHalf}`} bold`}
        style={{ fontWeight: 'bold' }}
      >
        <div
          style={{ color: '#374957', display: 'flex', alignItems: 'center' }}
        >
          Violation
        </div>
        <div
          className={`${sliceIndex === 3 ? `${styles.wrap}` : `${styles.wrapHide}`}`}
        >
          <p
            style={{
              color: '#FD371F',
              marginRight: 10,
            }}
          >
            Signature
          </p>
          <div className={styles.violations}>
            {violations.slice(0, sliceIndex).map((item, index) => (
              <motion.p
                key={index}
                className={styles.p}
                whileInView={{
                  opacity: [0, 1],
                  scale: [0.9, 1],
                }}
                style={{
                  color: '#FD371F',
                }}
              >
                {item.text}{' '}
                {violations.slice(0, sliceIndex).length - 1 != index && (
                  <>{`|`}</>
                )}
              </motion.p>
            ))}
          </div>
          <p className={styles.hide} onClick={hideViolations}>
            {sliceIndex === 3 ? <>Show more</> : <>Hide</>}
          </p>
        </div>
        {sliceIndex === 3 && (
          <motion.div
            whileInView={{
              opacity: [0, 1],
              scale: [0.9, 1],
            }}
            className={styles.current}
          >
            {statuses.map((status) => (
              <p className={`${styles.currentItem}`}>
                {status.text}:{' '}
                <span
                  className={`${status.certificated != null && `${styles.currentItemActive}`} ${status.certificated && `${styles.currentItemInActive}`}`}
                >
                  {status.time}
                </span>
              </p>
            ))}
          </motion.div>
        )}
      </div>
    </div>
  );
};
