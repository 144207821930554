import { motion } from 'framer-motion';
import styles from './Chat.module.css';
import { useEffect, useRef, useState } from 'react';
import chatIcon from '../../img/chatIcon.svg';
import Users from './Users';
import Messages from './Messages';
import { useDispatch, useSelector } from 'react-redux';
import { getCarriersListReq, getDriverListReq } from '../../actions';
import {
  child,
  get,
  getDatabase,
  onValue,
  push,
  ref,
  set,
  update,
} from 'firebase/database';
import { database, firebase } from '../../firebase';
import dayjs from 'dayjs';
import { useChat } from './ChatProvider';

export interface IDriver {
  documents: any;
  id: string;
  first_name: string;
  last_name: string;
  username: string;
  status: number;
  cdl: string;
  cdl_state: number;
  cdl_state_value: string;
  cdl_expiration: string;
  medical_card: string;
  medical_card_expiration: string;
  measurement_system: number;
  measurement_system_value: string;
  dst: number;
  dst_value: string;
  first_day: number;
  period_starting_time: string;
  compliance_mode: number;
  compliance_mode_value: string;
  motion_threshold: number;
  cargo_type: number[];
  cargo_type_values: string[];
  hos_rules: number;
  hos_rules_value: string;
  restart: number;
  restart_value: string;
  rest_break: number;
  rest_break_value: string;
  short_haul: boolean;
  exempt_driver: boolean;
  exempt_driver_notice: any;
  personal_conveyance: boolean;
  yard_move: boolean;
  adverse_condition: boolean;
  unlimited_trailers: boolean;
  unlimited_documents: boolean;
  alarm_allow: any;
  notes: any;
  signature: string;
  pinned: boolean;
  carrier: ICarrier;
}
export interface IMessage {
  id: string;
  duration: number;
  media: string;
  read: boolean;
  senderId: string;
  text: string;
  time: number;
  username?: string;
}
export interface ICarrier {
  id: string;
  name: string;
  usdot: string;
  mcnumber: string;
  status: number;
  status_value: string;
  tz: any;
  tz_value: any;
  note: string;
  logo?: string;
  with_account: boolean;
  address: any;
  company: any;
  offices: any;
  settings: any;
  terminals: any;
  driver_groups: any;
  drivers: any;
  vehicles: any;
  devices: any;
}
const Chat = () => {
  const { open, openChat, closeChat, driver } = useChat();
  const drivers: IDriver[] = useSelector((state: any) =>
    state?.driver?.driverList.map((driver: IDriver) => ({
      ...driver,
      pinned: false,
    })),
  );
  const carriers: ICarrier[] = useSelector(
    (state: any) => state?.carrier?.carrierList,
  );
  const divRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: any) => state.auth.user);
  const [selectDriver, setSelectDriver] = useState<IDriver>();
  const [width, setWidth] = useState<number | string>(50);
  const [height, setHeight] = useState<number | string>(50);
  const [usersMessages, setUsersMessages] = useState<any>([]);
  const dispatch = useDispatch();
  const openClick = () => {
    if (!open) {
      openChat();
    }
  };
  const closeClick = () => {
    closeChat();
  };
  const setMessage = (value: string, link: string, media: string) => {
    const db = getDatabase(firebase);
    const dbRef = push(ref(db, `topics/${link}`));
    set(dbRef, {
      duration: 0,
      media,
      read: false,
      senderId: user.id,
      time: dayjs().valueOf(),
      text: value,
      username: user.first_name + ' ' + user.last_name,
    }).then(() => {
      if (divRef.current) {
        divRef.current.scrollTo({
          behavior: 'smooth',
          top: divRef.current.scrollHeight,
        });
      }
    });
  };
  const getAllMessages = () => {
    const init = () => {
      const db = getDatabase(firebase);
      const dbRef = ref(db, `topics`);
      const unsubscribe = onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        const topicsArray: any = data
          ? Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }))
          : [];
        setUsersMessages(topicsArray);
      });
      return () => unsubscribe();
    };
    init();
  };
  const readMessage = (driverId: string, messageId: string) => {
    const updates: any = {};
    updates[`topics/${driverId}/${messageId}/read/`] = true;
    update(ref(database), updates);
  };
  useEffect(() => {
    if (open) {
      setWidth(1000);
      setHeight(700);
    } else {
      setWidth(50);
      setHeight(50);
    }
  }, [open]);
  useEffect(() => {
    dispatch(
      getDriverListReq({
        queryParams: {
          with: ['carrier'],
        },
      }),
    );
    dispatch(getCarriersListReq({}));
  }, []);
  useEffect(() => {
    getAllMessages();
  }, []);
  const selectDriverClick = (driver: IDriver) => {
    setSelectDriver(driver);
    setTimeout(() => {
      if (divRef.current) {
        divRef.current.scrollTo({
          behavior: 'smooth',
          top: divRef.current.scrollHeight,
        });
      }
    }, 200);
  };
  useEffect(() => {
    if (driver) {
      selectDriverClick(driver);
    }
  }, [driver]);
  return (
    <motion.div
      style={{
        cursor: open ? 'default' : 'pointer',
        userSelect: 'none',
        touchAction: 'none',
      }}
      animate={{
        width: width,
        height: height,
        borderRadius: open ? 10 : 50,
        borderColor: open ? '#E0E0E0' : 'transparent',
      }}
      onClick={openClick}
      className={styles.chat}
    >
      {open ? (
        <>
          <motion.div
            whileInView={{
              opacity: [0, 0, 1],
              scale: [0, 1],
            }}
            className={styles.container}
          >
            <Users
              usersMessages={usersMessages}
              carriers={carriers}
              open={width === 900}
              selectDriver={selectDriver}
              drivers={drivers}
              userId={user.id}
              selectDriverClick={selectDriverClick}
            />
            <Messages
              readMessage={readMessage}
              closeClick={closeClick}
              divRef={divRef}
              userId={user.id}
              driver={selectDriver}
              setAllMessages={setMessage}
              allMessages={usersMessages}
            />
          </motion.div>
        </>
      ) : (
        <>
          <img style={{ userSelect: 'none' }} src={chatIcon} width={25} />
        </>
      )}
    </motion.div>
  );
};

export default Chat;
