import { InputType } from '../../../constants/inputs';

import { NameDriverFields } from './fields/NameVehicleFields';

import { DeviceNotice } from './fields/TerminalCarrierFields';
import { DeviceCarrier } from './fields/TerminalCarrierFields';

export const deviceForm = (options: any) => {
  return [
    {
      type: InputType.MAIN_HEADER,
      titles: [
        { text: 'Device' },
        { text: `${options.create ? 'Create' : 'Edit'}` },
      ],
      back: true,
      link: '/client/device',
    },

    {
      type: InputType.TITLE,
      label: 'Name & Details',
    },
    { ...DeviceCarrier },
    { ...NameDriverFields },
    { ...DeviceNotice },
  ];
};
