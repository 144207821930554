import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useTableParams } from '../../../hooks/useTableParams';
import { useSelector } from 'react-redux';
import DVIRModal from './DVIRModal';

const DVIRTab = () => {
  const { handleTableChange, tableParams, clearCustomFilter, setCustomFilter } =
    useTableParams({});
  const count = useSelector((state: any) => state.log.count);
  const [open, setOpen] = useState(false);
  const onCancel = () => {
    setOpen(false);
  };
  const columns: ColumnsType<any> = [
    {
      title: 'Date&Time',
      key: 'date',
      dataIndex: 'date',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            07.09.2024 10:19:36 am
          </div>
        );
      },
      width: '16%',
      ellipsis: true,
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            1.6 mi WNW of South Salt Lake, UT
          </div>
        );
      },
      width: '16%',
      ellipsis: true,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            Pre TI
          </div>
        );
      },
      width: '16%',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      key: 'vehicle',
      dataIndex: 'vehicle',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            vehicle
          </div>
        );
      },
      width: '20%',
      ellipsis: true,
    },
    {
      title: 'Trailer',
      key: 'trailer',
      dataIndex: 'trailer',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            451,5412
          </div>
        );
      },
      width: '16%',
      ellipsis: true,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            No Defects
          </div>
        );
      },
      width: '16%',
      ellipsis: true,
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            No Defects
          </div>
        );
      },
      width: '16%',
      ellipsis: true,
    },
    {
      title: 'Photo',
      key: 'photo',
      dataIndex: 'photo',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            Photo 1, Photo 2, Photo 3
          </div>
        );
      },
      width: '11%',
      ellipsis: true,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            Resolved
          </div>
        );
      },
      width: '11%',
      ellipsis: true,
    },
    {
      title: 'Signature',
      key: 'signature',
      dataIndex: 'signature',
      render: (name, record, index) => {
        return (
          <div className="ubuntu" style={{ marginLeft: 10 }}>
            Signed
          </div>
        );
      },
      width: '14%',
      ellipsis: true,
    },
  ];
  return (
    <div>
      <div style={{ marginBottom: 10, cursor: 'pointer' }}>
        <div
          className="orange"
          onClick={() => setOpen(true)}
          style={{
            fontWeight: 700,
            fontSize: 12,
            marginLeft: 16,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 10,
            background: '#f5f9ff',
            padding: '10px',
            width: 'fit-content',
            gap: 5,
          }}
        >
          <span
            className="icon-fi-rr-plus orange"
            style={{ width: 'fit-content' }}
          />
          Create DVIR
        </div>
        <DVIRModal open={open} onCancel={onCancel} onOk={onCancel} />
      </div>
      <div style={{ width: '100%' }} className="logs-table">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={[{}]}
          pagination={{
            ...tableParams.pagination,
            position: ['bottomCenter'],
            total: count,
          }}
          onChange={handleTableChange}
          //   rowSelection={{ ...rowSelection, columnWidth: 10 }}
          className="table-custom-original-tab"
          //   sticky
          //   scroll={{ y: window.innerHeight - 235 }}
        />
      </div>
    </div>
  );
};

export default DVIRTab;
