export const CarrierStatus = {
  ACTIVE: 'ACTIVE',
};

export const carrierStatusOptions = [
  {
    value: CarrierStatus.ACTIVE,
    key: 'Active',
  },
];

export const COUNTRY = {
  USA: 'USA',
  CANADA: 'CANADA',
};

export const PAGE_STATUS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};

export const OfficeField = {
  ADDRESS: 'address',
  NOTES: 'notes',
  NAME: 'name',
  EMAIL: 'email',
  PERSON: 'person',
  WEBSITE: 'website',
  LOGO: 'logo',
  BACK_GROUND: 'background',
  FAVICON: 'favicon',
  OFFICES: 'offices',
  USDOT: 'usdot',
  PHONE: 'phone',
  STATUS: 'status',
  BILLING_PLAN: 'billing_plan',
  DST: 'dst',
  first_day: 'first_day',
  date_format: 'date_format',
  distance_geofence: 'distance_geofence',
  map_source: 'map_source',
  geodata_source: 'geodata_source',
  address_format: 'address_format',
  min_city_radius: 'min_city_radius',
  max_distance: 'max_distance',
  measurement: 'measurement',
  tz: 'tz',
};
