import { PieChart } from '@mui/x-charts/PieChart';
import styles from './dasboard.module.css';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';
import { MakeOptional } from '@mui/x-charts/models/helpers';

export interface IDashboardPieChart {
  dashobards: IIDashboardPieChartItem[];
}
export interface IIDashboardPieChartItem {
  pieColors: string[];
  series: [
    {
      data: {
        value: number;
      }[];
      innerRadius: number;
      outerRadius: number;
      paddingAngle: number;
      cornerRadius: number;
      cx: number;
    },
  ];
  title: string;
  pieNumbers: number;
  pieText: string;
  orangeText: string;
  orangeFirstNumber: number;
  orangeSecondNumber: number;
  redText: string;
  redFirstNumber: number;
  redSecondNumber: number;
}

const DashboardPieChart = ({ dashobards }: IDashboardPieChart) => {
  function formatNumber(num: number) {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + ' k';
    }
    return num.toString();
  }

  return (
    <div className={styles.dashobards}>
      {dashobards.map((value) => (
        <div className={styles.dashobard}>
          <p className={`ubuntu ${styles.dashobardTitle}`}>{value.title}</p>
          <div className={styles.dasboardBody}>
            <PieChart
              series={value.series}
              width={132}
              height={132}
              colors={value.pieColors}
              tooltip={{ trigger: 'none' }}
            />
            <div className={styles.dasboardInfo}>
              <p className={`ubuntu ${styles.dasboardInfoNumber}`}>
                {formatNumber(value.pieNumbers)}
              </p>
              <p className={`ubuntu ${styles.dasboardInfoText}`}>
                {value.pieText}
              </p>
            </div>
          </div>
          <div className={styles.dashobardFooter}>
            <div className={styles.dashobardFooterItem}>
              <div className={styles.dashobardFooterItemInfo}>
                <div
                  style={{ background: value.pieColors[0] }}
                  className={styles.dashobardFooterItemColor}
                />
                <p className={`ubuntu ${styles.dashobardFooterItemText}`}>
                  {value.orangeText}
                </p>
              </div>
              <div className={styles.dashobardFooterItemNumbers}>
                <p
                  className={`${styles.dashobardFooterItemNumbersText} ubuntu`}
                >
                  {value.orangeFirstNumber}%
                </p>
                <p
                  className={`${styles.dashobardFooterItemNumbersText} ubuntu`}
                >
                  {value.orangeSecondNumber}
                </p>
              </div>
            </div>
            <div className={styles.dashobardFooterItem}>
              <div className={styles.dashobardFooterItemInfo}>
                <div
                  style={{ background: value.pieColors[1] }}
                  className={styles.dashobardFooterItemColor}
                />
                <p className={`ubuntu ${styles.dashobardFooterItemText}`}>
                  {value.redText}
                </p>
              </div>
              <div className={styles.dashobardFooterItemNumbers}>
                <p
                  className={`${styles.dashobardFooterItemNumbersText} ubuntu`}
                >
                  {value.redFirstNumber}%
                </p>
                <p
                  className={`${styles.dashobardFooterItemNumbersText} ubuntu`}
                >
                  {value.redSecondNumber}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardPieChart;
