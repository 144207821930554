export const InputType = {
  MULTI: 'MULTI',
  TEXT_AREA: 'TEXT_AREA',
  PASSWORD: 'PASSWORD',
  ADD_DYNAMIC: 'ADD_DYNAMIC',
  TITLE: 'TITLE',
  TEXT: 'TEXT',
  SELECT: 'SELECT',
  ADDRESS: 'ADDRESS',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  UPLOAD: 'UPLOAD',
  TEXT_V2: 'TEXT_V2',
  TITLEBUTTON: 'TITLEBUTTON',
  HOURS: 'HOURS',
  SELECT_V2: 'SELECT_V2',
  ADDRESS_V2: 'ADDRESS_V2',
  TIME: 'TIME',
  CHECKBOX_V2: 'CHECKBOX_V2',
  RADIO_V2: 'RADIO_V2',
  UPLOAD_V2: 'UPLOAD_V2',
  PAGE_TITLE: 'PAGE_TITLE',
  IMAGE_UPLOAD: 'IMAGE_UPLOAD',
  FETCH_CARRIER_SELECT: 'FETCH_CARRIER_SELECT',
  FETCH_COMPANY_SELECT: 'FETCH_COMPANY_SELECT',
  TIME_PICKER: 'TIME_PICKER',
  PHONE: 'PHONE',
  SELECT_MULTI: 'SELECT_MULTI',
  SELECT_DYNAMIC: 'SELECT_DYNAMIC',
  DRIVER_DYNAMIC_CARRIER: 'DRIVER_DYNAMIC_CARRIER',
  DRIVER_SELECT_TERMINAL: 'DRIVER_SELECT_TERMINAL',
  DATE_PICKER_SINGLE: 'DATE_PICKER_SINGLE',
  MULTI_UPLOAD: 'MULTI_UPLOAD',
  DRIVER_DOCUMENTS_LIST: 'DRIVER_DOCUMENTS_LIST',
  //DRIVER GROUP
  CARRIER_DYNAMIC_DRIVER_GROUP: 'CARRIER_DYNAMIC_DRIVER_GROUP',
  //MECHANIC
  CARRIER_DYNAMIC_MECHANIC: 'CARRIER_DYNAMIC_MECHANIC',
  MECHANIC_SELECT_TERMINAL: 'MECHANIC_SELECT_TERMINAL',
  //VEHICLE
  CARRIER_DYNAMIC_VEHICLE: 'CARRIER_DYNAMIC_VEHICLE',
  //TRAILER
  CARRIER_DYNAMIC_TRAILER: 'CARRIER_DYNAMIC_TRAILER',
  //DEVICE
  CARRIER_DYNAMIC_DEVICE: 'CARRIER_DYNAMIC_DEVICE',
  //ROLE
  INPUT_ROLE: 'INPUT_ROLE',
  ROLE_SELECT: 'ROLE_SELECT',
  TABLE_ROLE: 'TABLE_ROLE',
  // OFFICE
  OFFICE_SELECT: 'OFFICE_SELECT',
  ADDRESS_V3: 'ADDRESS_V3',
  CARRIER_DRIVER_VEHICLE_TRAILER: 'CARRIER_DRIVER_VEHICLE_TRAILER',
  //DYNAMIC
  DRIVER_DYNAMIC: 'DRIVER_DYNAMIC',
  CARRIER_DYNAMIC: 'CARRIER_DYNAMIC',
  VEHICLE_DYNAMIC: 'VEHICLE_DYNAMIC',
  TRAILER_DYNAMIC: 'TRAILER_DYNAMIC',
  ELD_DYNAMIC: 'ELD_DYNAMIC',
  DEVICE_DYNAMIC: 'DEVICE_DYNAMIC',
  TIME_PICKER_LOG: 'TIME_PICKER_LOG',
  TIME_PICKER_LOG_TEST: 'TIME_PICKER_LOG_TEST',
  DATE_PICKER_LOG: 'DATE_PICKER_LOG',
  //LOG DRIVER
  SELECT_ORIGIN_V2: 'SELECT_ORIGIN_V2',
  SELECT_EVENT_V2: 'SELECT_EVENT_V2',
  SELECT_NOTES_V2: 'SELECT_NOTES_V2',
  MAIN_HEADER: 'MAIN_HEADER',
  TEXT_V2_AUTHORITY: 'TEXT_V2_AUTHORITY',
};
