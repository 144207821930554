import { all, put, takeLatest, call } from 'redux-saga/effects';
import { notification } from 'antd';
import { secondInstance } from '../utils/requestMonitoring';

import { getLogListFailed } from '../actions';
import { ConfigTypes, getConfigSucces } from '../actions/config';

notification.config({
  placement: 'topRight',
  bottom: 50,
  duration: 5,
  // rtl: true,
});

export function* getConfig({ payload }: any): any {
  try {
    const { data } = yield call(secondInstance.get, `/root/config`);

    yield put(getConfigSucces(data));
  } catch (e: any) {
    yield put(getLogListFailed(e.message));
    yield call(notification.error, {
      message: 'Something went wrong, try again later',
    });
  }
}

export default function* root() {
  yield all([takeLatest(ConfigTypes.GET_CONFIG_REQ, getConfig)]);
}
