import { InputType } from '../../../constants/inputs';

import { NameUserFields } from './fields/NameUserFields';
import { LoginUserFields } from './fields/LoginUserFields';
import { InfoUserFields } from './fields/InfoUser';
import { OfficeUserFields } from './fields/OfficeUserField';

export const userForm = (options: any) => {
  return [
    {
      type: InputType.MAIN_HEADER,
      titles: [
        { text: 'Users' },
        { text: `${options.create ? 'Create' : 'Edit'}` },
      ],
      back: true,
      link: '/client/user',
    },
    {
      type: InputType.TITLE,
      label: 'User Basics',
    },
    // USER NAME DETAILS
    { ...NameUserFields },
    // USER INFO
    { ...InfoUserFields },
    //USER LOGIN DETAILS
    { ...LoginUserFields },
    { ...OfficeUserFields },
  ];
};
