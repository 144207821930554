import { motion } from 'framer-motion';
import styles from './Chat.module.css';
import SearchIcon from '../../img/SearchIcon.svg';
interface ISearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}
const SearchInput = ({ value, onChange, placeholder }: ISearchInputProps) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.98,
        opacity: 0.5,
      }}
      className={styles.inputSearchCon}
    >
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        type="text"
        className={styles.inputSearch}
      />
      <img src={SearchIcon} alt="search" className={styles.searchIcon} />
    </motion.div>
  );
};

export default SearchInput;
