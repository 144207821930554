import { createAction } from '@reduxjs/toolkit';
import { keyMirror } from '@gilbarbara/helpers';

import { actionPayload } from '../modules/helpers';

const ActionTypes = keyMirror({
  GET_CONFIG_REQ: undefined,
  GET_CONFIG_SUCCES: undefined,
});

export const ConfigTypes = ActionTypes;

// get config

export const getConfigReq = createAction(
  ActionTypes.GET_CONFIG_REQ,
  (payload: any) => {
    return actionPayload(payload);
  },
);
export const getConfigSucces = createAction(
  ActionTypes.GET_CONFIG_SUCCES,
  (payload: any) => {
    return actionPayload(payload);
  },
);
