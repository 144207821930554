export const CarrierStatus = {
  ACTIVE: 'ACTIVE',
};

export const carrierStatusOptions = [
  {
    value: CarrierStatus.ACTIVE,
    key: 'Active',
  },
];

export const UserField = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  STATUS: 'status',
  PHONE: 'phone',
  EMAIL: 'email',
  PERSONAL_EMAIL: 'personal_email',
  NOTE: 'note',
  ROLE: 'role',
  COMPANY: 'company',
  OFFICES: 'offices',
  PASSWORD: 'password',
  USERNAME: 'username',
};
