import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';

import './log-top-panel.scss';
import { API, IRecap } from '../../../../api/API';
import { timeFormat } from './LogDashboardPanel';

export const LogBottomPanel = (props: any) => {
  const driverLogDate = useSelector(
    (state: any) => state.driverLog?.driverLogDate,
  );
  const params = useParams();
  const { getRecap } = API;
  const [data, setData] = useState<IRecap[]>([]);
  const init = async () => {
    if (driverLogDate) {
      const response = await getRecap(params.driverid || '', driverLogDate);
      setData(response.reverse());
    }
  };

  useEffect(() => {
    init();
  }, [driverLogDate]);

  return (
    <Row style={{ width: '100%' }}>
      <Col
        span={24}
        className="ubuntu top-log-container"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Row style={{ width: '100%', justifyContent: 'space-between' }}>
          <Col style={{ fontWeight: 'bold' }} span={4}>
            <div className="log-bottom-panel-item small-panel-item">Date</div>
            <div className="log-bottom-panel-item-header log-bottom-panel-item small-panel-item">
              Worked | Driving (h)
            </div>
            <div className="log-bottom-panel-item-header log-bottom-panel-item small-panel-item">
              Vehicle | Driver (m)
            </div>
          </Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '83%',
            }}
          >
            {data.map((item, i: number) => {
              const driving = timeFormat(item.driving);
              const driver_dist = timeFormat(item.driver_dist);
              const onduty = timeFormat(item.onduty);
              const vehicle_dist = timeFormat(item.vehicle_dist);
              return (
                <div key={i} style={{ fontWeight: 'bold' }}>
                  <div className="log-bottom-panel-item log-bottom-panel-item-data small-panel-item">
                    {item.date}
                  </div>
                  <div className="log-bottom-panel-item log-bottom-panel-item-data small-panel-item">
                    {driving.hours}:{driving.minutes} | {onduty.hours}:
                    {onduty.minutes}
                  </div>
                  <div className="log-bottom-panel-item log-bottom-panel-item-data small-panel-item">
                    {vehicle_dist.hours}:{vehicle_dist.minutes} |{' '}
                    {driver_dist.hours}:{driver_dist.minutes}
                  </div>
                </div>
              );
            })}
          </div>
        </Row>
      </Col>
    </Row>
  );
};
