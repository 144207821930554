import EngineIcon from './EngineIcon';
import LogOutIcon from './LogOutIcon';
import LogInIcon from './LogInIcon';
import styles from '../ChartTestV.module.css';
import MalfunctionIcon from './MalfunctionIcon';
import CloudsIcon from './CloudsIcon';
// ADC
import UserCheckIcon from './UserCheckIcon';
// On Pick up driver
import UserUnCheckIcon from './UserUnCheckIcon';
// On Pick down driver
import TruckIcon from './TruckIcon';
// ON trailer добавление и удаление
import DockCheckedIcon from './DockCheckedIcon';
// Delivery
import DockUnCheckedIcon from './DockUnCheckedIcon';
// Pick up
import RepairIcon from './RepairIcon';
// PTI DVE
import TimerIcon from './TimerIcon';
// ON DOT

const AllIcons = ({ type, color }: { type: string; color: string }) => {
  switch (type) {
    case 'Engine':
      return <EngineIcon fill={color} />;
    case 'LogOut':
      return <LogOutIcon fill={color} />;
    case 'LogIn':
      return <LogInIcon fill={color} />;
    case 'Malfunction':
      return <TimerIcon fill={color} />;
    case 'Malfunction cleared':
      return <MalfunctionIcon fill={color} />;
    case 'Circle':
      return (
        <>
          <div className={styles.circle} style={{ background: color }} />
        </>
      );
    default:
      return <></>;
  }
};

export default AllIcons;
