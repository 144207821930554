import { createAction } from '@reduxjs/toolkit';
import { keyMirror } from '@gilbarbara/helpers';

import { actionPayload } from '../modules/helpers';

const ActionTypes = keyMirror({
  GET_ALL_TRANSACTION: undefined,
  GET_ALL_TRANSACTION_SUCCES: undefined,
  APPLY_BY_DRIVER: undefined,
  FORCE_APPROVE: undefined,
  ROLLBACK: undefined,
});

export const TransactionTypes = ActionTypes;

// get transaction

export const getTransaction = createAction(
  ActionTypes.GET_ALL_TRANSACTION,
  (payload: any) => {
    return actionPayload(payload);
  },
);
export const getTransactionSucces = createAction(
  ActionTypes.GET_ALL_TRANSACTION_SUCCES,
  (payload: any) => {
    return actionPayload(payload);
  },
);
export const applyDriverReq = createAction(
  ActionTypes.APPLY_BY_DRIVER,
  (payload: any) => {
    return actionPayload(payload);
  },
);
export const forceAproveReq = createAction(
  ActionTypes.FORCE_APPROVE,
  (payload: any) => {
    return actionPayload(payload);
  },
);
export const transactionRolllBackReq = createAction(
  ActionTypes.ROLLBACK,
  (payload: any) => {
    return actionPayload(payload);
  },
);
