import { motion } from 'framer-motion';
import styles from './Unit.module.css';
import pointIcon from '../../../img/pointIcon.svg';

export interface IUnitCardProps {
  title: string;
  icon: string;
  location: string;
  body: IUnitCardBodyProps[];
}
interface IUnitCardBodyProps {
  title: string;
  changed: boolean;
  icon?: string;
}

const UnitCard = ({ title, icon, location, body }: IUnitCardProps) => {
  return (
    <motion.div
      whileInView={{
        scale: [0.8, 1],
        opacity: [0, 1],
      }}
      className={styles.card}
    >
      <div className={styles.iconItem}>
        <span className={`${icon}`} />
      </div>
      <div className={styles.cardBody}>
        <h2 className={styles.cardBodyTitle}>{title}</h2>
        <h2 className={styles.cardBodyDescription}>
          <span style={{ marginRight: 5 }}>
            <img src={pointIcon} alt="" />
          </span>
          {` `} {location}
        </h2>
        <div className={styles.cardBodyItems}>
          {body.map((item) => (
            <div className={styles.cardBodyItem}>
              {item.icon && (
                <i
                  className={`${item.icon} ${styles.icon} ${item.changed && ` ${styles.cardBodyItemPActive}`}`}
                />
              )}
              <p
                className={`${styles.cardBodyItemP} ${item.changed && `  ${styles.cardBodyItemPActive}`} `}
              >
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default UnitCard;
