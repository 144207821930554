import DasboardHeader from './DasboardHeader';
import styles from './dasboard.module.css';
import DashboardPieChart, {
  IIDashboardPieChartItem,
} from './DashboardPieChart';
import DahboardBars, { IDahboardBarsItem } from './DahboardBars';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getConfigReq } from '../../../actions/config';
import MainContainer from '../../layout/MainContainer';

const Dashboard = () => {
  const headerItems = [
    {
      title: 'Trip Hours',
      number: 34,
      procent: 59,
    },
    {
      title: 'Trip Distance',
      number: 990,
      procent: -90,
    },
    {
      title: 'Active Drivers',
      number: 524,
      procent: 45,
    },
    {
      title: 'Active Vehicles',
      number: 52,
      procent: -52,
    },
    {
      title: 'Hos Violations',
      number: 522,
      procent: 52,
    },
    {
      title: 'F&M Errors',
      number: -2,
      procent: -5,
    },
  ];
  const pieColors = ['#FFAB00', '#FD371F'];

  const dashobards: IIDashboardPieChartItem[] = [
    {
      series: [
        {
          data: [
            {
              value: 60,
            },
            {
              value: 40,
            },
          ],
          innerRadius: 40,
          outerRadius: 65,
          paddingAngle: 0,
          cornerRadius: 5,
          cx: 60,
        },
      ],
      title: 'Logs',
      pieNumbers: 644,
      pieText: 'Total logs',
      orangeText: 'Compliant logs',
      orangeFirstNumber: 34,
      orangeSecondNumber: 216,
      redText: 'Non Compliant logs',
      redFirstNumber: 66,
      redSecondNumber: 428,
      pieColors,
    },
    {
      series: [
        {
          data: [
            {
              value: 55,
            },
            {
              value: 45,
            },
          ],
          innerRadius: 40,
          outerRadius: 65,
          paddingAngle: 0,
          cornerRadius: 5,
          cx: 60,
        },
      ],
      title: 'Trips',
      pieNumbers: 5500,
      pieText: 'Total logs',
      orangeText: 'Identified or Annotated',
      orangeFirstNumber: 34,
      orangeSecondNumber: 216,
      redText: 'Unidentified',
      redFirstNumber: 66,
      redSecondNumber: 428,
      pieColors,
    },
    {
      series: [
        {
          data: [
            {
              value: 10,
            },
            {
              value: 90,
            },
          ],
          innerRadius: 40,
          outerRadius: 65,
          paddingAngle: 0,
          cornerRadius: 5,
          cx: 60,
        },
      ],
      title: 'Inspections',
      pieNumbers: 999,
      pieText: 'Total logs',
      orangeText: 'Defects Resolved',
      orangeFirstNumber: 34,
      orangeSecondNumber: 216,
      redText: 'Defect Status Unknown',
      redFirstNumber: 66,
      redSecondNumber: 428,
      pieColors,
    },
    {
      series: [
        {
          data: [
            {
              value: 80,
            },
            {
              value: 20,
            },
          ],
          innerRadius: 40,
          outerRadius: 65,
          paddingAngle: 0,
          cornerRadius: 5,
          cx: 60,
        },
      ],
      title: 'DVIR',
      pieNumbers: 9999,
      pieText: 'Total logs',
      orangeText: 'Compliant logs',
      orangeFirstNumber: 34,
      orangeSecondNumber: 216,
      redText: 'Non Compliant logs',
      redFirstNumber: 66,
      redSecondNumber: 428,
      pieColors,
    },
    {
      series: [
        {
          data: [
            {
              value: 20,
            },
            {
              value: 80,
            },
          ],
          innerRadius: 40,
          outerRadius: 65,
          paddingAngle: 0,
          cornerRadius: 5,
          cx: 60,
        },
      ],
      title: 'IFTA',
      pieNumbers: 90,
      pieText: 'Total logs',
      orangeText: 'Identified or Annotated',
      orangeFirstNumber: 34,
      orangeSecondNumber: 216,
      redText: 'Unidentified',
      redFirstNumber: 66,
      redSecondNumber: 428,
      pieColors,
    },
    {
      series: [
        {
          data: [
            {
              value: 60,
            },
            {
              value: 40,
            },
          ],
          innerRadius: 40,
          outerRadius: 65,
          paddingAngle: 0,
          cornerRadius: 5,
          cx: 60,
        },
      ],
      title: 'Safety',
      pieNumbers: 13500,
      pieText: 'Total logs',
      orangeText: 'Defects Resolved',
      orangeFirstNumber: 34,
      orangeSecondNumber: 216,
      redText: 'Defect Status Unknown',
      redFirstNumber: 66,
      redSecondNumber: 428,
      pieColors,
    },
  ];
  const bars: IDahboardBarsItem[] = [
    {
      pieColors,
      title: 'Top HOS Violations',
      firstViolation: '30',
      secondViolation: '20',
      type: 'viol',
      barData: [
        {
          width: 50,
          title: 'Daily 10h Off Du..',
          secondNumber: -10,
          firstNumber: 15,
        },
        {
          width: 90,
          title: '14h Shift Limit',
          secondNumber: -10,
          firstNumber: 2,
        },
        {
          width: 20,
          title: '11h Driving Limit',
          secondNumber: 1,
          firstNumber: 15,
        },
        {
          width: 10,
          title: '70h Cycle Limit',
          secondNumber: -10,
          firstNumber: 1,
        },
        {
          width: 95,
          title: '20h Shift Limit',
          secondNumber: 20,
          firstNumber: 10,
        },
      ],
    },
    {
      pieColors: [pieColors[0], 'transparent'],
      title: 'Top Form & Manner Errors',
      firstViolation: '30',
      secondViolation: '20',
      type: '',
      barData: [
        {
          width: 50,
          title: 'Driver Signature',
          secondNumber: -10,
          firstNumber: 15,
        },
        {
          width: 90,
          title: 'Man Office',
          secondNumber: -10,
          firstNumber: 2,
        },
        {
          width: 20,
          title: 'Shipping Docs',
          secondNumber: 1,
          firstNumber: 15,
        },
        {
          width: 10,
          title: 'Distance',
          secondNumber: -10,
          firstNumber: 1,
        },
        {
          width: 95,
          title: 'Vehicle',
          secondNumber: 20,
          firstNumber: 10,
        },
      ],
    },
  ];

  return (
    <MainContainer>
      <div className={styles.container}>
        <DasboardHeader items={headerItems} />
        <DashboardPieChart dashobards={dashobards} />
        <DahboardBars bars={bars} />
      </div>
    </MainContainer>
  );
};

export default Dashboard;
