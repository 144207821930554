import React, { useEffect, useRef, useState } from 'react';

interface IChartBg {
  labelsLength: number;
  cellWidth: number;
  cellImg: string;
  setBgHeight: React.Dispatch<React.SetStateAction<number>>;
  height: number;
}
const ChartBg = ({
  labelsLength,
  cellWidth,
  cellImg,
  setBgHeight,
  height,
}: IChartBg) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [render, setRender] = useState(false);
  const [cellHeight, setCellHeight] = useState(0);
  const generateBackgroundArray = (
    src: string,
    width: number,
    count: number,
  ): { src: string; width: number }[] => {
    return Array.from({ length: count }, () => ({ src, width }));
  };

  const bg = generateBackgroundArray(cellImg, cellWidth, labelsLength * 4);
  const getHeight = () => {
    if (!render) {
      //   setRender(true);
      if (divRef.current) {
        setBgHeight(divRef.current.getBoundingClientRect().height);
      }
    }
  };
  useEffect(() => {
    setCellHeight(height);
  }, [height]);

  return (
    <div
      ref={divRef}
      style={{ width: '100%', minHeight: '200px', maxHeight: '200px' }}
    >
      {bg.map((image, index) => {
        if (index === bg.length - 1) getHeight();

        return (
          <img
            key={index}
            src={image.src}
            alt=""
            style={{
              width: image.width,
              height: cellHeight / 5,
              opacity: 0.65,
            }}
          />
        );
      })}
    </div>
  );
};

export default ChartBg;
