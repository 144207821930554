import { Row, Col } from 'antd';

import './log-top-panel.scss';
import LogDashboardPanelItem from './LogDashboardPanelItem';
import { PieSeriesType, PieValueType } from '@mui/x-charts/models';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, IHOS } from '../../../../api/API';
interface ItemsLogPanel {
  text: string;
  time: string;
  color: string;
  series?: MakeOptional<
    PieSeriesType<MakeOptional<PieValueType, 'id'>>,
    'type'
  >[];
  certificated?: boolean;
}

export const timeFormat = (second: number) => {
  const days = Math.floor(second / (3600 * 24));
  const hours = Math.floor((second % (3600 * 24)) / 3600);
  const minutes = Math.floor((second % 3600) / 60);
  const time = {
    days,
    hours,
    minutes,
  };
  return time;
};

export const LogDashboardPanel = () => {
  const [items, setItems] = useState<ItemsLogPanel[]>([
    {
      text: 'Break In',
      time: 'h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Drive',
      time: '0h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Shift',
      time: '0h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Cycle',
      time: '0h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Previos',
      time: '0h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
  ]);
  const [items2, setItems2] = useState<ItemsLogPanel[]>([
    {
      text: 'Break In',
      time: '0h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Drive',
      time: '0h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Shift',
      time: '6h 40m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
    {
      text: 'Cycle',
      time: '0h 0m',
      color: '',
      series: [
        {
          data: [
            {
              value: 0,
            },
            {
              value: 100,
            },
          ],
          innerRadius: 6,
          outerRadius: 15,
          paddingAngle: 1,
          cornerRadius: 2,
          cx: 10,
        },
      ],
    },
  ]);
  const { getHos } = API;
  const [data, setData] = useState<IHOS>({
    active: {
      cycle: 0,
      prev_cycle: 0,
      shift: 0,
      drive: 0,
      break: 0,
    },
    suggested: {
      cycle: 0,
      prev_cycle: 0,
      shift: 0,
      drive: 0,
      break: 0,
    },
  });
  const params = useParams();
  const init = async () => {
    const response = await getHos(params.driverid || '');
    setData(response);
  };
  useEffect(() => {
    init();
  }, [params.driverid]);

  useEffect(() => {
    const timeBreak = timeFormat(data.active.break);
    const timeDrive = timeFormat(data.active.drive);
    const timeShift = timeFormat(data.active.shift);
    const timeCycle = timeFormat(data.active.cycle);
    const timePrevCycle = timeFormat(data.active.prev_cycle);

    const timeBreak2 = timeFormat(data.suggested.break);
    const timeDrive2 = timeFormat(data.suggested.drive);
    const timeShift2 = timeFormat(data.suggested.shift);
    const timeCycle2 = timeFormat(data.suggested.cycle);
    setItems([
      {
        text: 'Break In',
        time: `${timeBreak.hours}h  ${timeBreak.minutes}m`,
        color: '#374957',
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.active.break / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.active.break / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
      {
        text: 'Drive',
        time: `${timeDrive.hours}h  ${timeDrive.minutes}m`,
        color: '#FF6B00',
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.active.drive / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.active.drive / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
      {
        text: 'Shift',
        time: `${timeShift.hours}h  ${timeShift.minutes}m`,
        color: '#FFAB00',
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.active.shift / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.active.shift / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
      {
        text: 'Cycle',
        time: `${timeCycle.hours}h  ${timeCycle.minutes}m`,
        color: '#65D140',
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.active.cycle / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.active.cycle / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
      {
        text: 'Previos',
        time: `${timePrevCycle.hours}h  ${timePrevCycle.minutes}m`,
        color: '#65D140',
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.active.prev_cycle / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(
                    ((data.active.prev_cycle / 86400) * 100).toFixed(2),
                  ),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
    ]);
    setItems2([
      {
        text: 'Break In',
        color: '#374957',
        time: `${timeBreak2.hours}h  ${timeBreak2.minutes}m`,
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.suggested.break / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.suggested.break / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
      {
        text: 'Drive',
        color: '#FF6B00',
        time: `${timeDrive2.hours}h  ${timeDrive2.minutes}m`,
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.suggested.drive / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.suggested.drive / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
      {
        text: 'Shift',
        color: '#FFAB00',
        time: `${timeShift2.hours}h  ${timeShift2.minutes}m`,
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.suggested.shift / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.suggested.shift / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
      {
        text: 'Cycle',
        color: '#65D140',
        time: `${timeCycle2.hours}h  ${timeCycle2.minutes}m`,
        series: [
          {
            data: [
              {
                value: parseFloat(
                  ((data.suggested.cycle / 86400) * 100).toFixed(2),
                ),
              },
              {
                value:
                  100 -
                  parseFloat(((data.suggested.cycle / 86400) * 100).toFixed(2)),
              },
            ],
            innerRadius: 6,
            outerRadius: 15,
            paddingAngle: 1,
            cornerRadius: 2,
            cx: 10,
          },
        ],
      },
    ]);
  }, [data]);
  return (
    <Row
      style={{
        width: '100%',
        paddingTop: 10,
        gap: 15,
        justifyContent: 'space-between',
      }}
    >
      <Col>
        <p
          style={{
            color: '#374957',
            fontWeight: 700,
            paddingLeft: 10,
          }}
          className="ubuntu"
        >
          Active
        </p>
        <Row
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginTop: 10,
          }}
        >
          <Row style={{ gap: 5 }}>
            {items.slice(0, 5).map((i) => (
              <>
                <LogDashboardPanelItem
                  color={i.color}
                  series={i.series}
                  text={i.text}
                  time={i.time}
                  certificated={i.certificated}
                />
              </>
            ))}
          </Row>
        </Row>
      </Col>
      <Col>
        <p
          style={{
            color: '#374957',
            fontWeight: 700,
            paddingLeft: 10,
          }}
          className="ubuntu"
        >
          Suggested
        </p>
        <Row style={{ width: '100%', gap: 5, marginTop: 10 }}>
          {items2.map((i) => (
            <>
              <LogDashboardPanelItem
                color={i.color}
                series={i.series}
                text={i.text}
                time={i.time}
                certificated={i.certificated}
              />
            </>
          ))}
        </Row>
      </Col>
    </Row>
  );
};
