import { keyMirror } from '@gilbarbara/helpers';
import { createAction } from '@reduxjs/toolkit';
import { actionPayload } from '../modules/helpers';

const ActionTypes = keyMirror({
  GET_LOG_LIGST_REQ: undefined,
  GET_LOG_LIGST_REQ_SUCCES: undefined,
  ASSIGN_UN_LOG_REQ: undefined,
});

export const UnLogActionTypes = ActionTypes;

export const getUnLogReq = createAction(
  ActionTypes.GET_LOG_LIGST_REQ,
  (payload: any) => {
    return actionPayload(payload);
  },
);

export const getUnLogReqSucces = createAction(
  ActionTypes.GET_LOG_LIGST_REQ_SUCCES,
  (payload: any) => {
    return actionPayload(payload);
  },
);

export const assignUnLogReq = createAction(
  ActionTypes.ASSIGN_UN_LOG_REQ,
  (payload: any) => {
    return actionPayload(payload);
  },
);
