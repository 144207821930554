import React from 'react';

function TransactionIcon() {
  return (
    <svg
      style={{
        marginRight: 10,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        className="ant-menu-item-icon-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.716"
        clipPath="url(#clip0_2908_104472)"
      >
        <path d="M14.029 23.7H2.886a1.714 1.714 0 01-1.714-1.715V3.128a1.714 1.714 0 011.714-1.714h12.857M18.316 6.557l2.572-5.143 2.571 5.143v14.571a2.572 2.572 0 01-5.143 0V6.557zM18.316 16.844h5.143M6.313 1.414V23.7M10.602 7.414h3.428"></path>
      </g>
      <defs>
        <clipPath id="clip0_2908_104472">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(.313 .555)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TransactionIcon;
