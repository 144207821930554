import { InputType } from '../../../constants/inputs';

import { NameDriverFields } from './fields/NameDriverFields';

export const driverGroupForm = (options: any) => {
  return [
    {
      type: InputType.MAIN_HEADER,
      titles: [
        { text: 'Driver Group' },
        { text: `${options.create ? 'Create' : 'Edit'}` },
      ],
      back: true,
      link: '/client/driver_group',
    },
    {
      type: InputType.TITLE,
      label: 'Driver Group Basics',
    },
    // // DRIVER NAME DETAILS
    { ...NameDriverFields },
  ];
};
