import styles from './dasboard.module.css';
import positive from '../../../img/positive.svg';
import negative from '../../../img/negative.svg';
import { DatePicker, Select, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCarriersListReq } from '../../../actions';
export interface IDasboardHeader {
  items: IDasboardHeaderItem[];
}
export interface IDasboardHeaderItem {
  title: string;
  number: number;
  procent: number;
}
export function disabledDate(current: any) {
  return current && current > dayjs().endOf('day');
}

const DasboardHeader = ({ items }: IDasboardHeader) => {
  const { RangePicker } = DatePicker;
  const today = dayjs();

  const dispatch = useDispatch();

  const carriers = useSelector((state: any) => state.carrier.carrierList);

  useEffect(() => {
    dispatch(
      getCarriersListReq({
        queryParams: {
          with: ['terminals', 'driver_groups'],
        },
      }),
    );
  }, []);

  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: 'Today',
      value: [dayjs().startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last 8 days',
      value: [dayjs().subtract(8, 'd').startOf('day'), dayjs().endOf('day')],
    },
    {
      label: 'Last Month',
      value: [dayjs().subtract(1, 'M').startOf('day'), dayjs().endOf('day')],
    },
  ];

  return (
    <div className={styles.header}>
      <div className={styles.headerPicker}>
        <Select
          showSearch
          placeholder="Select Carrier"
          className={`select-custom ${styles.select}`}
          style={{ width: 200 }}
        >
          {carriers &&
            carriers.map((value: any) => (
              <Select.Option value={value.name}>{value.name}</Select.Option>
            ))}
        </Select>
        <RangePicker
          presets={rangePresets}
          className={styles.rangePicker}
          disabledDate={disabledDate}
        />
      </div>
      <div className={styles.headerItems}>
        {items.map((item, index) => {
          const isPositive = item.procent >= 0;
          return (
            <div key={index}>
              <div>
                <p className={`ubuntu ${styles.headerItemsTitle}`}>
                  {item.title}
                </p>
              </div>
              <div className={styles.headerItemsBody}>
                <p className={`ubuntu ${styles.headerItemsBodyNumber} `}>
                  {item.number}
                </p>
                <p
                  className={`ubuntu ${isPositive ? styles.headerItemsBodyProcentPsitive : styles.headerItemsBodyProcentNegative}`}
                >
                  {item.procent} %
                  {isPositive ? (
                    <img src={positive} alt="" />
                  ) : (
                    <img src={negative} alt="" />
                  )}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DasboardHeader;
