import { motion } from 'framer-motion';
import styles from './UnitRoutes.module.css';
import dayjs from 'dayjs';
import AllIcons from '../driver_log/LogTabs/Chart/icons/AllIcons';
interface IUnitRouteItemProps {
  label: string;
  color: string;
  adress: string;
  violation?: string;
  index: number;
  timestamp: number;
  lastIndex: number;
}
const UnitRouteItem = ({
  label,
  color,
  timestamp,
  violation,
  adress,
  index,
  lastIndex,
}: IUnitRouteItemProps) => {
  return (
    <>
      <motion.div
        whileInView={{
          scale: [0.8, 1],
          opacity: [0, 1],
        }}
        className={styles.card}
      >
        <div className={styles.point} style={{ background: color }}>
          {label}
        </div>

        <div className={styles.body}>
          <p className={styles.label}>
            {dayjs(timestamp).format('MM/DD/YYYY hh:mm a')}{' '}
            {violation && (
              <span style={{ color: 'red' }}>
                <AllIcons color={'red'} type={violation} />:{violation}
              </span>
            )}
          </p>
          <p className={styles.location}>{adress} </p>
        </div>
        {lastIndex != index && (
          <div className={styles.line} style={{ background: color }} />
        )}
      </motion.div>
    </>
  );
};

export default UnitRouteItem;
