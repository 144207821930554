import { InputType } from '../../../constants/inputs';
import { GeneralCarrierFields } from './fields/GeneralCarrierFields';
import { ContactsCarrierFields } from './fields/ContactsCarrierFields';
import { PreferencesCarrierFields } from './fields/PreferencesCarrierFields';
import { TerminalCarrierFields } from './fields/TerminalCarrierFields';
import {
  HosRulesCarrierFields,
  HosCarrierNotice,
} from './fields/HosRulesCarrierFields';

export const carrierForm = ({ visible, onClick, create, deleteLogo }: any) => {
  const contacts = visible && ContactsCarrierFields;
  const general = GeneralCarrierFields({ deleteLogo });
  const title = create
    ? { ...ContactsCarrierFields }
    : {
        type: InputType.TITLEBUTTON,
        label: 'Contacts',
        onClick: onClick,
        buttonText: 'Get Contacts',
      };
  const body = create ? {} : { ...contacts };

  return [
    {
      type: InputType.MAIN_HEADER,
      titles: [{ text: 'Carriers' }, { text: `${create ? 'Create' : 'Edit'}` }],
      back: true,
      password: true,
      link: '/client/carriers',
    },

    {
      type: InputType.TITLE,
      label: 'General',
    },
    { ...general },
    {
      type: InputType.TITLE,
      label: 'Preferences',
    },
    { ...PreferencesCarrierFields },
    { ...HosRulesCarrierFields({}) },
    { ...HosCarrierNotice },
    { ...TerminalCarrierFields({}) },
    title,
    body,
  ];
};
