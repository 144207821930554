import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Row, Col, Form, Button, Input } from 'antd';
import { CommonInput } from '../../common/inputs';
import { userForm } from './user-form';
import { Graph } from '../../common/graph/Graph';
import { getUserReq } from '../../../actions/user';
import MainContainer from '../../layout/MainContainer';

export const UserPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const user = useSelector((state: any) => state.user.user);
  const handleSubmit = (values: any) => {};

  React.useEffect(() => {
    console.log(params, 'params');

    dispatch(getUserReq({ id: params.userId }));
  }, []);
  return (
    <>
      <MainContainer half>
        <Row>
          <Col span={24}>
            <Form
              form={form}
              name="test"
              onFinish={handleSubmit}
              initialValues={{
                name: 'stas',
                lastName: 'litvinov',
                dot: '',
                id: '',
                phone: '',
                adress: {},
              }}
            >
              {userForm({})?.map((field: any, i: number) => {
                return <CommonInput key={i} {...field} />;
              })}
              <Form.Item style={{ width: '100%' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="orange"
                  style={{ width: '100%' }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};
