import { InputType } from '../../../constants/inputs';
import { VALIDATION_TYPE } from '../../../utils/validation';
import { AddressCompanyFields } from './fields/AdressCompany';
import { ContactCompanyFields } from './fields/ContactCompany';

import { NameCompanyFields } from './fields/NameCompanyFields';

import { DeviceNotice } from './fields/TerminalCarrierFields';
import { LogoCompanyFields } from './fields/LogoCompanyFields';

export const companyForm = (options: any) => {
  return [
    {
      type: InputType.MAIN_HEADER,
      titles: [
        { text: 'Company' },
        { text: `${options.create ? 'Create' : 'Edit'}` },
      ],
      back: true,
      link: '/client/company',
    },
    {
      type: InputType.TITLE,
      label: 'Company Basics',
    },
    { ...NameCompanyFields },
    { ...ContactCompanyFields },
    { ...AddressCompanyFields },

    { ...LogoCompanyFields },
    { ...DeviceNotice },
  ];
};
