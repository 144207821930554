function UnitHistoryIcon() {
  return (
    <svg
      style={{
        marginRight: 10,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        className="ant-menu-item-icon-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.716"
        clipPath="url(#clip0_2908_104660)"
      >
        <path d="M.992 12.932A11.143 11.143 0 1012.135 1.789a12.394 12.394 0 00-8.571 3.429"></path>
        <path d="M4.42 1.79l-.857 3.428 3.428.857M12.137 6.93v6.857L7.68 16.015"></path>
      </g>
      <defs>
        <clipPath id="clip0_2908_104660">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(.133 .93)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnitHistoryIcon;
